@import "./variables.scss";

.preview_form {
	.checkbox {
		font-size: 13px;
		font-weight: 600;
	}
}

.asoebi_layout {
	display: flex;
	align-items: center;
	margin: auto;

	.collection_option {
		gap: 20px;
	}

	.select_bank__control {
		height: 50px;
	}

	.custom_input {
		p {
			color: $primary;
			font-size: 12px;
		}
		input.error {
			border-color: $primary;
		}
	}
	.content {
		width: 100%;
		height: 100%;
		margin: auto;
		display: flex;
		justify-content: flex-start;
		flex-direction: column;
	}
	.tracker_content {
		padding: 2rem;

		h1 {
			font-weight: bold;
			color: black;
			font-size: 2rem;
			max-width: 80%;
			text-align: center;
			margin: auto;
			span {
				color: $primary;
			}
			@media (max-width: $bp-lg-phone) {
				font-size: 1.5rem;
				width: 100%;
			}
		}
		h4 {
			text-align: center;
			margin: 2rem auto;
			font-size: 18px;
			color: black;
		}

		form.questionnaire {
			gap: 10px;
		}
		@media (max-width: $bp-tab) {
			padding: 2rem 0;
		}
	}

	.asoebi_questionnaire {
		.question_misc {
			background-color: $primary;
			color: white;
			height: 20px;
			width: 20px;
			border-radius: 50%;
			margin: 0px 5px;
		}
		.form_view {
			position: relative;
			.overlay {
				background-color: #000000ba;
				position: absolute;
				width: 100%;
				height: 100%;
				z-index: 10;
				margin: auto;
				display: flex;
				align-items: center;
				h1 {
					color: white;
				}
			}
		}
		.asoebi_images {
			img {
				border-radius: 10px;
				height: 100%;
				width: 100%;
				min-height: 100%;
				min-width: 100%;
				object-position: center;
				object-fit: cover;
				display: block;
				min-height: 300px;
			}
			&.single {
				width: 100%;
				height: 100%;
				img {
					max-width: 100%;
				}
			}
			&.many {
				display: grid !important;
				grid-template-columns: repeat(2, 1fr);
				gap: 20px;
				place-items: center;
			}
		}
		.select_asoebi_options {
			gap: 15px;
		}
	}

	.asoebi_tracker {
		padding: 2rem 2rem;

		h1 {
			font-weight: bold;
			color: black;
			font-size: 2rem;
			max-width: 80%;
			text-align: center;
			margin: auto;
			span {
				color: $primary;
			}
		}
		h4 {
			text-align: center;
			margin: 2rem auto;
			font-size: 18px;
			color: black;
		}
		form {
			gap: 15px;
			display: flex;
			flex-direction: column;

			button {
				background-color: $primary;
				color: white;
				border: none;
				font-weight: bold;
			}
		}
	}
	.qty_control_group {
		.btn__minus {
			background-color: $alternate;
		}
		.btn__plus {
			background-color: $primary;
		}
		span {
			font-weight: bold;
			font-size: 20px;
		}
	}

	.guest_questionaire {
		width: 100%;
		height: 100%;
		margin: auto;
		justify-content: center;
		flex-direction: column;
		overflow-y: scroll;

		h1 {
			font-weight: bold;
			color: black;
			font-size: 2rem;
			max-width: 80%;
			text-align: center;
			margin: auto;
			span {
				color: $primary;
			}
		}
		h4 {
			text-align: center;
			margin: 2rem auto;
			font-size: 18px;
			color: black;
		}
		.guest_details {
			gap: 15px;
			display: flex;
			flex-direction: column;
			label {
				color: black;
			}
		}
		h6 {
			font-weight: bold;
			color: black;
		}

		@media (max-width: $bp-lg-phone) {
			padding: 4rem 2rem 0rem;
			overflow-y: unset;
		}
	}
	.radio_group {
		display: flex;
		width: 100%;
		gap: 10px;
		color: black;
		div {
			border-radius: 5px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			&.border_active {
				border-color: $primary !important;
			}
		}
	}
	.checkbox {
		border-radius: 5px;
		display: flex;
		height: 50px;
		align-items: center;
		justify-content: space-between;
		color: black;
		&.border_active {
			border-color: $primary !important;
		}
	}

	.preview_questionaire {
		height: 100%;
		overflow-y: scroll;
		h3 {
			color: black;
			font-size: 1.2rem;
		}
		.asoebi_options {
			align-items: flex-start;
			div {
				align-items: center;
				gap: 5px;
			}
			p {
				color: black;
			}
		}

		form {
			display: flex;
			gap: 20px;
			flex-direction: column;

			.upload_image {
				border: 1px solid #a7a8b0;
				text-wrap: nowrap;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
			.preview_images {
				img {
					max-width: 400px;
				}
				&.single {
					width: 100%;
					height: 100%;
					img {
						max-width: 100%;
					}
				}
				&.many {
					display: grid !important;
					grid-template-columns: repeat(2, 1fr);
					gap: 20px;
					place-items: center;
				}
			}
		}
	}

	.asoebi_options {
		display: flex;
		gap: 10px;
	}

	.complete_questionnaire {
		display: flex;
		flex-direction: column;
		gap: 20px;
	}

	.questionnaire {
		.qty_control_group {
			button {
				height: 36px;
				width: fit-content;
			}
			button.btn__minus {
				width: 45px;
				align-items: center;
			}
		}
	}

	.planner_login {
		h2 {
			font-size: 32px;
		}
		@media (max-width: $bp-lg-phone) {
			h2 {
				font-size: 24px;
			}
		}
	}

	.questionnaire__completed {
		@media (max-width: $bp-lg-phone) {
			h1 {
				font-size: 24px;
			}
			h4 {
				font-size: 20px;
			}
		}
	}

	@media (max-width: 768px) {
		flex-direction: column;
		height: 100%;

		.image_wrapper {
			width: 100%;
			height: 50%;
		}
		.content {
			width: 100%;
			height: 100%;
		}
		.asoebi_tracker {
			h1 {
				max-width: 100%;
				font-size: 1.5rem;
			}
		}
		.guest_questionaire {
			padding-top: 40px;
			h1 {
				max-width: 100%;
				font-size: 1.5rem;
			}
			.radio_group {
				div {
					width: 100%;
				}
			}
		}
	}

	@media (max-width: $bp-lg-phone) {
		.tracker_content {
			padding: 2rem 0.5rem;
		}
		.select_input_group select,
		.select_input_group input {
			min-width: 100%;
		}
	}
}
