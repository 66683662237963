@import "./variables.scss";

select.form-control {
	width: 100%;
	appearance: none;
	background: url("https://firebasestorage.googleapis.com/v0/b/plan-a-day-12b4c.appspot.com/o/select_arrow.svg?alt=media&token=9056509d-c3bb-450d-abe0-91884643ef77")
		no-repeat;
	-webkit-appearance: none;
	padding-right: 10px !important;
	background-position: calc(100% - 0.3rem) center !important;
	-moz-appearance: none !important;
}

.collaborators_icons {
	.icon_2,
	.icon_3,
	.spouse_icon {
		margin-left: -20px;
		position: relative;
		z-index: 10;
		border: 2px solid white !important;
	}
}

button#add_ceremony_button svg {
	height: 20px;
	width: 20px;
}

.event_filter {
	font-family: "Poppins", sans-serif;
	font-style: normal;
	line-height: normal;
	h1 {
		color: #cb3050;
		font-size: 24px;
		font-weight: 600;
	}

	.event_overview {
		gap: 5px;
		h3 {
			color: #545565;
			font-size: 20px;
			font-weight: 600;
			margin-bottom: 0;
		}
		span {
			border-radius: 5px;
			background: #c0c6a6;
			color: #000;
			font-size: 12px;
			font-weight: 600;
			padding: 5px 15px;
			text-transform: uppercase;
		}
	}
}

.top_left_card {
	height: 325px;
	.top .weather {
		width: 80px;
	}
	.ceremony_name.shorten_event_name {
		max-width: 120px;
		text-wrap: nowrap;
		white-space: nowrap;
		overflow-x: hidden;
		text-overflow: ellipsis;
	}
}

.filter_modal {
	form {
		h4 {
			color: #545565;
			font-family: "Poppins";
			font-size: 20px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
		}
		display: flex;
		flex-direction: column;
		gap: 20px;
		.filter_checkbox.checkbox {
			gap: 10px;
			align-items: center;
			label {
				order: 2;
				margin-bottom: 0;
				color: #545565;
				font-family: "Poppins", sans-serif;
				font-size: 20px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				@media (max-width: $bp-tab) {
					font-size: 16px;
				}
			}

			input {
				order: 1;
			}
		}
		button {
			width: 125px;
			height: 48px;
			color: #fff;
			text-align: center;
			font-family: "Poppins", sans-serif;
			font-size: 20px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
		}
	}
}

.my-wedding {
	.new_event_details {
		font-family: "Poppins", sans-serif;
		font-style: normal;
		line-height: normal;

		div.weather_view {
			.event_icons,
			div#manage_ceremonies {
				display: flex;
			}

			@media (max-width: 400px) {
				&.grid {
					display: grid !important;
					grid-template-columns: repeat(3, 1fr);
					grid-template-rows: 2fr 2fr;
					padding-right: 10px !important;
					.weather .loader {
						width: 50px;
						margin-top: -10px;
						display: flex;
					}
					.event_title {
						grid-column: 1/4;
						grid-row: 1;
						display: flex !important;
						align-items: center;
						flex-direction: row !important;
						justify-content: space-between;
						width: 100%;
						min-width: 100%;
					}

					div#manage_ceremonies {
						grid-column: 1/2;
						grid-row: 2;
						display: flex;
					}

					.event_icons {
						grid-column: 3/4;
						grid-row: 2;
						justify-content: space-between;
						display: flex;
					}

					.event_icons #edit_ceremony_button {
						margin-right: 20px;
					}
				}
			}
		}
		.ceremony_name {
			width: 300px;
			text-overflow: ellipsis;
			overflow: hidden;
			text-wrap: nowrap;
			white-space: nowrap;
			@media (max-width: 768px) {
				width: 200px;
			}
		}

		.section_1 {
			.location {
				width: 200px;
				text-overflow: ellipsis;
				overflow: hidden;
				text-wrap: nowrap;
				white-space: nowrap;
			}
		}

		.top {
			display: flex !important;
			border-radius: 5px 5px 0px 0px;
			box-shadow: 0px 8px 24px 0px rgba(84, 85, 101, 0.2);
			h3 {
				color: #fff;
				font-style: normal;
				font-weight: 600;
				font-size: 18px;
				text-transform: capitalize;
			}
			button.event_control:disabled {
				opacity: 0.5;
			}
		}
		.body {
			border-radius: 0px 0px 5px 5px;
			background: #fff;
			box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.2);
			font-size: 18px;
			color: #545565;
			.section_1 {
				@media (min-width: 1200px) {
					border-right: 1px solid #e5e5e5;
				}
				h6 {
					font-weight: 600;
				}
				p {
					font-weight: 400;
				}
				p.date {
					color: #545565;
					font-weight: 600;
					font-size: 16px;
				}
			}
			.collaborators {
				h6 {
					font-weight: 600;
				}
				.circle_wrapper {
					position: relative;
					.comment_icon {
						position: absolute;
						left: -4px;
						top: -4px;
						background-color: #545565;
						padding: 4px;
						height: 30px;
						width: 30px;
						display: flex;
						justify-content: center;
						border-radius: 100%;
						align-items: center;
						z-index: 100;
						@media (max-width: $bp-phone) {
							height: 20px;
							width: 20px;
						}
					}
					.comment_icon:hover {
						background-color: #26a578;
					}
				}
				.circle {
					background-color: #cb3050;
					width: 60px;
					height: 60px;
					display: flex;
					border-radius: 50%;
					align-items: center;
					justify-content: center;
					color: white;
					@media (max-width: $bp-phone) {
						width: 50px;
						height: 50px;
						border-radius: 50%;
						color: white;
						font-size: 14px;
					}
				}
				.circle.join {
					margin-left: -10px;
					border: 1px solid white;
					z-index: 3;
					position: relative;
				}
			}
		}
		@media (max-width: $bp-phone) {
			.top {
				h3 {
					font-size: 16px;
					width: 120px;
					white-space: nowrap;
					text-wrap: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
				}
			}
			.body .section_1 {
				border-right: none;
				@media (max-width: 375px) {
					display: flex;
					flex-direction: column;
					div {
						padding: 0;
						max-width: 100%;
					}
				}
				p {
					font-size: 16px;
				}
			}
		}
	}
	.event_overview_grid {
		.card_row {
			gap: 40px;
			justify-content: space-between;
		}
		.card.tips_ideas {
			width: 48%;
		}
		.card.quotes {
			width: 48%;
		}
		.budget_card.group {
			gap: 60px;
		}
		.item_card {
			a {
				color: #cb3050;
				font-size: 14px;
				font-weight: 300;
				letter-spacing: 0.7px;
			}
		}
		.grid_row_1 {
			display: flex;
			flex-direction: row;
			column-gap: 40px;
			.item_card {
				width: 48%;
				border-radius: 5px;
				background: #fff;
				box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.2);
				h3 {
					color: #545565;
					font-size: 18px;
					font-weight: 600;
				}
				ul {
					li {
						list-style: none;
						// height: 80px;
						@media (max-width: 500px) {
							height: unset;
						}
						input {
							width: 20px;
							height: 20px;
							border-radius: 5px;
							background: #fff;
							border: 2px soliod rgba(0, 0, 0, 0.2);
							accent-color: $primary;
						}
						p {
							color: #cb3050;
							font-size: 14px;
							font-weight: 400;
							margin-bottom: 0;
						}
					}
					li.border_bottom {
						border-bottom: 1px solid #e5e5e5;
					}
				}
				.checklist_card,
				.budget_card {
					height: 195px;
					display: flex;
					flex-direction: column;
					justify-content: space-between;
				}
			}
		}

		@media (max-width: $bp-phone) {
			.grid_row_1 {
				flex-direction: column;
				gap: 25px;
				.item_card {
					width: 100%;
					h3 {
						font-size: 16px;
					}
				}
			}
			.card.tips_ideas,
			.card.quotes {
				width: 100%;
			}
			div.card_row {
				gap: 25px;
			}
		}
	}
	.todo-item {
		display: flex;
		justify-content: space-between;
		width: 100%;
		align-items: center;
		margin-bottom: 28px;
		padding: 8px 12px;
		position: relative;

		&.done {
			h5 {
				text-decoration: line-through;
			}
		}

		.icons {
			button {
				border: 0;
				background-color: transparent;
			}
		}
		.todo_left {
			align-items: flex-start !important;
			input {
				accent-color: #cb3050;
				border-radius: 5px;
				height: 20px;
				width: 20px;
				margin-top: 5px;
			}
			.text {
				p {
					font-size: 14px;
					line-height: 16px;
				}
			}
		}
		.text {
			margin-left: 14px;

			h5 {
				font-weight: 600;
				font-size: 20px;
				line-height: 30px;
				color: #545565;
				@media (max-width: $bp-lap) {
					font-size: 18px;
					line-height: 24px;
				}
			}
			p {
				margin-top: 11px;
				font-weight: 500;
				font-size: 16px;
				line-height: 24px;
				color: #cb3050;
			}
		}
		.note_text {
			padding: 17px;
			position: absolute;
			border: 1px solid #d8d8d8;
			right: 30px;
			max-width: 430px;
			background: white;
			top: 110px;
			z-index: 1;
		}
	}
	.todo-body .todo-item .todo_left input {
		accent-color: #cb3050;
		border-radius: 5px;
		height: 30px;
		width: 30px;
	}
	@media (max-width: $bp-tab) {
		.event_filter.top {
			display: flex !important;
		}
		.filter {
			width: unset;
			order: 1;
			button.bg_dark {
				max-width: 232px;
				width: 200px;
				gap: 0px;
				padding-left: 0px;
				padding-right: 0px;
			}
			.mobile_filter {
				display: none !important;
			}
		}
		.event_overview_grid {
			.card_row {
				gap: 40px;
			}
			.tips_ideas {
				padding: 16px;
				width: 45%;
			}
			.quotes {
				font-style: italic;
				padding: 16px;
				width: 45%;
			}
			.card {
				padding: 16px;
			}
			.small_card {
				padding: 16px;
			}
			.top_left_card .top {
				width: 100%;
				display: flex !important;
				.weather span.loader .lds-ring {
					display: flex !important;
				}
				button.btn {
					padding: 5px 10px;
					display: flex;
					align-items: center;
					justify-content: center;
					span {
						display: none;
					}
					img {
						display: flex;
						margin: 0px !important;
						height: 20px;
						width: 20px;
					}
				}
				.add_ceremony_icon span {
					display: none;
				}
			}
		}
	}
	.event_overview_grid .top_left_card .top {
		.bg_pinky img {
			display: none;
		}
	}
	.my_wedding_top {
		@media (max-width: 500px) {
			flex-direction: column;
			align-items: flex-start !important;
			justify-content: flex-start;
			display: flex !important;
		}
	}
	.greetings {
		font-family: "Poppins";
		font-style: normal;
		color: #545565;
		@media (max-width: 500px) {
			order: 2;
			margin-top: 30px;
		}
		h1 {
			font-weight: 600;
			font-size: 24px;
			line-height: 36px;
			text-overflow: ellipsis;
			text-wrap: nowrap;
			white-space: nowrap;
			overflow: hidden;
			@media (max-width: $bp-tab) {
				font-weight: 600;
				font-size: 20px;
				line-height: 24px;
			}
		}
		p {
			font-weight: 400;
			font-size: 18px;
			line-height: 27px;
		}
	}
	.event_overview_grid {
		display: flex;
		flex-direction: column;
		gap: 40px;

		div.row_1 {
			display: flex;
			justify-content: space-between;
			width: 100%;
			gap: 30px;
			@media (max-width: 1024px) {
				gap: 20px;
			}
			div.event_overview_group {
				width: 55%;
			}
			.card_group {
				display: flex;
				width: 45%;
				gap: 20px;
			}
		}
		div.row_2 {
			display: flex;
			gap: 30px;
			align-items: flex-start;
			.upcoming {
				width: 55%;
			}
			.tips_ideas.card {
				min-height: 300px;
			}
			.card_group_tips_n_quote {
				width: 45%;
				gap: 20px;
				display: flex;
				flex-direction: column;
			}
		}
		// width: 55%;
		.event_overview_group {
			position: relative;
		}
		@media (max-width: 500px) {
			div.event_overview_group {
				height: 270px;
			}
		}
		.card {
			width: 100%;
		}

		@media (max-width: $bp-tab) {
			width: 100%;
			.card {
				width: 100%;
			}
			.right_cards {
				width: 100%;
			}
		}

		@media (max-width: $bp-tab) {
			display: flex;
			flex-direction: column;
			row-gap: 25px;
		}
		.top_left_card {
			position: absolute;
			background: linear-gradient(237deg, #cb3050 0%, #545565 100%);
			box-shadow: 0px 8px 24px 0px rgba(84, 85, 101, 0.2);
			border-radius: 5px;
			padding: 22px;
			width: 100%;
			button.controls:disabled {
				opacity: 0.5;
			}
			button.controls {
				position: absolute;
				left: 15px;
				background-color: white;
				border-radius: 50%;
				display: flex;
				height: 30px;
				width: 30px;
				top: 45%;
				color: black;
				font-size: 30px;
				margin: auto;
				justify-content: center;
			}
			button.controls.right {
				right: 15px;
				left: unset;
			}
			.top {
				width: 100%;
				button {
					border-radius: 5px;
					background: #fceded;
					color: #545565;
					font-size: 12px;
					font-weight: 600;
					line-height: normal;
				}
			}

			@media (max-width: $bp-dsk) {
				height: 278px;
				button.btn.add_ceremony_icon span {
					display: none;
				}
			}

			@media (max-width: $bp-phone) {
				height: 265px;
			}

			.couple_icons {
				display: flex;
				align-items: center;
				justify-content: center;
				position: relative;
				img.love {
					position: absolute;
					left: 68px;
				}
				.partner_icon {
					height: 80px;
					width: 80px;
					background-color: #fceded;
					font-family: "Poppins";
					font-style: normal;
					font-weight: 600;
					font-size: 32px;
					line-height: 48px;
					color: #cb3050;
					border-radius: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
					button {
						border: 0px;
						img {
							border: 0px;
							height: 24px;
							width: 24px;
							position: relative;
						}
					}
				}
			}
			.text_group {
				color: #fceded;
				display: flex;
				justify-content: space-between;
				font-family: "Poppins";
				font-style: normal;
				padding: 20px;
				width: 100%;
				p.location {
					text-transform: capitalize;
				}
				@media (max-width: 1024px) {
					padding: 5px;
					div {
						h5 {
							font-size: 14px;
							line-height: 16px;
						}
						h6 {
							font-size: 14px;
							line-height: 16px;
						}
						p {
							font-size: 12px;
							line-height: 16px;
						}
					}
				}
				h5 {
					font-weight: 600;
					font-size: 18px;
				}
				h6 {
					font-weight: 600;
					font-size: 16px;
				}
				p {
					font-weight: 400;
					font-size: 18px;
					line-height: 27px;
					max-width: 175px;
					text-overflow: ellipsis;
					overflow: hidden;
					text-wrap: nowrap;
					white-space: nowrap;
				}
			}
		}
		.top_right_cards {
			justify-content: space-between;
			display: flex;
			flex-direction: column;
			@media (max-width: $bp-tab) {
				gap: 25px;
			}
			.card {
				height: 154px;
				@media (max-width: $bp-dsk) {
					height: fit-content;
				}
				button {
					margin-top: 20px;
				}
			}
		}
		.card.upcoming {
			height: 455px;
			.upcoming_task {
				min-height: 200px;
			}
			@media (min-width: 1441px) {
				height: 395px;
			}
			ul {
				padding: 0px;
				li {
					list-style: none;
				}
			}
			// .top {
			//   display: block !important;
			//   margin-bottom: 30px;
			// }
			@media (max-width: 1024px) {
				// height: fit-content;
			}
		}
		.card {
			padding: 14px 24px;
			font-family: "Poppins";
			font-style: normal;
			color: #545565;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
			border-radius: 5px;

			h5 {
				font-weight: 600;
				font-size: 18px;
				line-height: 27px;
			}
			p {
				font-weight: 400;
				font-size: 18px;
				line-height: 27px;
			}
			@media (max-width: 1024px) {
				h5 {
					font-size: 16px;
					line-height: 20px;
				}
				p {
					font-size: 14px;
					line-height: 16px;
					margin-top: 5px;
				}
				a {
					margin-top: 10px;
					font-size: 12px;
					line-height: 16px;
				}
			}
			a {
				margin-top: 15px;
			}
			button {
				color: #cb3050;
				font-weight: 300;
				font-size: 14px;
				line-height: 21px;
				letter-spacing: 0.05em;
				width: fit-content;
				border: 0px;
				background-color: transparent;
			}
		}
		.upcoming {
			max-width: 100%;
			p {
				margin-top: 15px;
			}
			.top {
				margin-bottom: 60px;
				display: block;
			}
		}
		.tips_ideas {
			.button_group {
				gap: 6px;
				display: flex;
				flex-wrap: wrap;
				margin: 14px 0px 14px 0px;
				@media (max-width: $bp-phone) {
					gap: 10px !important;
				}
			}
			a {
				background-color: #545565;
				color: white;
				height: 33px;
				display: flex;
				align-items: center;
				font-weight: 500;
				font-size: 12px;
				padding: 5px 9px;
				border-radius: 10px;
				line-height: 4px;
				margin-top: 0px;
				@media (max-width: 1024px) {
					font-size: 12px;
					line-height: 16px;
					height: 28px;
					padding: 5px 10px;
				}
			}
			a:hover {
				background-color: #cb3050;
				text-decoration: none;
			}
		}
		.quotes {
			font-style: italic;
			position: relative;
			min-height: 117px;

			img {
				position: absolute;
				width: 76px;
				height: 86px;
			}

			h5 {
				color: #cb3050;
				margin-left: -12px;
			}
			p {
				font-weight: 400;
			}
		}
	}

	@media (max-width: 500px) {
		div.event_filter.top {
			flex-direction: column;
			align-items: flex-start !important;
			div.greetings {
				order: 1;
			}

			.filter {
				order: 2;
			}
		}
	}
}

@media (max-width: 500px) {
	.my-wedding .event_overview_grid div.row_1,
	.my-wedding .event_overview_grid div.row_2 {
		flex-direction: column;
		gap: 20px;
	}

	.my-wedding .new_event_details .ceremony_name {
		width: 150px;
	}

	.my-wedding .new_event_details .section_1 .location {
		width: 90px;
	}

	.my-wedding .event_overview_grid div.row_1 .card_group,
	.my-wedding .event_overview_grid div.row_1 div.event_overview_group,
	.my-wedding .event_overview_grid div.row_1 .card_group,
	.my-wedding .event_overview_grid div.row_2 .upcoming,
	.my-wedding .event_overview_grid div.row_2 .card_group_tips_n_quote {
		width: 100%;
	}

	.estimate p {
		font-size: 14px;
	}
}
