@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400,500&family=Raleway:wght@500,600&display=swap");
@import "./variables.scss";
@import "../../node_modules/bootstrap/scss/bootstrap";

@font-face {
	font-family: "Helvetica_Neue";
	font-weight: 400;
	src: local("Helvetica_Neue"), url(../assets/fonts/HelveticaNeueLight.ttf) format("ttf");
}

@font-face {
	font-family: "Helvetica_Neue";
	font-weight: 500;
	font-style: italic;
	src: local("Helvetica_Neue"), url(../assets/fonts/HelveticaNeue-MediumCondObl.otf) format("otf");
}

@font-face {
	font-family: "Helvetica_Neue";
	font-weight: 500;
	src: local("Helvetica_Neue"), url(../assets/fonts/HelveticaNeueMedium.ttf) format("ttf");
}

@font-face {
	font-family: "Helvetica_Neue";
	font-weight: 700;
	src: local("Helvetica_Neue"), url(../assets/fonts/HelveticaNeueBold.ttf) format("ttf");
}

@font-face {
	font-family: "SF_PRO";
	font-weight: 500;
	src: local("SF_PRO"), url(../assets/fonts/SFPRODISPLAYMEDIUM.OTF) format("otf");
}

@font-face {
	font-family: "SF_PRO";
	font-weight: 400;
	src: local("SF_PRO"), url(../assets/fonts/SFPRODISPLAYREGULAR.OTF) format("otf");
}

header.about_us_header {
	width: 100%;
	position: fixed;
	top: 10px;
	z-index: 10;
	ul {
		text-decoration: none;
		li {
			list-style: none;
		}
	}
	.header_content {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: auto;
		margin: 20px auto;
		padding: 8px 8px 8px 16px;
		border-radius: 16px;
		height: 4rem;
		color: black;
		background-color: white;
		width: 90%;
		// opacity: 0.75;
		font-family: "Helvetica_Neue", sans-serif;
		font-weight: 500;
		a.button {
			background-color: $primary;
			color: white;
			display: flex;
			align-items: center;
			font-family: "Helvetica_Neue", sans-serif;
			font-weight: 500;
			padding: 16px 20px 16px 20px;
			height: 3rem;
			opacity: 1;
			border-radius: 12px;
		}
		a:hover{
		text-decoration: none;
		}
	}
	div {
		display: flex;
		align-items: center;
		gap: 40px;
	}
	ul {
		display: flex;
		align-items: center;
		margin: 0;
		gap: 40px;
		a {
			color: #303030;
		}
		a.active {
			color: $primary;
		}
	}
}

@media (min-width: 1600px) {
	main.about_us {
		width: 1600px;
		margin: auto;
	}
	header.about_us_header {
		margin: 0px auto;
		justify-content: center;
		display: flex;
	}
	header.about_us_header .header_content {
		position: fixed;
		max-width: 1550px;
		top: 0px;
	}
}

main.about_us {
	section {
		padding: 35px 70px;
	}
	section.hero {
		padding: 35px 70px;
		background-image: url("../assets/img/about_us_banner.webp");
		background-position: center;
		height: 750px;
		background-size: cover;
		background-repeat: no-repeat;
		color: white;
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		justify-content: flex-end;
		.note_region {
			gap: 40px;
			display: flex;
			flex-direction: column;
			width: 50%;
			height: 400px;
			.row_1 {
				display: flex;
				justify-content: space-between;
				width: 100%;
			}
			.user_note {
				display: flex;
				flex-direction: column;
				align-items: flex-end;
				justify-content: flex-end;
				font-family: "SF_PRO", sans-serif;
				span {
					border-radius: 6px 6px 0px 0px;
					padding: 4px 12px 4px 12px;
					height: 20px;
					font-weight: 400;
					font-size: 0.625rem;
					line-height: 12px;
					letter-spacing: 0.05px;
				}
				&.first span {
					background-color: #e845ba;
				}
				&.second span {
					background-color: #f6671c;
				}
				&.third span {
					background-color: #63e46f;
				}
				div {
					background-color: white;
					color: black;
					border-radius: 12px 0px 12px 12px;
					padding: 16px;
					p {
						font-weight: 400;
						font-size: 1rem;
						letter-spacing: 0.01px;
						margin: 0;
					}
				}
			}
		}
		.hero_content {
			width: 100%;
			display: flex;
			justify-content: space-between;
			color: white;
			h1 {
				font-weight: 400;
				font-family: "Helvetica_Neue", sans-serif;
				font-size: 3rem;
				line-height: 56px;
				letter-spacing: 0.05px;
			}
			p {
				width: 45%;
				font-size: 1.25rem;
				line-height: 24px;
				font-weight: 400;
				font-family: "Helvetica_Neue", sans-serif;
			}
		}
	}
	section.vision {
		display: flex;
		background-color: white;
		flex-direction: row;
		padding: 90px 70px;
		justify-content: space-between;
		align-items: center;
		h3 {
			color: black;
			font-family: "Helvetica_Neue", sans-serif;
			font-weight: 400;
			font-size: 3rem;
			line-height: 56px;
			letter-spacing: 0.05px;
			span {
				color: $primary;
			}
		}
		p {
			font-family: "Helvetica_Neue", sans-serif;
			font-weight: 400;
			width: 45%;
			font-size: 1.25rem;
			line-height: 24px;
			font-weight: 400;
			margin: 0;
			color: #303030;
			span {
				color: $primary;
			}
		}
	}
	section.our_journey {
		padding: 0px;
		margin: 0px;
		color: white;
		.section_1 {
			color: white;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 0px 75px;
			background-image: url("../assets/img/journey_banner_1.webp");
			background-position: center;
			height: 400px;
			background-size: cover;
			background-repeat: no-repeat;
			h3 {
				font-family: "Helvetica_Neue", sans-serif;
				font-weight: 400;
				font-size: 3rem;
				line-height: 56px;
				letter-spacing: 0.05px;
			}
			div {
				width: 40%;
				padding: 0px 15px;
				font-family: "Helvetica_Neue", sans-serif;
				font-weight: 400;
				font-size: 1.25rem;
				line-height: 24px;
				background-color: #ffffff26;
				height: 100%;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				justify-content: center;
				ul {
					margin: 20px 0px;
					padding: 0;
					li {
						margin: 10px 0;
					}
				}
			}
		}
		.section_2 {
			padding: 70px 75px;
			background-image: url("../assets/img/journey_banner_2.webp");
			background-position: center;
			height: 450px;
			border-top: 0.5px solid #ffffff90;
			background-size: cover;
			background-repeat: no-repeat;
			display: flex;
			gap: 20px;
			align-items: center;
			justify-content: space-between;
			div {
				background-color: #ffffff26;
				height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				align-items: center;
				padding: 20px 20px 50px;
				border-radius: 10px;
				width: 250px;
				backdrop-filter: blur(10px);
				border: 1px solid #ffffff26;
				h5 {
					font-family: "SF_PRO", sans-serif;
					font-size: 1rem;
					letter-spacing: 0.04px;
					font-weight: 500;
				}
				img {
					height: 100px;
					width: fit-content;
				}
			}
		}
	}
	section.help_you_plan {
		padding: 50px 60px 40px;
		h3 {
			color: black;
			font-family: "Helvetica_Neue", sans-serif;
			font-weight: 400;
			font-size: 3rem;
			line-height: 56px;
			letter-spacing: 0.05px;
			span {
				color: $primary;
			}
		}

		.plan_group {
			display: flex;
			gap: 80px;
			margin-top: 80px;
			h6 {
				font-weight: 700;
				font-family: "Helvetica_Neue", sans-serif;
				font-size: 1.625rem;
				line-height: 32px;
				color: #151618;
				margin: 20px 0px;
			}
			p {
				font-family: "Helvetica_Neue", sans-serif;
				font-size: 1.25rem;
				line-height: 28px;
				color: #303030;
				font-weight: 400;
				margin: 0;
			}
			img {
				height: 400px;
				width: 100%;
				border-radius: 26px;
				object-fit: cover;
			}
		}
	}
	section.the_team {
		background-image: url("../assets/img/the_team.webp");
		background-position: center;
		// height: 1000px;
		margin: 0px;
		padding-top: 0;
		padding-bottom: 0;
		background-size: cover;
		background-repeat: no-repeat;
		display: flex;
		align-items: center;
		justify-content: space-between;
		h3 {
			font-family: "Helvetica_Neue", sans-serif;
			font-weight: 400;
			font-size: 3rem;
			line-height: 56px;
			letter-spacing: 0.05px;
			color: white;
			span {
				color: $primary;
			}
		}
		.team_group {
			width: 50%;
			padding: 60px 20px;
			font-family: "Helvetica_Neue", sans-serif;
			font-weight: 400;
			font-size: 1.25rem;
			line-height: 24px;
			background-color: #ffffff26;
			height: 100%;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: center;
			position: relative;
			z-index: 2;
			gap: 30px;
			color: white;
			.team_member {
				display: flex;
				align-items: flex-end;
				gap: 20px;
				img.team_mate_img {
					height: 200px;
					width: 200px;
					border-radius: 20px;
					object-fit: cover;
					position: relative;
					z-index: 1;
					-webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
					filter: grayscale(100%);
					&:hover {
						filter: unset;
					}
				}
				div {
					font-family: "Helvetica_Neue", sans-serif;
					h6 {
						font-weight: 700;
						font-size: 1.25rem;
						line-height: 24px;
					}
					p {
						font-weight: 400;
						font-size: 1rem;
						line-height: 20px;
						letter-spacing: 0.01px;
					}
					.social_icon_group {
						display: flex;
						gap: 10px;
						img {
							height: 40px;
							width: 40px;
							border-radius: 0px;
						}
					}
				}
			}
		}
	}
	section.join_community {
		background-color: $primary;
		color: white;
		margin: 0;
		padding: 60px 0px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: 320px;
		font-family: "Helvetica_Neue", sans-serif;
		font-weight: 400;
		gap: 10px;
		p {
			font-size: 1.125rem;
			margin: 0;
		}
		h4 {
			font-size: 2.5rem;
		}
		a {
			background-color: #fff;
			color: $primary;
			font-family: "Helvetica_Neue", sans-serif;
			font-size: 1rem;
			font-weight: 500;
			letter-spacing: 0.01px;
			border-radius: 12px;
			padding: 16px 48px 16px 48px;
			&:hover{
			text-decoration: none;
			}
		}
	}
}

footer.about_us.footer {
	background-color: white;
	margin-top: 0px;
	.top_section {
		width: 90%;
		div p {
			color: black;
		}
		div {
			a.icon,
			a.go_up {
				background-color: #f5f5f580;
				height: 40px;
				border-radius: 8px;
				width: 40px;
			}
			a.icon {
				span {
					background-color: $primary;
				}
			}
		}
	}
	hr {
		border: 0.5px solid $primary;
		width: 90%;
	}
	.bottom_section {
		width: 90%;
		a,
		p {
			color: $primary;
			opacity: 1;
		}
		a {
			text-decoration: underline;
			font-size: 0.875rem;
			opacity: 1;
		}
	}
}

@media (max-width: 1024px) {
	main.about_us section.hero .note_region {
		width: 65%;
	}

	main.about_us section.hero {
		padding: 35px 45px;
	}

	main.about_us section.help_you_plan .plan_group {
		gap: 30px;
	}

	main.about_us section.help_you_plan {
		padding: 50px 45px 40px;
		margin: 0;
	}

	main.about_us section.our_journey .section_2 {
		padding: 70px 45px;
	}

	main.about_us section.the_team {
		padding: 0px 45px;
	}

	main.about_us section.our_journey .section_1 {
		padding: 0px 45px;
	}

	main.about_us section.vision {
		padding: 90px 45px;
		margin: 0;
	}

	main.about_us section.hero .hero_content h1 {
		font-size: 2.5rem;
		line-height: 40px;
	}

	main.about_us section.vision h3 {
		font-size: 2rem;
		line-height: 32px;
	}

	main.about_us section.our_journey .section_1 h3 {
		font-size: 2rem;
		line-height: 32px;
	}

	main.about_us section.help_you_plan h3 {
		font-size: 2rem;
		line-height: 32px;
	}

	main.about_us section.the_team h3 {
		font-size: 2rem;
		line-height: 32px;
	}

	main.about_us section.help_you_plan .plan_group h6 {
		font-size: 1.4rem;
	}

	main.about_us section.help_you_plan .plan_group p {
		font-size: 1rem;
	}
}

@media (max-width: 768px) {
	main.about_us section.our_journey .section_2 {
		flex-direction: column;
	}

	header.about_us_header .header_content button {
		font-size: 10px;
		padding: 10px 14px;
		height: unset;
		border-radius: 6px;
		color: white;
	}

	main.about_us section.our_journey .section_1 {
		flex-direction: column;
		height: unset;
		padding: 40px 0px;
		h3 {
			text-align: center;
		}

		div {
			padding: 25px 20px;
			margin-top: 30px;
		}
	}

	main.about_us section.vision {
		flex-direction: column;
		padding: 50px 20px;
		h3 {
			font-size: 1.5rem;
			line-height: 24px;
			text-align: center;
		}
		p {
			width: 100%;
			font-size: 1rem;
			line-height: 24px;
		}
	}

	main.about_us section.hero {
		padding: 150px 20px 50px;
		flex-direction: column;
		justify-content: center;
		height: fit-content;
		.hero_content {
			flex-direction: column;
			margin-top: 30px;
			h1 {
				font-size: 1.6rem;
				line-height: 28px;
				text-align: center;
			}
			p {
				width: 100%;
				font-size: 1rem;
				line-height: 20px;
				margin-top: 10px;
			}
		}
		.note_region {
			width: 100%;
			height: unset;
			flex-direction: column;
			justify-content: center;
			.user_note div p {
				font-size: 9px;
			}
			.user_note.third {
				justify-content: center;
				margin: auto;
			}
		}
	}

	header.about_us_header ul {
		display: none;
	}

	main.about_us section.help_you_plan .plan_group {
		flex-direction: column;
	}

	main.about_us section.the_team .team_group {
		width: 100%;
		padding: 0;
	}

	main.about_us section.the_team {
		flex-direction: column;
	}
	main.about_us section.our_journey {
		.section_1 {
			h3 {
				font-size: 24px;
				line-height: 24px;
			}
			div {
				width: 100%;
				font-size: 1rem;
				line-height: 20px;
			}
		}
	}
	main.about_us section.help_you_plan {
		padding: 60px 15px;
		height: unset;
	}

	main.about_us section.our_journey .section_2 {
		height: unset;
		padding: 50px 15px;
		columns: 2;
	}

	main.about_us section.our_journey .section_2 div img {
		height: 50px;
	}

	main.about_us section.our_journey .section_2 div h5 {
		font-size: 0.75rem;
	}

	main.about_us section.our_journey .section_2 div {
		width: 200px;
	}

	main.about_us section.help_you_plan {
		.plan_group {
			margin-top: 40px;
			gap: 40px;
			h3 {
				font-size: 1.5rem;
				line-height: 28px;
				text-align: center;
			}
			h6 {
				font-size: 1.4rem;
				line-height: 28px;
			}
			p {
				font-size: 1rem;
				line-height: 24px;
			}
		}
		h3 {
			font-size: 1.5rem;
			line-height: 28px;
			text-align: center;
		}
	}

	main.about_us section.the_team {
		padding: 40px 0px;
		h3 {
			font-size: 1.5rem;
			line-height: 24px;
			text-align: center;
		}
		.team_group {
			padding: 40px 15px;
			margin-top: 20px;
			.team_member img.team_mate_img {
				height: 150px;
				width: 150px;
			}
		}
	}

	main.about_us section.join_community {
		h4 {
			font-size: 1.5rem;
		}
		p {
			font-size: 0.75rem;
		}
	}
}

@media (max-width: 768px) and (min-width: 600px) {
	main.about_us section.our_journey .section_2 {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: 30px;
		div {
			width: 100%;
		}
	}
	main.about_us section.help_you_plan .plan_group {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
	}
}

@media (max-width: 600px) {
	main.about_us section.our_journey .section_2 {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
	}

	main.about_us section.our_journey .section_2 div {
		width: 100%;
	}
}
