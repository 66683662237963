@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400,500&family=Manrope:wght@400,500,600,700&display=swap");
@import "./variables.scss";

.dashboard_modal.show.add_event_day_task_modal {
	.modal-dialog {
		div.element_group {
			display: flex;
			align-items: center;
			gap: 10px;

			.vendor_input {
				min-width: 130px;
				position: relative;
			}
		}
	}
}

.event_day_view {
	border-radius: 6px;
	background-color: white;
	padding: 16px 21px 24px 21px;
	margin-top: 10px;
	font-family: "Inter", sans-serif;
	display: flex;
	flex-direction: row;
	gap: 20px;

	align-items: flex-start;
	.event_day_statistics {
		display: flex;
		align-items: center;
		flex-direction: column;
		border: 1px solid #5455660d;
		background: #fbfbfb;
		padding: 6px 4px 20px;
		border-radius: 6px;
		width: 148px;
		gap: 20px;
		align-items: flex-start;
		.task_percentage {
			width: 100%;
			box-shadow: 0px 1px 10px 0px #0000000d;
			gap: 2px;
			background-color: #fff;
			height: 42px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			border-radius: 6px;
			padding: 0px 8px 4px;
			.progress_container {
				height: 4px;
				border-radius: 50px;
				background-color: #0000001a;
				.progress {
					height: 4px;
					border-radius: 50px;
					background-color: #06b217;
				}
			}
			p {
				font-size: 12px;
				color: #404040;
				line-height: 18px;
				font-weight: 400;
				width: fit-content;
				margin-bottom: 2px;
			}
		}
		.date_group {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			gap: 20px;
			margin-left: 10px;
			h6 {
				font-size: 12px;
				font-weight: 400;
				line-height: 18px;
				letter-spacing: -0.025em;
				text-align: left;
				color: #9395aa;
				margin-bottom: 4px;
			}
			time {
				font-size: 13px;
				font-weight: 500;
				line-height: 18.2px;
				letter-spacing: -0.025em;
				text-align: left;
				color: #404040;
				div {
					display: flex;
					align-items: center;
					gap: 4px;
				}

				svg {
					height: 12px;
					width: 12px;
				}
			}
			.date_period {
				display: flex;
				gap: 0px;
				flex-direction: column;
				align-items: flex-start;
				time {
					margin-left: 5px;
				}
				svg {
					margin: 10px 0px;
				}
			}
		}
	}
	.event_day_flow_list_wrapper {
		background-color: white;
		display: flex;
		flex-direction: column;
		height: 480px;
		width: 100%;
		justify-content: space-between;
		@media (max-width: 786px) {
			height: unset;
		}
		.event_day_flow_list {
			display: flex;
			flex-direction: column;
			gap: 10px;
			width: 100%;
			.event_day_flow_item {
				display: flex;
				align-items: flex-start;
				gap: 20px;
				width: 100%;
				div.status {
					width: 25px;
				}
				.event_day_item_group {
					display: flex;
					align-items: flex-start;
					width: 100%;
					gap: 20px;
					justify-content: space-between;

					.event_day_item_action_group {
						button.status {
							display: none;
						}
						button.delete_button {
							display: flex;
							align-items: center;
							justify-content: center;
						}
					}

					.event_day_flow_body {
						width: 100%;
						background-color: white;
						border: 1px solid #5455660d;
						border-radius: 6px;
						padding: 8px 12px;
						box-shadow: 0px 1px 10px 0px #0000000d;
						display: flex;
						flex-direction: column;
						.item__row_1 {
							display: flex;
							flex-direction: row;
							justify-content: space-between;
							h4 {
								font-size: 13px;
								font-weight: 500;
								line-height: 18.2px;
								letter-spacing: -0.025em;
								text-align: left;
								color: #031729;
							}
							div {
								display: flex;
								flex-direction: row;
								background: #fbfbfb;
								border-radius: 6px;
								padding: 2px 2px 2px 4px;
								button {
									border: none;
									svg {
										height: 12px;
										width: 12px;
									}
								}
								button:last-child {
									background-color: white;
									box-shadow: 0px 1px 10px 0px #0000000d;
									border-radius: 4px;
									padding: 0px 6px;
									height: 24px;
									width: 24px;
									svg {
										stroke: #888c8f;
									}
								}
								button:first-child {
									background-color: #fbfbfb;
									display: flex;
									align-items: center;
									justify-content: center;
									svg {
										stroke: #404040;
									}
								}
							}
						}
						.item__row_2 {
							display: flex;
							flex-direction: row;
							font-size: 12px;
							font-weight: 400;
							line-height: 18px;
							letter-spacing: -0.025em;
							align-items: center;
							hr {
								margin: 0px 10px;
								height: 14px;
								width: 1px;
								color: black;
								background-color: #e9e9e9;
							}
							svg {
								height: 12px;
								width: 12px;
								stroke: #888c8f;
								margin: 0px 4px;
							}
							.item_group {
								display: flex;
								align-items: center;
							}
						}
					}
					button.status {
						border: 1px solid #5455660d;
						border-radius: 6px;
						background-color: white;
						height: 32px;
						font-size: 12px;
						font-weight: 500;
						line-height: 18px;
						min-width: 80px;
						letter-spacing: -0.025em;
						padding: 0px 12px;
						text-wrap: nowrap;
						white-space: nowrap;
						display: flex;
						align-items: center;
						justify-content: center;
						&.active {
							background-color: #34a853;
							color: white;
						}
					}
				}
			}
		}
		.paginate_event {
			font-size: 12px;
			select#events {
				font-size: 12px;
			}
			.pagination_btn_group {
				display: flex;
				align-items: center;
				justify-content: center;
			}
			button {
				background-color: white;
				border: 1px solid #5455660d;
				border-radius: 6px;
				padding: 8px 10px;
				height: 32px;
				width: 32px;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
	}

	@media (max-width: 1024px) {
		flex-direction: column;
		.event_day_statistics {
			width: 100%;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			padding-bottom: 6px;
			.date_group {
				flex-direction: row;
				align-items: center;
				.date_period {
					flex-direction: row;
					align-items: center;
					svg {
						transform: rotate(90deg);
						margin-top: -2px;
						margin: -5px 10px 5px 10px;
					}
					h6 {
						margin-bottom: 0;
					}
				}
			}
			.date_group time {
				flex-direction: row;
				display: flex;
				h6 {
					margin-bottom: 0;
					margin-right: 5px;
				}
			}
			.task_percentage {
				width: 30%;
			}
		}
	}

	@media (max-width: 768px) {
		.event_day_flow_list_wrapper .event_day_flow_list .event_day_flow_item .event_day_item_group {
			button.status {
				display: none;
			}
			.event_day_item_action_group {
				button.status {
					display: flex;
					color: #000;
					width: fit-content;
					min-width: fit-content;
				}
			}
		}
	}

	@media (max-width: 600px) {
		.event_day_flow_list_wrapper .event_day_flow_list .event_day_flow_item .event_day_item_group {
			gap: 10px;

			.event_day_flow_body .item__row_2 {
				flex-direction: column;
				align-items: flex-start;
				gap: 10px;
				margin-top: 10px;
			}
		}
	}
}
@media (max-width: 560px) {
	.event_day_flow {
		.checklist_view .title_row .dropdown_group {
			max-width: 50px;
		}
		.add_task {
			span {
				display: none;
			}
		}
		.event_day_view {
			.event_day_statistics {
				flex-direction: column;
				align-items: flex-start;
				.task_percentage {
					width: 100%;
				}
				.date_group {
					flex-direction: column;
					align-items: flex-start;
					gap: 10px;
				}
			}
			.event_day_flow_list_wrapper .event_day_flow_list .event_day_flow_item {
				gap: 10px;
				button.status {
					display: none;
				}
				.event_day_item_group .event_day_flow_body {
					.item__row_1 {
						align-items: center;
						div button {
							display: flex;
							align-items: center;
							/* gap: 5px; */
						}
						h4 {
							margin-bottom: 0;
						}

						.event_day_item_action_group {
							gap: 5px;
							align-items: center;
						}
					}
					.item__row_2 {
						.group__1 {
							margin-top: 10px;
						}
						.item_group {
							.list_separator {
								display: none;
							}
						}
						time {
							display: flex;
							align-items: center;
							text-wrap: nowrap;
							white-space: nowrap;
						}
					}
				}
			}
		}
	}
}
