button.button.btn {
	border-radius: 5px;
	font-family: 'Poppins';
	font-style: normal;
	border: 0px;
	width: fit-content;
}

button {
	&:hover {
		opacity: 0.9;
	}
}

button.button.is__loading {
	padding-right: 50px !important;
	text-wrap: nowrap;
	white-space: nowrap;
}

button.btn:focus,
button.btn.focus {
	box-shadow: 0 0 0 0.05rem rgba(203, 48, 80, 0.25);
}

button.bg_dark {
	background: #545565;
	padding: 9px 16px;
}

button.with_icon {
	gap: 25px;
	display: flex;
	align-items: center;
}

button.font_base {
	font-weight: 500;
	font-size: 18px;
	line-height: 27px;
}

button.bg_pinky {
	background: #fceded;
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 18px;
	color: #545565;
	padding: 6px 15px;
}

button.bg_red {
	background: #cb3050;
	border-radius: 5px;
	padding-left: 20px;
	padding-right: 20px;
}

button.btn:hover {
	opacity: 0.8;
	transition: ease-in-out opacity 1s;
}

button.button {
	position: relative;
	.loader {
		position: absolute;
		right: 40px;
	}
}
