div.policy_page {
	.policy.content {
		overflow-y: scroll;
		justify-content: unset;
		font-family: "Inter", sans-serif;
		align-items: flex-start;
		scroll-behavior: smooth;

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			font-size: 16px;
			font-weight: 500;
		}
		p,
		ul,
		li {
			font-size: 14px;
		}
		b {
			font-weight: 500;
		}
	}

	.policy_sidebar_link {
		background-color: #fff;
		border-radius: 8px;
		display: flex;
		align-items: flex-start;
		gap: 10px;
		flex-direction: column;
		padding: 20px 30px 20px 35px;
		@media (max-width: 768px) {
			display: none;
		}
	}
	aside {
		@media (max-width: 768px) {
			min-height: 150px;
			height: 150px;
		}
	}
}
