.location .dropdown button.dropdown-btn {
	max-width: 300px;
	text-wrap: nowrap;
	white-space: nowrap;
	text-overflow: ellipsis;
	/* width: 200px; */
	overflow: hidden;
}

.budget_calculator_banner {
	width: 80%;
  position: relative;
  z-index: 1000;
	margin: auto;
	@media (max-width: 768px) {
		width: fit-content;
	}
}

.budget_calculator_banner {
	width: 80%;
  position: relative;
  z-index: 1000;
	margin: auto;
	@media (max-width: 768px) {
		width: fit-content;
	}
}