@import "./variables.scss";

.settings_page {
	display: flex;
	flex-direction: row;
	border-radius: 6px;
	padding: 6px;
	font-family: "Inter", sans-serif;
	height: calc(100vh - 150px);
	align-items: flex-start;
	background-color: #fff;
	font-size: 12px;
	font-weight: 400;
	aside {
		width: 240px;
		ul {
			list-style: none;
			gap: 20px;
			display: flex;
			flex-direction: column;
			line-height: 18px;
			letter-spacing: -0.025em;
			text-align: left;
			color: #404040;
			padding: 0;

			li {
				display: flex;
				align-items: center;
				flex-direction: row;
				position: relative;
				a {
					color: #404040;
					display: flex;
					&:hover {
						text-decoration: none;
						color: #e72e50;
					}
				}
				a::before {
					content: "";
					height: 16px;
					display: flex;
					width: 4px;
					border-radius: 6px;
					margin-right: 12px;
				}
				&.active {
					a {
						color: #e72e50;
						display: flex;
					}
					a::before {
						content: " ";
						height: 16px;
						width: 2px;
						background-color: #e72e50;
						margin-right: 12px;
						display: flex;
						border-radius: 6px;
					}
				}
			}
		}
	}
	.settings_view {
		width: 100%;
		padding: 12px;
		border-radius: 6px;
		background-color: #fafafc;
		h4 {
			font-size: 14px;
			font-weight: 500;
			line-height: 19.6px;
			letter-spacing: -0.025em;
			text-align: left;
			color: #031729;
		}
		.subscription_benefit {
			background-color: #eea4ae;
			width: 100%;
			padding: 12px;
			border-radius: 6px;
			margin: 5px 0 8px;
			ul {
				padding: 0;
				li {
					list-style: none;
					color: #000;
					margin: 10px 0px;
					svg {
						height: 14px;
						width: 14px;
					}
				}
			}
			h6 {
				color: #000;
				font-size: 12px;
				font-weight: 500;
				span {
					font-size: 22px;
					font-weight: bold;
				}
			}
		}
		.profile_row {
			width: 100%;
			height: 88px;
			background-color: #fff;
			padding: 16px 24px;
			margin-bottom: 6px;
			display: flex;
			border-radius: 6px;
			align-items: center;
			justify-content: space-between;
			.image_wrapper {
				display: flex;
				flex-direction: column;
				align-items: center;
				.upload_image {
					flex-direction: column;
				}
				svg {
					height: 12px;
					width: 12px;
					position: relative;
					z-index: 1;
					stroke: $slate_gray;
				}
				p {
					margin-bottom: 0;
					color: $slate_gray;
					font-size: 10px;
					font-weight: 500;
				}
			}
			.text_content {
				h6 {
					font-size: 14px;
					font-weight: 500;
					line-height: 19.6px;
					letter-spacing: -0.025em;
					text-align: left;
					color: #031729;
					margin: 0;
					sup {
						color: #34a853;
						border: 1px solid #26cf9f;
						background: #e8fff4;
						border-radius: 3px;
						padding: 2px 4px;
						font-size: 10px;
						font-weight: 500;
						line-height: 10px;
						margin-left: 10px;
						&.inactive {
							color: #d64d12;
							border: 1px solid #e71a03;
							background: #efe7e7;
						}
					}
				}
				p {
					color: #8f8c88;
					padding: 0;
					margin: 0;
					font-size: 12px;
					font-weight: 400;
					line-height: 18px;
					letter-spacing: -0.025em;
				}
			}
			.select_country {
				label {
					color: #888c8f;
					padding: 0;
					margin: 0;
					font-size: 12px;
					font-weight: 400;
					line-height: 18px;
					letter-spacing: -0.025em;
				}
				select {
					font-size: 14px;
					font-weight: 500;
					line-height: 19.6px;
					letter-spacing: -0.025em;
					text-align: left;
					color: #031729;
					margin: 0;
					border: none;
					padding: 0px;
					height: unset;
					&:focus {
						box-shadow: none;
					}
					&.form-control {
						appearance: auto;
						background: unset;
					}
				}
				&.editable {
					border: 1px solid #031729;
					color: #031729;
					border-radius: 8px;
					height: 54px;
					font-size: 12px;
					font-weight: 500;
					letter-spacing: -0.025em;
					width: 40%;
					padding: 10px 10px 0;
					label {
						margin-left: 3px;
					}
				}
			}
			.input_wrapper {
				input {
					border: 1px solid #031729;
					color: #031729;
					&::placeholder {
						color: #031729;
					}
				}
			}
			button {
				border: none;
				background-color: #fff;
				font-weight: 500;
				line-height: 18px;
				letter-spacing: -0.025em;
				text-align: left;
				color: #031729;
				border: 1px solid #5455660d;
				height: 32px;
				padding: 0px 12px;
				border-radius: 6px;
				&.save_changes {
					background-color: #e72e50;
					color: #fff;
				}
			}
			.input_wrapper {
				width: 50%;
				input {
					width: 100%;
				}
			}
			.input_with_img {
				display: flex;
				align-items: center;
				width: 50%;
				gap: 12px;
				.upload_image {
					width: 45px;
					height: 45px;
					border-radius: 6px;
					background-color: #fafafc;
					padding: 10px;
					display: flex;
					position: relative;
					cursor: pointer;
					align-items: center;
					justify-content: center;
					position: relative;
					z-index: 1;
					overflow: hidden;
					input {
						position: absolute;
						width: 100%;
						height: 100%;
						z-index: 100;
						cursor: pointer;
						&::-webkit-file-upload-button {
							visibility: hidden;
						}
					}
					&.user_img {
						border-radius: 6px;
						padding: 0;
						object-fit: cover;
						object-position: top center;
					}
					img {
						height: 40px;
						width: 40px;
						border-radius: 6px;
					}
				}
				.input_wrapper {
					width: 100%;
				}
			}

			@media (max-width: 560px) {
				gap: 10px;
				flex-direction: column;
				align-items: flex-start;
				height: 100px;
				button {
					padding: 2px 12px;
				}
			}
		}
		.manage_collaborators {
			background-color: #fff;
			padding: 16px 24px;
			width: 100%;

			.group_row {
				display: flex;
				align-items: center;
				justify-content: space-between;
				button {
					border: none;
					background-color: #031729;
					border-radius: 6px;
					padding: 0px 12px;
					height: 32px;
					color: #fff;
					border: 1px solid #5455660d;
					font-size: 12px;
					font-weight: 500;
					line-height: 18px;
					letter-spacing: -0.025em;
					text-align: left;
				}
				h4 {
					font-size: 13px;
					font-weight: 500;
					line-height: 18.2px;
					letter-spacing: -0.025em;
					text-align: left;
					color: #031729;
					margin-bottom: 0;
				}
			}

			.collaborators {
				margin-top: 24px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				button {
					color: #031729;
					background-color: transparent;
					border: 1px solid #5455660d;
					padding: 0px 12px;
					height: 32px;
					font-weight: 500;
					line-height: 18px;
					letter-spacing: -0.025em;
					border-radius: 6px;
				}
				.left_side {
					display: flex;
					flex-direction: column;
					p {
						color: #71798c;
						font-size: 12px;
						font-weight: 400;
						line-height: 18px;
						letter-spacing: -0.025em;
						margin: 0;
						padding: 0;
					}
					h6 {
						font-size: 14px;
						font-weight: 500;
						line-height: 19.6px;
						letter-spacing: -0.025em;
						margin: 0;
						padding: 0;
						color: #031729;
					}
				}
			}
		}
	}
}
@media (max-width: 768px) {
	section.settings_page {
		flex-direction: column;
		align-items: flex-start;
	}

	section.settings_page aside ul {
		display: flex;
		flex-direction: row;
	}
}
