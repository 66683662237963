@import "./variables.scss";

p.ceremony_note_warning {
	font-size: 14px;
	color: $primary;
	margin-top: 5px;
}

button.react-datepicker__navigation.react-datepicker__navigation {
	display: flex !important;
}
.react-datepicker__navigation-icon::before,
.react-datepicker__navigation-icon::after {
	border-color: #000;
}
.add_new_ceremony {
	.ceremony_note_warning {
		margin-top: 10px;
	}
}

input:focus::placeholder {
	color: transparent !important;
}

.switch_wrapper {
	display: flex;
	align-items: center;
	gap: 10px;
	border: 0.5px solid #5455660d;
	background-color: #fafafc;
	height: 28px;
	padding: 0px 4px;
	border-radius: 6px;
	.label {
		font-size: 12px;
		font-weight: 500;
		line-height: 18px;
		letter-spacing: -0.025em;
		color: #404040;
		margin: 0;
	}
	.switch {
		position: relative;
		display: inline-block;
		height: 18px;
		width: 32px;

		.switch-input {
			display: none;
		}
		.switch-label {
			display: block;
		}
		.switch-label::before,
		.switch-label::after {
			content: "";
			display: block;
			position: absolute;
			cursor: pointer;
		}
		.switch-label::before {
			border-radius: 9999em;
			height: 16px;
			width: 28px;
			background-color: #e2e2e2;
			-webkit-transition: background-color 0.25s ease;
			transition: background-color 0.25s ease;
		}
		.switch-label::after {
			box-shadow: 0px 1.64px 3.27px -0.82px #0000000f;
			box-shadow: 0px 3.27px 4.91px -0.82px #0000001a;
			box-shadow: 0px 0px 0px 0.41px #ffffff;
			height: 12px;
			width: 12px;
			top: 2px;
			left: 3px;
			border-radius: 50%;
			background-color: #fff;
			box-shadow: 0 0 2px rgba(0, 0, 0, 0.45);
			-webkit-transition: left 0.25s ease;
			transition: left 0.25s ease;
		}
		.switch-input:checked + .switch-label::before {
			background-color: $base_red;
		}
		.switch-input:checked + .switch-label::after {
			left: 13px;
			top: 2px;
		}
	}
}
.switch_input {
	display: flex;
	align-items: center;
	gap: 10px;
	label {
		font-size: 12px;
		font-weight: 500;
		line-height: 18px;
		letter-spacing: -0.025em;
		color: #404040;
		margin: 0;
	}
	.switch_input_view {
		height: 16px;
		width: 28px;
		.switch_input_wrapper {
			background-color: #e2e2e2;
			border-radius: 80px;
			height: 16px;
			width: 28px;
			position: relative;
			padding: 2px 3px;
			z-index: 1;
			.switch {
				box-shadow: 0px 1.64px 3.27px -0.82px #0000000f;
				box-shadow: 0px 3.27px 4.91px -0.82px #0000001a;
				box-shadow: 0px 0px 0px 0.41px #ffffff;
				height: 12px;
				width: 12px;
				background-color: #fff;
				border-radius: 50%;
			}
		}
		input {
			position: absolute;
			left: -100vw;
			&:checked + .switch_input_wrapper {
				background-color: $base_red;
			}
		}
		input[type="checkbox"]:checked + .switch_input_wrapper {
			background-color: $base_red;
			.switch {
				position: absolute;
				right: 2px;
			}
		}
	}
}
.custom_input_view {
	display: flex;
	align-items: center;
	font-family: "Inter", sans-serif;
	width: 100%;
	.input2 {
		width: 100%;
		button.password_icon {
			border: none;
			right: 5px;
			position: absolute;
			bottom: 10px;
			background-color: transparent;
			img {
				height: 16px;
				width: 16px;
			}
		}
		input::placeholder {
			font-family: "Inter", sans-serif !important;
		}
	}
	.link_bar {
		border-width: 1px 0px 1px 1px;
		border-style: solid;
		border-color: #30303026;
		background: #fbfbfb;
		padding: 8px;
		border-radius: 6px 0px 0px 6px;
		border: 1px 0px 1px 1px;
		font-family: "Inter", sans-serif;
		color: #9395aa;
		font-size: 11px;
		font-weight: 500;
		line-height: 13.75px;
		text-align: left;
		height: 54px;
		display: flex;
		align-items: center;
		p {
			margin-bottom: 0;
		}

		& + .input2 input {
			border-top-left-radius: 0px !important;
			border-bottom-left-radius: 0px !important;
			margin-bottom: 0px !important;
		}
	}
}

input:focus-visible {
	outline-offset: none;
	outline: none;
}

.select_date_input_view label {
	z-index: 1;
}

.radio_group {
	display: flex;
	align-items: center;
	justify-content: space-between;
	label {
		margin: 0;
	}
	div {
		display: flex;
		flex-direction: row;
		gap: 15px;
		height: 50px;
		width: 100%;
	}
	@media (max-width: $bp-lg-phone) {
		flex-direction: column;
		align-items: flex-start;
	}
}

.form_element.input2 {
	position: relative;
	.form-control:focus {
		box-shadow: none;
	}
	&.select_date_input {
		width: 30%;
		input {
			width: 100%;
		}
		button.btn {
			display: none;
		}
		button.react-datepicker__navigation {
			width: 30px !important;
			height: 30px !important;
		}
		label {
			z-index: 1;
		}
	}
	label {
		position: absolute;
		font-size: 10px;
		color: $slate_gray;
		left: 14px;
		top: 3px;
		font-weight: 400;
	}
	input:focus {
		border: 1px solid #4285f4;
		box-shadow: none;
	}
	input {
		border-radius: 8px;
		height: 54px;
		border: 1px solid #30303026;
		padding-bottom: 0px;
		font-size: 12px;
		font-weight: 500;
		letter-spacing: -0.025em;
		padding-top: 15px;
	}
	input::placeholder {
		color: $dark_gray;
		font-size: 13px;
		letter-spacing: -0.02px;
		position: absolute;
		bottom: 6px;
	}
	input:focus::placeholder {
		color: #303030;
	}

	input:focus + label {
		font-weight: 400;
		color: $light_gray;
	}
}

.upload_images {
	.preview_images {
		display: grid;
		&.single {
			grid-template-columns: repeat(1, 1fr);
		}
		&.many {
			grid-template-columns: repeat(2, 1fr);
			gap: 30px;
		}
		img {
			height: auto;
			width: 100%;
		}
		div {
			button {
				right: 5px;
				top: 5px;
				height: 32px;
				width: 32px;
				display: flex;
				padding: 0px;
				align-items: center;
				margin: auto;
				justify-content: center;
			}
		}
	}
	.upload_image {
		&.is_drag_accept {
			border-color: green !important;
		}
		&.is_drag_reject {
			border-color: red !important;
		}
	}
}

.select_input_group {
	gap: 25px;
	.select_input {
		max-width: 100%;
		justify-content: space-between;
		align-items: center;
		div {
			gap: 20px;
			width: 85%;
			display: flex;
			flex-direction: row;
			input,
			select {
				height: 40px;
				max-height: 40px;
				width: 100%;
			}
		}

		button {
			width: 50px;
			justify-content: center;
			height: 35px;
		}
	}
	@media (max-width: $bp-lg-phone) {
		justify-content: space-between;
		.select_input {
			max-width: 100%;
			div {
				gap: 15px;
				width: 100%;
				display: flex;
				flex-direction: column;
				input,
				select {
					height: 40px;
					max-height: 40px;
					width: 100%;
				}
			}
		}
	}
}

.form_element {
	position: relative;
	&.search_select .select__control {
		border: 1px solid #a7a8b0;
		padding: 5px 10px;
		.select__input-container {
			.select__value-container {
				padding: 0px;
			}
			height: 30px;
			padding: 0px;
			input {
				height: 30px;
			}
		}
		.select__indicator.select__clear-indicator {
			display: none;
		}
		.ceremony_indicator_group {
			display: flex;
			align-items: center;
			gap: 5px;
			position: relative;
			z-index: 1000;
			margin-left: 40px;

			.select__indicator-separator {
				order: 2;
			}
			.select__dropdown-indicator {
				order: 3;
			}
		}
	}
	label {
		font-style: normal;
		font-weight: 500;
		font-size: 20px;
		line-height: 20px;
		color: #545565;
		@media (max-width: $bp-lap) {
			font-size: 14px;
			line-height: 24px;
		}
	}
	.form-control:focus {
		box-shadow: 0 0 0 0.1rem rgba(203, 48, 80, 0.25);
	}
	&.select_date_input_view {
		width: 100%;
		// min-width: 100%;
		.select_date_input {
			position: relative;
			button.btn {
				position: absolute;
				right: 15px;
				max-width: fit-content;
			}
		}
	}
	.icon {
		position: absolute;
		right: 15px;
		top: 55px;
	}
	input,
	select {
		height: 50px;
		background: #ffffff;
		border: 1px solid #a7a8b0;
		text-wrap: nowrap;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	input::placeholder {
		color: #a2a2a4;
		font-family: Poppins;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}
	textarea.form-control {
		background: #ffffff;
		border: 1px solid #a7a8b0;
		border-radius: 5px;
		height: 168px;
	}
	&.input-more {
		margin-bottom: 16px;
		width: 100%;
		display: flex;
		flex-direction: column;
		.input-list-group {
			.input-list {
				margin-bottom: 16px;
				gap: 10px;
				display: flex;
				flex-direction: row;
				width: 100%;
				align-items: center;
				input {
					width: 100%;
				}
				button {
					background-color: transparent;
					border: 0px;
					img {
						height: 30px;
						width: 30px;
					}
				}
			}
		}
	}
	&.password_input {
		position: relative;
		button {
			position: absolute;
			right: 0;
			background: transparent;
			border: 0;
			width: 50px;
			display: flex;
			align-items: center;
			justify-content: center;
			bottom: 12px;
			height: 20px;
		}
	}

	&.checkbox {
		input.form-control {
			background: #cb3050;
			border: 0.5px solid rgba(84, 85, 101, 0.4);
			border-radius: 5px;
			height: 22px;
			width: 22px;
			accent-color: #cb3050;
		}
	}
	@media (max-width: $bp-tab) {
		.icon {
			position: absolute;
			right: 15px;
			top: 40px;
		}
	}
}

.checklist_item_modal {
	display: flex;
	flex-direction: column;
	gap: 32px;

	.label {
		font-style: normal;
		font-weight: 600;
		font-size: 20px;
		line-height: 30px;
		color: #545565;
		margin-bottom: 9px;

		@media (max-width: $bp-lap) {
			font-size: 16px;
			line-height: 24px;
		}
	}

	@media (max-width: $bp-phone) {
		.react-datepicker__input-container {
			.d-flex {
				flex-direction: column;
				gap: 15px;
				align-items: flex-start !important;
			}
		}
	}

	.select_date {
		button.select_data_button {
			background: #ffffff;
			border: 1px solid #a7a8b0;
			border-radius: 5px;
		}
		.clear_date {
			background-color: #545565;
			color: white;
			display: flex;
			align-items: center;
			span {
				border: 1px solid white;
				border-radius: 20px;
				height: 20px;
				width: 20px;
				align-items: center;
				justify-content: center;
				display: inherit;
				transform: rotate(45deg);
			}
		}
	}
}

.input_dropdown {
	.input {
		position: relative;
		font-size: 12px;
		font-weight: 500;
	}

	.form-control:focus {
		box-shadow: none;
	}

	label {
		position: absolute;
		font-size: 10px;
		color: $slate_gray;
		left: 14px;
		top: 3px;
		font-weight: 600;
	}

	input:focus {
		border: 1px solid #303030;
		box-shadow: none;
	}

	input {
		border-radius: 8px;
		height: 54px;
		border: 1px solid #30303026;
		padding-bottom: 0px;
		font-size: 1rem;
		padding-top: 15px;
		font-size: 12px;
		font-weight: 500;
	}
	input::placeholder {
		color: #d9d9d9;
		font-weight: 500;
		font-size: 12px;
		letter-spacing: -0.02px;
		position: absolute;
		bottom: 6px;
	}

	input:focus::placeholder {
		color: #303030;
	}

	input:focus + label {
		font-weight: 400;
		color: $light_gray;
	}
	ul {
		box-shadow: 0px 4.5px 15.75px 1.13px #3030301a;
		border: 1px solid #30303026;
		background-color: $white;
		width: 400px;
		padding: 8px;
		gap: 8px;
		border-radius: 8px;
		position: absolute;
		z-index: 20;
		@media (max-width: 560px) {
			width: 100%;
		}
		top: 60px;
		li {
			list-style: none;
			width: 100%;
			height: 34px;
			padding: 8px;
			gap: 10px;
			border-radius: 8px;
			font-family: "Inter", sans-serif;
			font-size: 0.875rem;
			font-weight: 500;
			background-color: $white;
			cursor: pointer;
			line-height: 17.5px;
			letter-spacing: 0.01em;
			&:hover {
				background-color: #fbfbfb;
				color: #e72e50;
			}
			&.active {
				background-color: #fbfbfb;
				color: #e72e50;
			}
		}
	}
}

@media (max-width: 560px) {
	.form_element.input2 input::placeholder {
		font-size: 12px;
	}
}
