@import "./variables.scss";

// ui banner
.new_ui_banner {
	height: 40px;
	background-color: $primary;
	color: white;
	width: 100%;
	display: flex;
	font-size: 16px;
	align-items: center;
	justify-content: center;
	font-family: "Poppins", sans-serif;
	svg {
		fill: white;
		margin: 0px 10px;
	}
	a {
		font-weight: bold;
		color: white;
		margin: 0px 5px;
		text-decoration: underline;
		&:hover {
			color: #fceded;
		}
	}
	b {
		font-weight: 600;
	}

	button {
		border: 1px solid white;
		background-color: transparent;
		color: white;
		border-radius: 10px;
		font-size: 13px;
		padding: 2px 10px;
		&:hover {
			background-color: white;
			color: $primary;
		}
	}

	text-wrap: nowrap;
	white-space: nowrap;
	@media (max-width: 1024px) {
		font-size: 13px;
		button {
			font-size: 13px;
		}
	}

	@media (max-width: 768px) {
		font-size: 14px;
		span {
			display: none;
		}
		button {
			font-size: 14px;
		}
	}

	@media (max-width: 560px) {
		font-size: 11px;
		text-wrap: wrap;
		span {
			display: none;
		}

		button {
			font-size: 10px;
		}

		svg {
			min-width: 12px;
			min-height: 12px;
			margin: 0px 5px;
		}
	}

	@media (max-width: 320px) {
		&.new_dashboard {
			height: unset;
			padding: 5px 0px;
		}
	}
}
