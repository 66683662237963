@import "./variables.scss";

form {
	.duplicate_event {
		width: 150px;
	}
}

.events {
	.events_loader {
		width: 75%;
	}
}
.search_user_events {
	@media (max-width: 768px) {
		width: 100%;
		div.event_search {
			width: 100%;
			input.search_event_input {
				width: 100%;
				max-width: 100%;
			}
		}
	}
	.event_search {
		width: 300px;
		input.search_event_input {
			border: 1px solid #545565;
			border-radius: 5px;
			width: 300px;
			height: 46px;
			max-width: 300px;
		}
		button {
			right: 10px;
			bottom: 11px;
		}
	}
}

button.add_new_events {
	min-width: 170px;
}

.paginate_event {
	select {
		width: 60px;
		height: 40px;
	}
}
.event_plan_details {
	display: flex;
	flex-direction: column;
	gap: 5px;
	.current_event_plan {
		border-radius: 5px;
		background: #c0c6a6;
		color: #000;
		font-size: 12px;
		font-weight: 600;
		padding: 5px 15px;
		text-transform: uppercase;
	}
}

.events_loader {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 10px;
	svg {
		min-height: 300px;
	}
	@media (max-width: 500px) {
		display: flex;
		flex-direction: column;
		gap: 40px;
	}
}

.upgrade_account {
	.btn_link {
		color: $primary;
		font-weight: bold;
	}
}

.profile.user_profile {
	.events_view {
		grid-template-columns: repeat(4, 300px);
	}
}

.profile {
	font-family: "Poppins", sans-serif;
	font-style: normal;
	.events_view {
		display: grid;
		grid-template-columns: repeat(3, 300px);
		gap: 20px;
		a.event_card:hover {
			text-decoration: none;
		}
	}
	.top_view {
		margin-bottom: 40px;
		h4.title {
			font-weight: 600;
		}
	}
	.event_card {
		&.disable_event {
			pointer-events: none;
			cursor: not-allowed;
			.disabled_event_label {
				position: absolute;
				top: 0;
				z-index: 100;
				background-color: #c0c6a6;
				font-weight: bold;
				padding: 10px;
				font-size: 16px;
			}
		}
		.profile_banner {
			height: 150px;
			width: 100%;
			border-top-left-radius: 5px;
			border-top-right-radius: 5px;
		}
		.dropdown {
			button:hover {
				color: $primary;
			}
		}
	}

	.event_card {
		width: 300px;
		img {
			width: 300px;
			height: 150px;
			object-fit: cover;
			border-radius: 5px 5px 0px 0px;
		}
		.content {
			padding: 15px 0px 18px 18px;
			background-color: white;
			border-bottom-left-radius: 5px;
			border-bottom-right-radius: 5px;

			button#duplicate_event_button {
				width: 50px;
			}

			.dropdown {
				position: absolute;
				right: 30px;
				background-color: white;
				box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.15);
				border-radius: 5px;
				bottom: 30px;
				li:hover {
					color: red;
				}
			}

			h6 {
				color: #545565;
				font-size: 20px;
				font-weight: 600;
				line-height: normal;
				text-wrap: nowrap;
				overflow: auto;
				white-space: nowrap;
				text-overflow: ellipsis;
				max-width: 220px;
			}
			div {
				p {
					color: #545565;
					font-size: 18px;
					font-weight: 500;
					line-height: normal;
				}
				.countdown {
					border-radius: 5px;
					color: white;
					font-size: 12px;
					font-style: normal;
					font-weight: 500;
					line-height: normal;
					padding: 2px 10px;
				}
			}
		}
	}
}
@media (max-width: $bp-dsk) {
	section.profile {
		min-height: 500px;
		.events_view {
			display: grid;
			grid-template-columns: repeat(3, 300px);
			gap: 30px;
		}
	}
}

@media (max-width: $bp-tab) {
	section.profile {
		.events_view {
			display: grid;
			grid-template-columns: repeat(2, 300px);
			gap: 20px;
		}
	}
}

@media (max-width: $bp-phone) {
	section.profile {
		.top_view {
			flex-direction: column;
			align-items: flex-start !important;
			gap: 15px;
			h4 {
				font-size: 18px;
			}
		}
		.events_view {
			display: flex;
			flex-direction: column;
			gap: 30px;
		}

		.event_card {
			max-width: 300px;
			img {
				max-width: 300px;
			}
			div.content {
				h6 {
					font-size: 16px;
				}
				p,
				span {
					font-size: 14px;
				}
			}
		}
	}
}

@media (max-width: 380px) {
	section.profile {
		.event_card {
			max-width: 100%;
			width: 100%;
			img {
				width: 100%;
				max-width: 100%;
			}
		}
	}
}

@media (max-width: $bp-mob) {
	section.profile {
		.event_card {
			max-width: 100%;
			img {
				max-width: 100%;
			}
		}
	}
}
