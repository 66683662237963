@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400,500&family=Raleway:wght@500,600&family=Manrope:wght@600&display=swap");
@import "./variables.scss";

.onboarding_layout main .content.auth {
	justify-content: space-between;

	.button_group {
		display: flex;
		width: 50%;
		justify-content: space-between;
		align-items: center;
		@media (min-width: 1500px) {
			width: 35%;
		}
		.underline_text {
			padding: 0;
			text-align: center;
			font-size: 9px;
			color: #9395aa;
			span {
				font-size: 13px;
				font-weight: 400;
				line-height: 18.2px;
				letter-spacing: -0.025em;
				text-align: right;
				color: #031729;
				margin-bottom: 2px;
			}
		}
		button {
			height: 42px;
			border-radius: 8px;
			font-size: 0.8125rem;
			line-height: 18px;
			font-family: "Inter", sans-serif;
			font-weight: 400;
			width: 105px;
			&.prev_btn {
				background-color: transparent;
				border: 1px solid #5455660d;
				color: #606060 !important;
				&.stage_1 {
					border: none;
				}
			}
			&.next_btn {
				background-color: $base_red;
				color: white;
				border: none;
				display: flex;
				font-weight: 600;
				align-items: center;
				padding: 0px 15px;
				justify-content: center;
				&.loading {
					width: 120px;
				}
			}
		}
		.skip_btn {
			border: none;
			background-color: transparent;
			color: $base_red;
		}
	}

	form.invite_collaborator_form {
		margin-bottom: 0;
		.collaborators_section {
			.add_collaborators {
				display: flex;
				flex-direction: column;
				gap: 10px;
				.top_row {
					display: flex;
					align-items: center;
					justify-content: space-between;
					gap: 20px;
					.button_group {
						display: flex;
						align-items: center;
						gap: 10px;
						button {
							padding: 6px;
							border-radius: 4px;
							border: 0;
						}
						button:last-child {
							background-color: #031729;
							color: #fff;
							svg path {
								stroke: white;
							}
							&:hover {
								color: #fff;
							}
						}
						.permission button {
							background-color: #fbfbfb;
							color: #4285f4;
						}
						.invite_btn {
							position: relative;
							span {
								position: absolute;
								left: 0;
							}
							&.loading {
								min-width: 120px;
							}
						}
					}

					.custom_input {
						display: flex;
						align-items: center;
						padding: 4px 6px;
						border-radius: 6px;
						width: 100%;
						height: 40px;
						input {
							text-wrap: nowrap;
							white-space: nowrap;
							font-family: "Inter", sans-serif;
							font-size: 12px;
							font-weight: 500;
							line-height: 18px;
							letter-spacing: -0.025em;
							color: #000;
							width: 100%;
							background-color: transparent;
							border: 0;
							height: 40px;
							padding-left: 3px;
							padding-right: 3px;
							margin-bottom: 0;
							white-space: nowrap;
							text-overflow: ellipsis;
						}
						svg {
							height: 13px;
							width: 13px;
						}
						&:focus-within {
							border: 1px solid #dddfe380;
						}
					}
				}
				input {
					margin-bottom: 0;
				}
				.input_group {
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: space-between;
					gap: 10px;
					.form_element {
						width: 100%;
						input.form-control {
							width: 95%;
						}
					}

					.add_role {
						width: 180px;
					}
				}
				.dropdown {
					button.dropdown_toggle {
						padding: 6px 10px !important;
						border-radius: 4px;
						display: flex;
						justify-content: space-between;
						color: #4285f4;
						font-size: 12px;
						font-weight: 500;
						background-color: #fbfbfb;
						min-height: 24px;
						text-wrap: nowrap;
						white-space: nowrap;
						svg {
							width: 12px;
							height: 12px;
							stroke: #4285f4 !important;
						}
						&:hover {
							background-color: #fbfbfb !important;
							color: #4285f4 !important;
							svg {
								color: unset;
							}
						}
					}
					.dropdown-menu.show {
						button {
							background-color: #fff;
							justify-content: flex-start;
							padding: 5px 10px !important;
							height: unset;
							&:hover {
								background-color: #fbfbfb;
							}
						}
					}
				}
				img {
					height: 280px;
					width: 280px;
					display: flex;
					align-items: center;
					justify-content: center;
					margin: auto;
				}
				font-family: "Inter", sans-serif;
				.text_content {
					border: 1.11px solid #5455660d;
					padding: 16px 8px 0px;
					border-radius: 8px;
					margin-bottom: 12px;
					h4 {
						font-size: 24px;
						font-weight: 600;
						color: #031729;
						line-height: 36px;
					}
					.text_1 {
						margin-bottom: 20px;
						color: #031729;
					}
					p {
						font-weight: 400;
						color: $slate_gray;
						font-size: 12px;
						span {
							color: $primary;
						}
					}
					letter-spacing: -0.02em;
					text-align: center;
				}
			}
		}
	}

	.collaborators_list {
		display: flex;
		flex-direction: column;
		width: 100%;
		gap: 20px;
		.collaborator {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			.collaborator_item {
				display: flex;
				align-items: center;
				justify-content: space-between;
				gap: 15px;
				.image_wrapper {
					background-color: #bfd9fc;
					border-radius: 4px;
					height: 32px;
					width: 32px;
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 12px;
					color: #000;
				}
				.text_list {
					display: flex;
					flex-direction: column;
					gap: 5px;
					h4 {
						font-size: 12px;
						font-weight: 500;
						line-height: 18px;
						letter-spacing: -0.025em;
						margin-bottom: 0;
					}
					p {
						font-size: 10px;
						font-weight: 500;
						line-height: 10px;
						margin-bottom: 0;
					}
				}
			}
			span {
				color: #4285f4;
				font-family: "Inter", sans-serif;
				font-size: 10px;
				font-weight: 500;
				line-height: 10px;
				text-align: left;
				background-color: #fbfbfb;
				height: 24px;
				padding: 6px 4px;
				border-radius: 4px;
			}
		}
	}
}
.onboarding_layout main .content.auth div.onboarding_stage {
	width: 50%;
	gap: 40px;
	height: unset;
	max-width: 350px;
	min-width: 400px;
	@media (min-width: 1500px) {
		width: 35%;
	}

	.page_title {
		h4 {
			text-align: center;
		}
		p {
			text-align: center;
			font-family: "Inter", sans-serif;
			font-size: 14px;
			font-weight: 500;
			line-height: 19.6px;
			letter-spacing: -0.025em;
			text-align: center;
			color: #9395aa;
			margin-bottom: 0;
		}
	}

	.onboarding_stepper {
		display: flex;
		align-items: center;
		margin: 0;
		border: 1px solid #5455660d;
		padding: 0 16px;
		position: relative;
		justify-content: space-between;
		width: 100%;
		border-radius: 6px;
		.hr_wrapper {
			position: absolute;
			width: 100%;
			left: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			hr {
				border: 0.5px solid #e9e9e9;
				width: 50%;
			}
		}
		li {
			padding: 12px 12px 14px;
			list-style-type: none;
			display: flex;
			flex-direction: column;
			align-items: center;
			background-color: white;
			position: relative;
			z-index: 1;
			justify-content: center;
			gap: 5px;

			&:nth-child(2) {
				padding: 12px 20px 15px;
			}
			p {
				margin: 0;
				padding: 0;
				color: #9395aa;
				font-size: 10px;
				line-height: 15px;
				letter-spacing: -0.01px;
				font-family: "Inter", sans-serif;
				font-weight: 500;
			}
			&.progress p {
				color: $base_red;
			}
			div {
				height: 10px;
				width: 10px;
				min-width: 10px;
				min-height: 10px;
				background-color: $gray_50;
				border-radius: 50%;
				display: flex;
				align-items: center;
				justify-content: center;
				span {
					height: 6px;
					width: 6px;
					&.active {
						background-color: $base_red;
					}
					&.done {
						svg {
							margin-top: -20px;
							margin-left: -2px;
						}
					}
					background-color: $white;
					border-radius: 50%;
				}
			}
			&.active {
				p {
					color: $base_red;
				}
			}
		}
	}

	h4 {
		font-weight: 500;
		font-family: "Inter", sans-serif;
		font-size: 1.25rem;
		letter-spacing: -0.025em;
		color: #031729;
		margin-top: 0px;
	}
	form {
		width: 100%;
		gap: 20px;

		.input_list .form_element.input input {
			color: #000;
			font-size: 0.8125rem;
		}
		.select_date_input {
			button.calender_icon_btn {
				display: none;
			}
			input {
				color: #000;
				font-size: 0.8125rem;
			}
		}

		.form_row {
			display: flex;
			flex-direction: column;
			.section_title {
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-bottom: 10px;
				h6 {
					color: $base_red;
					font-weight: 600;
					margin: 0;
					padding: 0;
					font-size: 0.8125rem;
					font-family: "Inter", sans-serif;
				}
				div {
					display: flex;
					align-items: center;
					gap: 2px;
				}
				button {
					height: 32px;
					width: fit-content;
					margin: unset;
					padding: 7px 8px !important;
					background-color: #fbfbfb;
					color: #031729;
					font-family: "Inter", sans-serif;
					font-size: 0.75rem;
					font-weight: 500;
					line-height: 18px;
					letter-spacing: -0.025em;
					text-align: center;

					svg {
						stroke: #031729;
						height: 12px;
						width: 12px;
					}
				}
			}
			input {
				margin-bottom: 10px;
				color: #000;
			}
			.input_group {
				display: flex;
				gap: 10px;
				.location_input {
					min-width: 180px;
				}
				.select_input {
					min-width: 70px;
				}
				.select_date_input_view {
					min-width: 110px;
				}
			}
			.ceremonies {
				display: flex;
				flex-direction: column;
				gap: 10px;
			}
			div.button_action_group {
				display: flex;
				flex-direction: row;
				gap: 6px;
				.dropdown_toggle {
					span {
						display: none;
					}
				}
				.dropdown-menu.show {
					display: flex;
					flex-direction: column;
				}
			}
		}

		.input_list_group {
			display: flex;
			align-items: flex-start;
			flex-direction: row;
			.input_list {
				display: flex;
				flex-direction: column;
				width: 100%;
				gap: 10px;

				.upload_group {
					display: flex;
					align-items: center;
				}
			}
			.business_name {
				display: flex;
				position: relative;
			}
			.upload_event_banner {
				width: 32px;
				height: 32px;
				border-radius: 50%;
				background-color: #fafafc;
				padding: 10px;
				margin-left: 10px;
				display: flex;
				position: absolute;
				right: 10px;
				bottom: 10px;
				cursor: pointer;
				align-items: center;
				justify-content: center;
				min-height: 32px;
				min-width: 32px;
				z-index: 1;
				input {
					position: absolute;
					width: 100%;
					height: 100%;
					z-index: 100;
					cursor: pointer;
					&::-webkit-file-upload-button {
						visibility: hidden;
					}
				}
				svg {
					min-height: 12px;
					min-width: 12px;
					position: relative;
					z-index: 1;
				}
				img {
					height: 32px;
					width: 32px;
					border-radius: 100%;
					min-height: 32px;
					min-width: 32px;
					object-fit: cover;
					object-position: top center;
				}
			}
		}

		.select_date_input {
			width: 100%;
		}

		.other_sm_input_group {
			display: flex;
			align-items: center;
			gap: 20px;
			.platform {
				width: 40%;
			}
			.platform_link {
				width: 60%;
			}
		}

		.other_media_platforms {
			margin-top: -20px;
			display: flex;
			flex-direction: column;
			gap: 10px;
		}

		.control_button_group {
			display: flex;
			flex-direction: row;
			gap: 20px;
			.add_ceremony {
				width: 70%;
				@media (max-width: 560px) {
					width: 65%;
				}
			}

			.remove_ceremony {
				width: 30%;
				@media (max-width: 560px) {
					width: 35%;
				}
				svg {
					stroke: $slate_gray;
				}
			}
		}

		&.form_first_stage .add_ceremony {
			width: 100%;
		}
		&.form_first_stage .remove_ceremony {
			display: none;
		}

		button {
			background-color: $dark_50;
			color: $dark;
			display: flex;
			height: 42px;
			align-items: center;
			margin: auto;
			width: 100%;
			border-radius: 6px;
			gap: 5px;
			justify-content: center;
			font-size: 0.75rem;
			line-height: 18px;
			font-weight: 500;
			font-family: "Inter", sans-serif;
			color: #717984;
			svg {
				height: 20px;
				width: 20px;
				stroke: $slate_gray;
			}
		}
		button:hover {
			color: #000;
			svg {
				stroke: #000;
			}
		}
	}

	.info_card {
		display: flex;
		flex-direction: column;
		gap: 20px;
		font-family: "Inter", sans-serif;
		width: 100%;
		div {
			background-color: #fbfbfb;
			border: 1px solid #5455660d;
			padding: 20px 20px 14px;
			display: flex;
			border-radius: 8px;
			align-items: center;
			gap: 15px;
			span {
				padding: 10px;
				border: 1px solid #5455660d;
				border-radius: 4px;
			}
			p {
				padding: 0;
				margin: 0;
				font-size: 0.875rem;
				line-height: 21px;
				font-weight: 400;
				color: $dark;
			}
			div {
				border: none;
				flex-direction: column;
				padding: 0;
				gap: 0px;
				.click_next {
					font-size: 13px;
					font-weight: 400;
					line-height: 18.2px;
					letter-spacing: -0.025em;
					text-align: left;
					color: #9395aa;
					margin-bottom: 10px;
				}
				p {
					font-size: 12px;
					font-weight: 400;
					line-height: 18px;
					letter-spacing: -0.025em;
					text-align: left;
					width: 100%;
				}
			}
		}
	}
}

@media (max-width: 1300px) {
	.onboarding_layout main .content.auth div.onboarding_stage {
		width: 70%;
	}
	.onboarding_layout main .content.auth .button_group {
		width: 70%;
	}
}

@media (max-width: 768px) {
	.onboarding_layout main .content.auth div.onboarding_stage {
		width: 100%;
		min-width: 100%;
	}

	.onboarding_layout main .content.auth form.invite_collaborator_form .collaborators_section .add_collaborators {
		margin: 0px;
	}

	.onboarding_layout main .content.auth div.onboarding_stage form {
		width: 100%;
	}

	.onboarding_layout main .content.auth .button_group {
		width: 100%;
	}
	.onboarding_layout.onboarding_page_stage aside .aside_content {
		justify-content: center;
	}
	.onboarding_layout.onboarding_page_stage aside div.aside_content p.title {
		position: relative;
		width: 90%;
		left: unset;
		text-align: center;
		bottom: unset;
	}
	.onboarding_layout main .content.auth {
		padding: 40px 15px;
	}
	.onboarding_layout main .content.auth div.onboarding_stage {
		gap: 25px;
	}
	.onboarding_layout main .content.auth .button_group button {
		font-size: 13px;
		width: unset;
		padding: 5px 15px;
		height: 36px;

		&.underline_text {
			padding: 0;
			text-align: center;
			font-size: 9px;
			color: #9395aa;
			span {
				//styleName: inter/sm/regular;
				font-size: 13px;
				font-weight: 400;
				line-height: 18.2px;
				letter-spacing: -0.025em;
				text-align: right;
				color: #031729;
			}
		}
	}

	div.stage_4 {
		form {
			width: 100%;
			.ceremony_section .input_group {
				flex-direction: column;
				.location_input {
					width: 100%;
				}
				.form_element.input.input_dropdown {
					width: 50%;
				}
			}
		}
		.info_card div {
			width: 100%;
		}
	}

	div.stage_5 {
		.content_view {
			.title_row .button_group {
				gap: 10px !important;
			}
		}
		.event_content {
			padding: 16px;
			padding-bottom: 50px;
			height: fit-content;
		}
	}
	.onboarding_layout main .content.auth div.onboarding_stage form .form_row .input_group .location_input {
		min-width: 100px;
	}
}

@media (max-width: 560px) {
	div.stage_5 {
		.content_view {
			.event_view {
				flex-direction: column;
			}
			.title_row .button_group {
				gap: 10px !important;
			}
		}
		.event_content {
			padding: 16px;
			padding-bottom: 50px;
			height: fit-content;
		}
	}
}
