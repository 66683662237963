@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400,500&family=Raleway:wght@500,600&display=swap");
@import "./variables.scss";
@import "../../node_modules/bootstrap/scss/bootstrap";

@font-face {
	font-family: "Helvetica_Neue";
	font-weight: 400;
	src: local("Helvetica_Neue"), url(../assets/fonts/HelveticaNeueLight.ttf) format("ttf");
}

@font-face {
	font-family: "Helvetica_Neue";
	font-weight: 500;
	font-style: italic;
	src: local("Helvetica_Neue"), url(../assets/fonts/HelveticaNeue-MediumCondObl.otf) format("otf");
}

@font-face {
	font-family: "Helvetica_Neue";
	font-weight: 500;
	src: local("Helvetica_Neue"), url(../assets/fonts/HelveticaNeueMedium.ttf) format("ttf");
}

@font-face {
	font-family: "Helvetica_Neue";
	font-weight: 700;
	src: local("Helvetica_Neue"), url(../assets/fonts/HelveticaNeueBold.ttf) format("ttf");
}

@font-face {
	font-family: "SF_PRO";
	font-weight: 500;
	src: local("SF_PRO"), url(../assets/fonts/SFPRODISPLAYMEDIUM.OTF) format("otf");
}

@font-face {
	font-family: "SF_PRO";
	font-weight: 400;
	src: local("SF_PRO"), url(../assets/fonts/SFPRODISPLAYREGULAR.OTF) format("otf");
}

.onboarding_layout {
	display: flex;
	align-items: center;
	height: 100vh;
	width: 100%;
	aside {
		width: 400px;
		height: 100%;
		background-position: top;
		background-repeat: no-repeat;
		background-size: cover;
		background-image: url("../assets/img/sidebar_bg.webp");
		position: relative;
		@media (max-width: 1280px) {
			width: 320px;
			max-width: 320px;
		}
		.aside_shadow {
			opacity: 0.6;
			background-color: #000;
			position: absolute;
			width: 100%;
			z-index: 1;
			height: 100%;
		}
		.aside_content {
			height: 100%;
			width: 100%;
			position: relative;
			z-index: 3;
			color: white;
			font-family: "Helvetica_Neue", sans-serif;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			padding: 25px;
			.logo_wrapper {
				position: absolute;
				padding: 10px;
				border-radius: 10px;
				left: 20px;
				top: 20px;
				img.logo {
					height: 32px;
					width: 32px;
				}
			}
			.question_group {
				display: flex;
				flex-direction: column;
				position: relative;
				height: 150px;
				width: 100%;
				margin-top: -70px;
			}
			.question_group .question_bar:nth-child(1) {
				position: absolute;
				right: 14%;
				top: 0px;
			}
			.question_group .question_bar:nth-child(2) {
				position: absolute;
				left: 0%;
				top: 85px;
			}
			.question_group .question_bar:nth-child(3) {
				position: absolute;
				right: 0%;
				top: 150px;
			}
			p.title {
				width: 80%;
				color: white;
				opacity: 1;
				position: absolute;
				left: 25px;
				bottom: 60px;
				font-family: "Helvetica Neue", sans-serif;
				font-size: 2rem;
				font-weight: 400;
				line-height: 40px;
				letter-spacing: -0.05em;
				text-align: left;
				span {
					color: $base_red;
				}
			}
		}
	}

	main {
		width: calc(100% - 400px);
		height: 100%;
		padding: 30px 15px;
		@media (max-width: 1280px) {
			width: calc(100% - 320px);
			max-width: calc(100% - 320px);
		}
		div.content {
			border: 1px solid #5455660d;
			height: 100%;
			border-radius: 16px;
			display: flex;
			align-items: center;
			margin: auto;
			justify-content: center;
			form {
				display: flex;
				gap: 30px;
				flex-direction: column;
			}
		}
	}
}

@media (max-width: 768px) {
	.onboarding_layout {
		flex-direction: column;
		height: 100%;
		width: 100%;
	}

	.onboarding_layout aside {
		width: 100%;
		height: 400px;
		min-width: 100%;
	}

	.onboarding_layout aside .aside_content .question_group {
		margin-top: 0px;
	}

	.onboarding_layout aside div.aside_content p.title {
		bottom: 10px;
		line-height: 36px;
		position: absolute;
		width: 85%;
		left: 25px;
		text-align: left;
		font-family: "Helvetica Neue", sans-serif;
		font-size: 2rem;
		font-weight: 400;
		line-height: 40px;
		letter-spacing: -0.05em;
		text-align: left;
	}

	.onboarding_layout main {
		width: 100%;
		margin-bottom: 100px;
		min-width: 100%;
	}
	.onboarding_layout main div.content {
		h4 {
			font-size: 1rem;
		}
		p {
			font-size: 0.75rem;
		}
		form {
			width: 90%;
			margin: 20px 0px;
		}
	}

	.onboarding_layout aside div.aside_content {
		justify-content: flex-start;
	}

	div.onboarding_layout.auth_page {
		aside {
			width: 100%;
			min-width: 100%;
		}
		main {
			width: 100%;
			min-width: 100%;
		}
	}
}

*::-webkit-scrollbar {
	display: none;
}

@media (max-width: 560px) {
	.onboarding_layout aside div.aside_content p.title {
		font-size: 1.5rem;
		line-height: 30px;
		
	}
}
