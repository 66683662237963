@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400,500&family=Raleway:wght@500,600&family=Manrope:wght@600&display=swap");
@import "./variables.scss";

.pricing_page main {
	padding: 15px;
	border-radius: 6px;
}
.onboarding_layout main div.content.pricing {
	display: flex;
	flex-direction: column;
	gap: 20px;
	font-family: "Inter", sans-serif;
	.pricing_wrapper {
		width: 75%;
		display: flex;
		flex-direction: column;
		gap: 20px;
		max-width: 700px;
		@media (max-width: 1280px) {
			width: 80%;
		}
		&.planner {
			width: 85%;
			max-width: 1000px;
			@media (max-width: 1280px) {
				width: 90%;
			}
		}
		.page_title {
			h2 {
				text-align: center;
				font-family: "Inter", sans-serif;
				font-size: 20px;
				font-weight: 500;
				line-height: 20px;
				letter-spacing: -0.025em;
				text-align: center;
				color: #031729;
			}
			p {
				font-size: 14px;
				font-weight: 500;
				line-height: 19.6px;
				letter-spacing: -0.025em;
				text-align: center;
				color: #9395aa;
			}
		}
		.row_1 {
			display: flex;
			background-color: #fafafc;
			border-radius: 6px;
			padding: 12px;
			width: 100%;
			align-items: center;
			justify-content: space-between;
			.all_plans {
				width: 100%;
			}
			button {
				background: #031729;
				color: white;
				height: 32px;
				border: 1px solid #5455660d;
				border-radius: 6px;
				padding: 0px 16px;
				font-family: Inter;
				font-size: 12px;
				font-weight: 500;
				line-height: 18px;
				letter-spacing: -0.025em;
				text-align: left;
			}
			h3 {
				font-family: Inter;
				font-size: 14px;
				font-weight: 600;
				line-height: 19.6px;
				letter-spacing: -0.025em;
				color: #031729;
			}
			p {
				font-family: Inter;
				font-size: 12px;
				font-weight: 500;
				line-height: 18px;
				letter-spacing: -0.025em;
				/* text-align: center; */
				color: #606060;
				margin: 0;
				padding: 0;
			}
			.dropdown {
				margin-left: 10px;
				border: 1px solid #5455660d;
				background-color: white;
				display: flex;
				border-radius: 6px;
				align-items: center;
				button {
					border: 0;
					background-color: transparent;
					font-size: 12px;
					font-weight: 500;
					line-height: 18px;
					letter-spacing: -0.025em;
					text-align: left;
					color: #031729;
					height: 100%;
					display: flex;
					align-items: center;
					padding: 5px 8px !important;
					svg {
						height: 10px;
						width: 10px;
					}
				}
			}
			.dropdown-menu.show {
				background-color: white;
				border: 0.5px solid #dddfe380;
				box-shadow: 0px 4.5px 15.75px 1.13px #3030301a;
				.dropdown-item {
					border: none;
					color: #606060;
					padding: 8px;
					height: 34px;
					&:hover {
						background-color: #fbfbfb;
						border-radius: 6px;
						color: $base_red;
					}
				}
			}
		}
		.all_plans {
			width: 100%;
			.top_row {
				justify-content: flex-end;
				display: flex;
				align-items: center;
				margin-bottom: 25px;
				.right_side {
					display: flex;
					flex-direction: row;
					.period {
						display: flex;
						gap: 0px;
						background-color: #f7f7f7;
						padding: 4px;
						border-radius: 6px;
						height: 36px;
						display: flex;
						align-items: center;
						button {
							border: 0;
							background-color: transparent;
							font-family: Inter;
							font-size: 12px;
							font-weight: 500;
							line-height: 18px;
							letter-spacing: -0.025em;
							text-align: left;
							color: #606060;
							height: 100%;
							display: flex;
							align-items: center;
							padding: 4px 6px !important;
							&.active {
								box-shadow: 0px 1px 2px 0px #0000000d;
								background-color: #fff;
								border: 0;
								border-radius: 4px;
								padding: 0px 6px;
								color: #e72e50;
							}
						}
					}

					.dropdown {
						margin-left: 10px;
						border: 1px solid #5455660d;
						background-color: white;
						display: flex;
						border-radius: 6px;
						align-items: center;
						padding: 0px 5px;
						button {
							border: 0;
							background-color: transparent;
							font-size: 12px;
							font-weight: 500;
							line-height: 18px;
							letter-spacing: -0.025em;
							text-align: left;
							color: #031729;
							height: 100%;
							display: flex;
							align-items: center;
							padding: 4px 6px !important;
							svg {
								height: 10px;
								width: 10px;
							}
						}
					}
					.dropdown-menu.show {
						background-color: white;
						border: 0.5px solid #dddfe380;
						box-shadow: 0px 4.5px 15.75px 1.13px #3030301a;
						.dropdown-item {
							border: none;
							color: #606060;
							padding: 8px;
							height: 34px;
							&:hover {
								background-color: #fbfbfb;
								border-radius: 6px;
								color: $base_red;
							}
						}
					}
				}
				h4 {
					font-family: Inter;
					font-size: 16px;
					font-weight: 500;
					line-height: 22.4px;
					letter-spacing: -0.025em;
					text-align: center;
					color: #031729;
					margin-bottom: 0;
				}
			}
			.plans {
				display: flex;
				flex-direction: row;
				gap: 20px;
				justify-content: center;
				.plan_card {
					border: 1px solid #5455660d;
					padding: 12px 24px;
					border-radius: 6px;
					width: 100%;
					max-width: 280px;
					position: relative;
					&:hover {
						cursor: pointer;
					}
					.card_top {
						h6 {
							font-size: 13px;
							font-weight: 600;
							line-height: 18.2px;
							letter-spacing: -0.025em;
							color: #031729;
							background-color: #fff;
						}
						p {
							font-size: 10px;
							font-weight: 400;
							line-height: 10px;
							letter-spacing: -0.025em;
							color: #9395aa;
						}
					}
					h5 {
						font-family: "Inter", sans-serif;
						font-size: 22.52px;
						font-weight: 600;
						line-height: 24.77px;
						text-align: left;
						color: #031729;
						margin: 25px 0;
						span {
							font-family: Inter;
							font-size: 9.01px;
							font-weight: 500;
							line-height: 13.51px;
						}
					}
					.benefits {
						padding: 0;
						display: flex;
						flex-direction: column;
						gap: 15px;
						margin-top: 30px;
						li {
							list-style: none;
							display: flex;
							cursor: pointer;
							align-items: center;
							gap: 10px;
							flex-direction: row;
							font-size: 12px;
							font-weight: 500;
							line-height: 18px;
							letter-spacing: -0.025em;
							&.not_available_benefit {
								color: #d9d9d9;
							}
							svg {
								height: 14px;
								width: 14px;
							}
						}

						.benefit {
							padding: 0;
						}
					}

					button {
						width: 100%;
						margin-top: 30px;
						border: 0px;
						border-radius: 6px;
						padding: 0px 12px;
						height: 36px;
						font-size: 13px;
						font-weight: 600;
						line-height: 18.2px;
						letter-spacing: -0.025em;
						display: flex;
						align-items: center;
						justify-content: center;
						background-color: white;
						border: 1px solid #5455660d;
						color: #606060;
					}

					&.pro {
						button {
							background-color: white;
							border: 1px solid #5455660d;
							transition: background-color 0.5s ease-in-out;
							&:hover {
								background-color: #e72e50;
								color: #fff;
							}
						}
					}
					&.active {
						border: 1px solid $base_red;
						button {
							background-color: #e72e50;
							color: white;
							border: none;
						}
					}
					.note {
						position: absolute;
						bottom: -12px;
						background-color: #000;
						width: 100%;
						left: 0;
						color: white;
						text-align: center;
						padding: 5px 0px;
						font-family: "Inter", sans-serif;
						font-weight: 500;
						font-size: 14px;
						border-bottom-right-radius: 10px;
						border-bottom-left-radius: 10px;
					}
				}
			}
		}

		.button_group {
			display: flex;
			width: 50%;
			min-width: 400px;
			margin: auto;
			margin-top: 30px;
			justify-content: space-between;
			align-items: center;
			@media (min-width: 1500px) {
				width: 35%;
			}
			.underline_text {
				padding: 0;
				text-align: center;
				font-size: 9px;
				color: #9395aa;
				span {
					font-size: 13px;
					font-weight: 400;
					line-height: 18.2px;
					letter-spacing: -0.025em;
					text-align: right;
					color: #031729;
					margin-bottom: 2px;
				}
			}
			button {
				height: 36px;
				border-radius: 6px;
				font-size: 0.8125rem;
				line-height: 18px;
				font-family: "Inter", sans-serif;
				font-weight: 400;
				width: 100px;
				font-size: 13px;
				font-weight: 400;
				line-height: 18.2px;
				padding: 0px 12px;
				letter-spacing: -0.025em;
				text-align: center;
				display: flex;
				justify-content: center;
				align-items: center;

				&.prev_btn {
					background-color: transparent;
					border: 1px solid #5455660d;
					color: #606060 !important;
				}
				&.next_btn {
					background-color: $base_red;
					color: white;
					border: none;
					font-weight: 600;
					padding: 0px 15px;
					width: unset;
					&.loading {
						width: 120px;
					}
				}
			}
			.skip_btn {
				border: none;
				background-color: transparent;
				color: #031729;
				font-size: 13px;
				font-weight: 400;
				line-height: 18.2px;
				letter-spacing: -0.025em;
			}
		}
	}
}

@media (max-width: 768px) {
	.onboarding_layout main div.content.pricing {
		padding: 40px 0px;
	}

	.onboarding_layout main div.content.pricing .pricing_wrapper .button_group {
		min-width: 100%;
	}
}
