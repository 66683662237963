@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
@import "./variables.scss";
@import "./utilities.scss";
@import "../../node_modules/bootstrap/scss/bootstrap";

.planner_icon {
	.a {
		fill: #ffffff;
	}
	.b {
		fill: #cb3050;
	}
	.c {
		fill: #fff;
	}
	.d {
		fill: #fff;
	}
	.e {
		fill: #fff;
	}
}
body {
	font-family: "Poppins", sans-serif;
	color: #545566;
}

.bg-base {
	background-color: $base;
}

.cursor-pointer {
	cursor: pointer;
}

.w-full {
	width: 100%;
}

.vh-55 {
	height: 55vh;
}

button.button:focus,
button {
	outline: none;
	box-shadow: none;
}

.slider_wrap {
	display: flex;
	justify-content: center;
}
.bottom,
.side-nav {
	display: none;
}
.hide-nav-first,
.hide-nav-second {
	display: none;
}
img .white_checkbox {
	border-radius: 50%;
}
.input-btn-location {
	background-color: white;
	height: 60px;
	padding: 0.2rem 1rem;
	align-items: center;
	border: 1.5px solid $primary;
	border-radius: 15px;
	color: $primary;
	font-size: 16px;
	width: 70% !important;
}
.logo-img {
	width: 100%;
}

.react-datepicker-popper {
	z-index: 100;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
	background-color: #cb3050;
	color: white;
}

@media screen and (min-device-width: 729px) and (max-device-width: 990px) {
	.bottom {
		display: block;
	}
	.top {
		display: none;
	}
	.bottom .col-md-4 {
		margin-left: auto;
		margin-right: auto;
		display: block;
	}
}

@media screen and (min-device-width: 350px) and (max-device-width: 726px) {
	.rc-slider-mark span {
		font-size: 10px;
		width: 40%;
	}
	.rc-slider-mark {
		margin: 0 1em 0 1em;
	}
}

@media screen and (min-device-width: 727px) and (max-device-width: 800px) {
	.rc-slider-mark span {
		font-size: 12px;
		width: 30%;
	}
	.rc-slider-mark {
		margin: 0 1em 0 1em;
	}
}

@media screen and (max-width: 481px) {
	.question-misc {
		position: relative;
		top: 2.5px !important;
		display: inline-block;
		border-radius: 50%;
		height: 5px;
		width: 5px;
		font-weight: bold;
		margin-left: 1px;
		text-align: center;
		background-color: $primary;

		span {
			color: white !important;
			top: -5px !important;
			left: -1px !important;
			font-weight: bolder;
			font-size: 8em;
		}
	}
}

@media screen and (max-width: 727px) {
	.navbar {
		padding: 0 0 0 0 !important;
		height: 80px;
	}
	.navbar .container {
		padding: 0px 20px;
	}
	.logo-img {
		width: 75%;
	}
	.side-nav-link a {
		display: block !important;
	}
	.close-mobile-menu-top {
		margin-top: 1.5em;
	}
	.top,
	.close-mobile-menu a,
	.main-nav {
		display: none !important;
	}
	.bottom {
		display: block !important;
	}
	.footer-social {
		margin-top: 1rem;
	}
	.section-navbar {
		margin-bottom: 5rem !important;
	}
	.fluid-container div.side-nav {
		display: block;
		text-align: right;
	}
	.fluid-container .side-nav.close-mobile-menu {
		display: none;
	}

	.side-nav-link {
		flex-direction: column;
		display: flex;
	}

	.side-nav-btn {
		font-size: 16px;
		border-radius: 15px;
		/* padding: 1.2rem 1.5rem; */
		font-weight: 600;
		height: 35px;
		transition: all 1s;
	}

	.side-nav-link a:hover {
		cursor: pointer;
	}
	.side-nav-link a {
		color: $base;
		display: none;
		padding: 0 0 0px 0;
		font-weight: 500;
	}
}

.nav-links a {
	margin-bottom: -1em !important;
	display: block !important;
}
.navbar {
	.nav-icons {
		display: block;
	}
	.display-dp {
		display: inline !important;
	}
}

.w-40 {
	width: 40%;
}

.w-60 {
	width: 60%;
}

.navbar {
	width: 100%;
	max-height: 100px;
	background-color: transparent;

	.logo {
		img {
			max-width: 150px;
		}
	}

	@media screen and (max-width: $bp-tab-one-pixel-down) {
		.justify-content-end {
			display: none;
		}
	}
}

@media (max-width: 1280px) {
	button.button {
		font-size: 14px;
	}
}

.banner {
	height: $bg-height;
	width: 100%;
	background-color: rgba(#fceded, 0.47);
	color: $base;
	overflow: hidden;

	h1 {
		font-weight: 700;
		font-size: 50px;
		line-height: 84px;
		margin-bottom: 2rem;

		span {
			color: $primary;
		}

		@media screen and (max-width: $bp-mob) {
			font-size: 36px;
			line-height: 42px;
		}
	}

	h2 {
		font-size: 36px;
		margin-bottom: 1rem;
		font-weight: 600;

		@media screen and (max-width: $bp-mob) {
			font-size: 28px;
		}
	}

	p {
		font-weight: 400;
		font-size: 24px;
		line-height: 36px;
		letter-spacing: 0.02em;
		width: 90%;
		margin-bottom: 45px;

		@media screen and (max-width: $bp-mob) {
			font-size: 14px;
		}
	}

	@media screen and (max-width: $bp-tab-one-pixel-down) {
		height: 120vh;

		&-content {
			margin-bottom: 2rem;
		}
	}

	@media screen and (max-width: 375px) {
		height: 130vh;
	}
}

.join-btn {
	font-size: 16px;
	border-radius: 15px;
	padding: 1.2rem 1.5rem;
	font-weight: 600;
	height: 63px;
	transition: all 1s;

	@media screen and (max-width: $bp-mob) {
		font-size: 14px;
		height: 40px;
	}
}

.join-btn:hover {
	transform: scale(1.05);
}

.primary-bg {
	background-color: $primary;
	color: white;
}

.secondary-bg {
	background-color: $secondary;
	// color: white;
}

.pink-bg {
	background-color: #fceded;
}

.white-btn {
	background-color: white;
	color: $primary;
}

.white-btn:hover {
	background-color: lighten(white, 5);
	color: $primary;
}

.primary-btn {
	background-color: $primary;
	color: white;
}

.primary-btn:hover {
	background-color: lighten($primary, 5);
	color: white;
}

.input-btn {
	background-color: white;
	height: 60px;
	padding: 0.5rem 2rem;
	display: flex;
	align-items: center;
	border: 1px solid $primary;
	border-radius: 15px;
	color: $primary;
	font-weight: 600;
	font-size: 16px;

	@media (min-width: 767px) {
		.planner_icon {
			height: 50px;
			width: 150px;
		}

		.couple {
			height: unset;
			width: unset;
			margin-left: 15px;
		}
	}
	@media (max-width: 500px) {
		.planner_icon,
		.couple {
			height: 40px;
			width: 40px;
		}
	}

	&.groom {
		@media screen and (min-width: 768px) {
			svg {
				transform: scale(3.5);
			}
			span {
				position: relative;
				left: 7.5px;
			}
		}
	}

	@media screen and (max-width: 480px) {
		padding: 0.25rem 1rem;
		font-size: 14px;
		svg {
			width: 18px;
			height: 18px;
		}
	}

	svg {
		width: 30px;
		height: 30px;
		margin-right: 5px;
	}
}

.calc-btn {
	background: $primary;
	border: 1px solid $primary;
	border-radius: 30px;
	font-weight: 600;
	padding: 0.75rem 3rem;
}

.calc-btn-load {
	background: $primary;
	border: 1px solid $primary;
	border-radius: 30px;
	font-weight: 600;
	width: 40%;
	padding: 0.75rem 3rem;
}

.edit-btn {
	background: $base;
	border-radius: 5px;
	color: white;
	font-size: 14px;
	height: 35px;
	display: flex;
	align-items: center;
	justify-content: center;

	img {
		height: 100%;

		@media screen and (max-width: 767px) {
			height: 150%;
		}
	}
}

.question {
	position: relative;
	top: -3px;
	display: inline-block;
	border-radius: 50%;
	height: 15px;
	width: 15px;
	font-size: 10px;
	font-weight: bold;
	margin-left: 3px;
	text-align: center;
	background-color: $primary;

	span {
		color: white !important;
	}
}

.question-misc {
	// margin-top: 2em !important;
	height: 15px;
	width: 15px;
	background-color: $primary;
	border-radius: 50%;
	display: inline-block;
	position: relative;
	top: 0.35em;
	left: 0.3em;

	span {
		position: relative;
		font-size: 0.5em;
		padding-left: 0.4em;
		top: -10px;
		left: 1px;
		color: white !important;
		font-weight: bolder;
	}
}
// .tooltip {
// 	background: $base;
// 	border: 0.5px solid $base;
// 	box-sizing: border-box;
// 	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
// 	border-radius: 8px;
// 	text-wrap: nowrap;

// 	&-inner {
// 		background-color: transparent;
// 		font-weight: normal;
// 		font-size: 16px;
// 		line-height: 24px;
// 		text-align: center;
// 		min-width: fit-content !important;
// 		max-width: fit-content !important;
// 		padding: 0.1rem 0.4rem;
// 	}
// }

.w-fit {
	width: fit-content !important;
}

.counter {
	display: flex;
	align-items: center;
	button {
		width: 30px;
		height: 30px;
		display: flex;
		text-align: center;
		align-items: center;
		justify-content: center;
		background-color: $primary;
		border-radius: 50%;
		border: none;

		p {
			color: white;
			font-weight: bold;
			font-size: 18px;
			margin-bottom: 0px;
		}
	}

	.input-container {
		background: #ffffff;
		border: 1.5px solid #cb3050;
		box-sizing: border-box;
		border-radius: 15px;
		display: flex;
		flex-direction: row;
		align-items: center;
		padding: 0 10px;
		height: 65px;
		width: 190px;
		margin-right: 15px;
		margin-left: 15px;
		p {
			font-weight: bold;
			font-size: 20px;
			line-height: 30px;
			color: $primary;
			margin-bottom: 0;
		}

		input,
		input:hover,
		input:focus {
			border: none;
			background-color: transparent;
			outline: none;
			box-shadow: none;
			font-weight: 600;
		}
	}

	.mini-input {
		width: 110px;
	}
}

.dropdown {
	.dropdown-btn,
	.dropdown-btn:focus,
	.dropdown-btn:hover,
	.dropdown-btn:active {
		background-color: white !important;
		color: $primary !important;
		outline: none !important;
		box-shadow: none !important;
		border: 1.5px solid $primary !important;
		height: 63px;
		border-radius: 15px;
		text-transform: capitalize;
	}

	.dropdown-toggle::after {
		margin-left: 1.5rem;
	}
}

.hide-dropdown-btn {
	display: none;
}
.dropdown-menu {
	.search {
		border-bottom: 1px solid rgba(84, 85, 101, 0.2);
		padding: 0 1rem;
		margin-bottom: 0.5rem;
		input,
		input:focus,
		input:hover,
		input:active {
			border: none;
			background-color: transparent;
			outline: none;
			box-shadow: none;
		}
	}

	.dropdown-item {
		color: $primary;
		font-weight: 600;
		font-size: 18px;
	}

	.dropdown-option:hover {
		background: rgba(203, 48, 80, 0.15);
	}
}

.custom-check {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-top: 10px;

	button.rectangle {
		width: 20px;
		height: 20px;
		border: 1px solid grey;
		background-color: white;
		border-radius: 5px;
	}

	img.checkbox {
		background-color: transparent;
		border: none;
		outline: none;
	}

	p {
		margin-bottom: 0;
		margin-left: 5px;
	}
}

.underline {
	text-decoration: line-through;
}

.section {
	padding: 3rem 1.5rem;

	&-heading {
		font-weight: 600;
		font-size: 36px;
		margin-bottom: 3rem;

		@media screen and (max-width: $bp-tab-one-pixel-down) {
			padding-bottom: 1rem;
			// border-bottom: 1px dotted $primary;
		}

		@media screen and (max-width: $bp-mob) {
			font-size: 28px;
			margin-bottom: 1.5rem;
		}
	}

	&-subheading {
		font-size: 18px;

		@media screen and (max-width: $bp-mob) {
			font-size: 16px;
		}
	}

	&-title {
		font-weight: 600;
		font-size: 32px;
		margin-bottom: 2rem;

		@media screen and (max-width: $bp-mob) {
			font-size: 24px;
			margin-bottom: 1rem;
		}
	}

	&-content {
		font-size: 20px;
		font-weight: 500;
		color: $primary;
		padding-bottom: 2rem;
		border-bottom: 1px solid $primary;

		@media screen and (max-width: $bp-tab-one-pixel-down) {
			// border-bottom: none;
		}

		@media screen and (max-width: $bp-mob) {
			font-size: 16px;
		}
	}

	&-bar {
		width: 100%;
		height: 15px;
		background-image: linear-gradient(135deg, $secondary, $primary);
	}

	&-circle {
		border-radius: 50%;
		background-color: white;
		color: $primary;
		height: 100px;
		width: 100px;
		margin-bottom: 0.5rem;
		display: flex;
		align-items: center;
		justify-content: center;
		transition: all 1s;

		img {
			max-width: 60px;
		}
	}

	&-circle:hover {
		transform: scale(1.15);
	}

	p.days {
		color: $primary;
		margin-bottom: 0.5rem;
	}

	.days-form {
		.guests {
			margin-left: 1rem !important;
		}
		.form-group {
			margin: 0;
		}
		@media screen and (max-width: 480px) {
			flex-direction: column;

			.guests {
				margin-left: 0px !important;
				margin-top: 1rem !important;
			}
		}
	}
}

section {
	.screen-mockup {
		img {
			@media screen and (max-width: $bp-tab-one-pixel-down) {
				display: none;
			}
		}
	}
}

.screen-mockup {
	display: flex;
	justify-content: center;
	transition: all 1s;

	img {
		max-width: 350px;
		max-height: 565px;

		@media screen and (max-width: 350px) {
			max-width: 100%;
		}
	}
}

.screen-highlights {
	display: flex;
	justify-content: center;
	transition: all 1s;
	padding-top: 1rem;

	img {
		max-width: 500px;
		max-height: 300px;

		@media screen and (max-width: 500px) {
			max-width: 100%;
			max-height: 218px;
		}
	}
}

.screen-mockup:hover,
.screen-highlights:hover {
	transform: scale(1.05);
}

.who-are-you {
	.input-btn {
		transition: all 1s;
		gap: 10px;
	}
	.input-btn:hover {
		transform: scale(1.05);
		color: #cb3050;
	}
	.input-btn.selected {
		background-color: #cb3050;
		color: white;
	}
	@media screen and (max-width: 480px) {
		flex-direction: column;
	}
}

.footer {
	padding: 29px 0px 23px 0px;
	font-size: 14px;
	.logo {
		margin-bottom: 1.5rem;

		img {
			max-width: 120px;
		}
	}

	&-content {
		align-items: center;

		p {
			margin-bottom: 0px;
			text-align: center;
		}

		@media screen and (max-width: $bp-tab-one-pixel-down) {
			text-align: center;

			.col-md-4 {
				margin-bottom: 0.25rem;
			}
		}
	}

	&-link {
		color: inherit;
	}

	&-link:hover {
		color: $primary;
		text-decoration: none;
	}

	&-social {
		display: flex;
		align-items: center;
		gap: 22px;

		.social {
			border-radius: 50%;
			background-color: $primary;
			color: white;
			width: 30px;
			height: 30px;
			display: flex;
			justify-content: center;
			align-items: center;
			transition: all 1s;

			i {
				font-size: 16px;
			}

			a {
				color: white;
			}
		}

		.social:hover {
			transform: scale(1.2);
		}
	}
}

.page {
	background-color: #fcf3f3;
	position: relative;
	padding: 0rem !important;
	&__header {
		font-size: 24px;
		font-weight: 600;
		text-align: center;
		margin-bottom: 2.5rem;
	}

	&__content {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		.option-select {
			.input-btn {
				width: 30%;
				padding: 0.5rem 3.5rem 0.5rem 0.75rem;

				@media screen and (max-width: 480px) {
					padding: 0.25rem 2rem 0.25rem 0.5rem;
					width: 100%;
					margin-bottom: 1rem;
				}
			}
		}

		label.form-label {
			font-weight: normal;
			font-size: 18px;
			text-wrap: nowrap;
			white-space: nowrap;
		}

		input,
		select {
			font-size: 18px;
			color: $primary !important;

			@media screen and (max-width: 480px) {
				font-size: 14px;
			}
		}

		input:focus,
		select:focus {
			outline: none;
			border: 1px solid $primary;
			box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
		}

		input::placeholder,
		select::placeholder {
			color: rgba($primary, 0.4);
		}

		input::-webkit-outer-spin-button,
		input::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}

		input[type="number"] {
			-moz-appearance: textfield;
		}

		.input-append {
			border: none;
		}

		.input-append:focus {
			border: none;
			box-shadow: none;
		}

		.input-group-text {
			background-color: white;
			border: none;
			color: $primary;
			font-weight: 500;
		}

		.no-budget {
			width: 192px;
			padding: 0;
			justify-content: center;
		}
	}

	.page__content {
		.form {
			width: 59%;
		}
	}
	@media (max-width: 560px) {
		.calculator_view {
			.page__content {
				.form {
					width: 100%;
				}
			}
		}
	}

	.page__content .form {
		width: 55%;

		.form-row {
			margin: 0;
		}

		@media screen and (max-width: 768px) {
			width: 100%;
		}
		@media screen and (min-width: 769px) and (max-width: 990px) {
			width: 80%;
		}
	}

	.share {
		display: flex;
		justify-content: space-between;

		margin-bottom: 4rem;
		.btn {
			background-color: $base;
			color: white;
			display: flex;
			justify-content: space-between;
			align-items: center;
			height: 60px;
			border-radius: 8px;
			margin-right: 25px;

			img {
				width: 32px;
				height: 32px;
				margin-right: 7.5px;
			}

			span {
				font-size: 20px;
				font-weight: normal;
				line-height: 30px;
			}
		}

		@media screen and (max-width: 480px) {
			width: 100%;
			justify-content: space-evenly;
			.btn {
				height: 45px;
				width: fit-content;
				margin-right: 3%;
				padding: 0 1rem;
				span {
					font-size: 14px;
				}
				img {
					width: 24px;
					height: 24px;
					margin-right: 5px;
				}
			}
		}
	}

	p.subscribe-text {
		margin-top: 1em;
		text-align: center;
		font-weight: 600;
		color: $primary;
	}

	p.update-text {
		text-align: center;
		font-weight: lighter;
		color: $base;
		font-size: small;
	}

	p.update-text span {
		margin-left: 1%;
	}

	p.update-text img:hover {
		cursor: pointer;
	}

	.subscribe {
		display: flex;
		background: #ffffff;
		align-items: center;
		font-size: 20px;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
		line-height: 30px;
		margin-bottom: 2rem;

		input {
			background-color: transparent;
			height: 100%;
			border: none;
			margin-left: 35px;
			width: 250px;
			color: #545565 !important;
		}

		input::placeholder {
			color: rgba(84, 85, 101, 0.5);
		}

		input:focus {
			outline: none;
			box-shadow: none;
		}

		.btn {
			background: $primary;
			border: 1px solid $primary;
			border-radius: 0px;
			color: whitesmoke;
			padding: 1rem 2rem;
			white-space: nowrap;
			font-weight: bold;
		}

		@media screen and (max-width: 480px) {
			font-size: 14px;
			input {
				width: 60%;
				margin-left: 15px;
				font-size: 12px;
			}

			.btn {
				padding: 0.65rem 1rem;
			}
		}
	}

	.answers {
		&--heading {
			text-align: center;
			h2 {
				font-weight: bold;
				font-size: 30px;
			}

			.cost {
				font-size: 28px;
				color: $primary;
			}
		}
		&--none {
			text-align: center;
			margin-bottom: 4rem;
			p {
				line-height: 32px;
			}
			span {
				color: $primary;
			}

			input[type="range"]:focus {
				outline: none;
				border: none;
				box-shadow: none;
			}
		}

		&--range {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: flex-end;

			small {
				font-size: 14px;
				color: #a2a2a4;
				font-weight: 600;

				@media screen and (max-width: 480px) {
					font-size: 10px;
				}
			}
		}
		@media screen and (max-width: 767px) {
			&--heading {
				h2 {
					font-size: 16px;
				}

				.cost {
					margin-top: 0.5rem;
					font-size: 22px;
					display: block;
				}

				p {
					font-size: 12px;
				}
			}
			&--none {
				p {
					font-size: 12px;
					line-height: 21px;
				}
			}
		}
		@media screen and (max-width: 480px) {
			h3 {
				font-size: 18px;
			}
			.breakdown {
				font-size: 12px;
			}
			.custom-check {
				width: 70%;
				p {
					margin-left: 5px !important;
				}
				.checkbox,
				button.rectangle {
					width: 16px !important;
					height: 16px !important;
				}
			}
		}
		.custom-check {
			button.rectangle {
				width: 30px;
				height: 30px;
			}
		}
	}

	.card {
		width: 80%;
		padding: 3rem 2rem;
		background: #ffffff;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
		border-radius: 25px;
		margin-bottom: 4rem;

		@media screen and (max-width: 920px) {
			width: 100%;
			padding: 1rem 1rem;
			border-radius: 15px;
		}

		@media screen and (max-width: 480px) {
			.card__text {
				font-size: 12px;
				line-height: 18px;
			}

			.card__category {
				h3 {
					font-size: 14px;
					line-height: 21px;
				}

				p {
					font-size: 14px;
					line-height: 21px;
					margin-bottom: 0;
				}

				label {
					font-size: 14px;
					line-height: 21px;
				}
			}
		}

		.pink-bg {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			padding: 1.5rem;
			border-radius: 5px;

			&__body {
				display: flex;
				align-items: center;
				justify-content: center;
				padding-right: 2.5rem;
			}

			.br {
				border-right: 1.5px solid #e598a7;
			}

			&__text {
				margin-left: 2rem;

				span {
					font-size: 14px;
				}

				p {
					margin-bottom: 0;
					color: $primary;
					font-weight: 600;
					font-size: 20px;
					text-transform: capitalize;
					max-width: 230px;
					text-wrap: nowrap;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}
			@media screen and (max-width: 990px) {
				padding: 0.5rem;
				&__icon {
					display: none;
				}
				&__body {
					align-items: flex-start;
				}
				&__text {
					margin-left: 0;
					span {
						font-size: 10px;
					}

					p {
						font-size: 14px;
					}
				}
			}
			@media screen and (max-width: 480px) {
				flex-direction: column;
				text-align: center;
				position: relative;
				&__body {
					width: 100%;
					padding: 0.5rem 1rem;
				}

				.br {
					border-right: none;
					border-bottom: 1.5px solid #e598a7;
				}

				.btn {
					height: 20px;
					width: 20px;
					position: absolute;
					bottom: 5px;
					right: 1rem;
				}
			}
		}

		&__line {
			height: 2px;
			background-color: rgba(203, 48, 80, 0.3);
			margin-left: -3rem;
			margin-right: -3rem;
			margin-bottom: 1rem;
		}

		&__text {
			font-weight: normal;
			font-size: 18px;
			line-height: 27px;
			color: rgba(203, 48, 80, 0.7);
		}

		&__category {
			&--title {
				display: flex;
				align-items: center;

				.question {
					margin-left: 0.5rem;
				}
			}
			h3 {
				font-weight: 600;
				font-size: 20px;
				line-height: 30px;
			}

			p {
				font-weight: normal;
				font-size: 20px;
				line-height: 30px;
				margin-bottom: 0;
			}

			span {
				color: $primary;
			}

			.d-flex {
				margin-bottom: 1rem;
			}

			.custom-check {
				margin-top: 0;

				p {
					margin-left: 1rem;
				}
			}
		}

		.mobile-btn {
			display: none;
			@media screen and (max-width: 768px) {
				display: flex;
				justify-content: flex-end;
				margin-bottom: 1rem;
			}
		}

		.mini-cards {
			display: flex;
			justify-content: space-between;
			gap: 15px;
			margin-top: 50px;

			@media screen and (max-width: 768px) {
				flex-direction: column;
			}
		}

		.mini-card {
			color: white;
			width: 33%;
			min-height: 200px;
			padding: 1.25rem;
			background: linear-gradient(247.49deg, #cb3050 10.33%, #545565 100%);
			box-shadow: 0px 8px 24px rgba(84, 85, 101, 0.2);
			border-radius: 10px;
			position: relative;
			cursor: pointer;
			p {
				font-weight: bold;
				font-size: 16px;
				line-height: 24px;
				margin-bottom: 0px;
			}
			p:first-child {
				margin-bottom: 1rem;
			}
			.arrow {
				display: flex;
				justify-content: flex-end;
			}
			.icon {
				position: absolute;
				top: 50%;
				left: 60%;
			}
			@media screen and (max-width: 768px) {
				margin-bottom: 2rem;
				width: 100%;
			}
			&:hover {
				text-decoration: none;
			}
		}
	}

	&__card {
		// border-radius: 10px;
		padding: 1rem;

		h2 {
			font-size: 24px;
			line-height: 36px;
			letter-spacing: 0.02em;
		}

		h3 {
			font-weight: 600;
			font-size: 20px;
		}

		p {
			color: rgba($primary, 0.7);
		}

		p,
		span {
			font-weight: 600;
			font-size: 20px;
		}
	}

	.heart1,
	.heart2,
	.heart3,
	.heart4 {
		position: absolute;

		img {
			height: 100%;
			width: 100%;
			object-fit: contain;
		}
	}

	.heart1 {
		top: 50px;
		left: 0px;

		@media screen and (max-width: 767px) {
			height: 80px;
		}
	}

	.heart2 {
		top: 15rem;
		right: 0px;

		@media screen and (max-width: 767px) {
			height: 80px;
		}
	}

	.heart3 {
		bottom: 10rem;
		left: 3rem;

		@media screen and (max-width: 767px) {
			bottom: 5rem;
			left: 2rem;
			height: 70px;
		}
	}

	.heart4 {
		bottom: 8rem;
		right: 3rem;

		@media screen and (max-width: 767px) {
			right: 2rem;
			bottom: 6rem;
			height: 60px;
		}
	}

	@media screen and (max-width: 727px) {
		.top {
			display: none !important;
		}
		.bottom {
			display: block !important;
		}
		.footer-social {
			margin-top: 1rem;
		}
		.section-navbar {
			margin-bottom: 5rem !important;
		}

		.nav-links a {
			margin-bottom: -1em !important;
		}
		.navbar {
			.nav-icons {
				display: block;
			}
			.display-dp {
				display: inline !important;
			}
		}

		.section-bar-menu {
			margin-top: 5rem;
		}
	}
}

.rc-slider-mark-text {
	font-size: 14px;
	color: #a2a2a4 !important;
	font-weight: 600;
}

.rc-slider-handle {
	font-size: 18px;
	border: solid 2px #cb3050 !important;
	background-color: #cb3050 !important;
}

.rc-slider-track {
	background-color: rgba(203, 48, 80, 0.5) !important;
}

.rc-slider-dot,
.rc-slider-dot-active {
	border-color: rgba(203, 48, 80, 0.2) !important;
}
.form-control:disabled,
.form-control[readonly] {
	background-color: white;
}
.rc-slider-mark-text {
	width: 12rem;
	padding-left: 2rem;
	padding-right: 2rem;

	@media screen and (max-width: 767px) {
		width: 8rem;
		padding-right: 2rem;
		padding-left: 1rem;
	}
}

// .rc-slider-mark:last-child {
//   left: 90%;
// }

.firebase-emulator-warning {
	display: none !important;
}

@keyframes spinners-react-circular {
	0% {
		stroke-dashoffset: 306;
	}
	50% {
		stroke-dasharray: 40, 134;
	}
	100% {
		stroke-dasharray: 1, 174;
		stroke-dashoffset: 132;
	}
}
