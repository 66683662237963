section.error_page {
	height: calc(100vh - 30vh);
	display: flex;
	flex-direction: column;
	margin: auto;
	justify-content: center;
	align-items: center;
	text-align: center;

	div {
		display: flex;
		flex-direction: column;
		gap: 20px;
		/* margin: auto; */
		/* justify-content: center; */
		align-items: center;
	}

	div h1 {
		color: #000;
	}

	a {
		font-size: 24px;
		background-color: #cb3050;
		color: white;
		padding: 10px 40px;
		border-radius: 8px;
	}
	@media (max-width: 768px) {
		padding: 0px 20px;

		div h1 {
			font-size: 24px;
		}
		a {
			font-size: 16px;
		}
	}
}
