@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400,500&family=Manrope:wght@400,500,600,700&display=swap");
@import "./variables.scss";

.welcome_modal .modal-dialog {
	width: 320px;
	margin-top: 150px;
	.modal-content {
		border-radius: 10px;
		border: none;
		padding: 0px;
		.modal-body {
			padding: 20px;
			padding-top: 40px;
		}
	}
}

.modal div.modal-dialog.welcome_modal {
	box-shadow: 0px 0px 35px 0px #00000026;
	.modal-content {
		border-radius: 16px;
		background-color: rgba(252, 252, 252, 0.96);
	}
}
.welcome_modal_content {
	border: none;
	font-family: "Inter", sans-serif;
	.wrapper {
		border: 1px solid #fceef0;
		height: 40px;
		width: 40px;
		border-radius: 6px;
		display: flex;
		margin: auto;
		align-items: center;
		justify-content: center;
		img {
			height: 28px;
			width: 28px;
			display: flex;
			align-items: center;
			justify-content: center;
			margin: auto;
		}
	}
	.text_content {
		border: none;
		padding: 16px 8px 0px;
		margin-bottom: 20px;
		h4 {
			color: #031729;
			font-size: 20px;
			font-weight: 600;
			line-height: 20px;
			letter-spacing: -0.025em;
			text-align: center;
			margin-bottom: 5px;
		}
		.text_1 {
			margin-bottom: 20px;
			color: #031729;
			font-size: 12px;
			font-weight: 500;
			line-height: 18px;
			letter-spacing: -0.025em;
			color: #9395aa;
			text-align: center;
		}

		.first_step {
			font-size: 10px;
			font-weight: 400;
			line-height: 10px;
			letter-spacing: -0.025em;
			margin-left: 5px;
			margin-top: 30px;
			color: #9395aa;
		}

		ul {
			padding: 0;
			text-align: left;
			display: flex;
			flex-direction: column;
			gap: 10px;
			margin-top: 15px;
			margin-left: 20px;
			margin-bottom: 50px;
			list-style: decimal;
			a {
				font-size: 12px;
				font-weight: 500;
				line-height: 20px;
				letter-spacing: -0.025em;
				svg {
					height: 12px;
					width: 12px;
				}
				text-decoration: underline;
				color: $base_red;
			}
		}
	}
	.button_group {
		margin-top: 20px;
		display: flex;
		margin-bottom: 20px;
		justify-content: space-between;
		align-items: center;
		gap: 15px;
		button {
			width: 100%;
			border-radius: 6px;
			padding: 0px 16px;
			height: 36px;
			font-family: "Inter", sans-serif;
			color: #fff;
			background: #031729;
			line-height: 24px;
			text-align: center;
			font-size: 13px;
			font-weight: 600;
			line-height: 18.2px;
			letter-spacing: -0.025em;
			border-width: 0;
		}
	}
}

@media (max-width: 1300px) {
	.modal div.modal-dialog {
		max-width: 50%;
	}
}

@media (max-width: 768px) and (min-width: 561px) {
	div.modal div.modal-dialog {
		width: 100%;
		max-width: 50%;
	}

	.welcome_modal .wrapper img {
		width: 80%;
		height: 80%;
	}

	.welcome_modal .wrapper .text_content h4 {
		font-size: 1rem;
	}

	.welcome_modal .button_group button {
		font-size: 0.75rem;
	}

	section.welcome_modal {
		padding: 0px;
		margin: 10px 0;
	}
}

@media (max-width: 560px) {
	div.modal div.modal-dialog {
		width: 100%;
		max-width: 95%;
	}

	.welcome_modal .wrapper img {
		width: 100%;
		height: 100%;
	}

	.welcome_modal .wrapper .text_content h4 {
		font-size: 1rem;
	}

	.welcome_modal .button_group button {
		font-size: 0.75rem;
	}

	section.welcome_modal {
		padding: 0px;
		margin: 10px 0;
	}
}
