div.new_ceremony_picker {
	position: relative;
	font-family: "Inter", sans-serif;
	margin-bottom: 20px;
	&.search_select .select__control {
		border: 1px solid #30303026;
		font-size: 0.8125rem;
		border-radius: 8px;
		height: 54px;
		&:hover {
			border: 1px solid #30303026;
		}
		&:focus {
			border: 1px solid #4285f4;
		}
		.select__placeholder {
			font-size: 0.8125rem;
			color: #000;
		}

		.select__value-container {
			padding: 0;
			margin-top: 12px;
			font-weight: 500;
		}
	}
	label {
		position: absolute;
		font-size: 10px;
		color: #606060;
		left: 14px;
		top: 11px;
		font-family: "Inter", sans-serif;
		font-weight: 400;
		z-index: 10;
	}
	.select__menu {
		background-color: white;
		z-index: 100;
		.select__menu-list {
			font-size: 0.8125rem;
			width: 100%;
			max-height: unset;
			.select__option {
				width: 100%;
				&:hover {
					background-color: #fbfbfb;
					color: #000;
					cursor: pointer;
					&::after {
						content: url("../assets/img/checkIconRed.svg");
						position: absolute;
						right: 5px;
						transform: scale(0.7);
					}
				}
				&.select__option--is-selected {
					background-color: #fbfbfb;
					&::after {
						content: url("../assets/img/checkIconRed.svg");
						position: absolute;
						right: 5px;
						transform: scale(0.7);
					}
				}
			}
		}
	}
}

@media (max-width: 768px) {
	div.new_ceremony_picker.search_select .select__control .select__placeholder {
		font-size: 11px;
	}
}
