@import "./variables";

.nav_dropdown.bold button {
	font-weight: 600;
}

.nav_dropdown.normal button {
	font-weight: 500;
}

.nav_dropdown button span.ml-3 svg {
	transform: rotate(0deg);
	transition: transform 0.5s;
}

.nav_dropdown.show button span.ml-3 svg {
	transform: rotate(0deg);
	transition: transform 0.5s;
}

.nav_dropdown button.btn.p-0:hover {
	transform: scale(1.05);
	transition: all 1s;
	color: #212529 !important;
}

#dropdown.three_dot {
	button.dropdown_toggle {
		width: 34px;
		padding: 0px !important;
		justify-content: center;
	}
}

.custom.dropdown {
	.dropdown-menu.show {
		min-width: 240px;
		box-shadow: 0px 4.5px 15.75px 1.13px #3030301a;
		border: 1px solid #30303026;
		border-radius: 8px;
		margin-top: 5px !important;
		padding: 5px;
		z-index: 1000;
		background-color: white;
		p {
			font-size: 12px;
			font-family: "Inter", sans-serif;
			text-align: center;
		}
		button,
		.dropdown-item {
			position: relative;
			padding: 8px 14px;
			border: none;
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			background-color: white;
			color: #000;
			font-family: "Inter", sans-serif;
			font-size: 12px;
			font-weight: 500;
			line-height: 18px;
			letter-spacing: -0.025em;
			text-align: center;

			&:hover {
				background-color: #fbfbfb;
				border-radius: 8px;
				color: $base_red;
				position: relative;
				&::after {
					content: url("../assets/img/checkIconRed.svg");
					position: absolute;
					right: 5px;
					transform: scale(0.7);
				}
			}

			&.no_hover_tick:hover {
				&::after {
					display: none;
				}
			}
		}
	}

	&.pick_event_dropdown {
		.dropdown-menu.show p {
			margin-bottom: 0;
			white-space: nowrap;
			text-wrap: nowrap;
		}
	}
}

.dropdown-menu.show {
	z-index: 2000;
	box-shadow: 0px 4.5px 15.75px 1.13px #3030301a;

	button:hover {
		background: #fbfbfb;
	}
}

div.nav_dropdown.btn,
div.nav_dropdown.btn button {
	outline: 0;
	box-shadow: none;
	opacity: 1;
}

.dropdown.text-color_base .btn,
.dropdown.text-color_base .dropdown-menu .dropdown-item {
	color: $base;
}

.nav_dropdown .dropdown-menu .dropdown-item {
	line-height: 27px;
	// margin-bottom: 20px;
	padding: 0px 20px;
}

.nav_dropdown .dropdown-menu.show {
	padding: 15px 0px;
	border-radius: 0px;
	border: 1px solid #c4c4c4;
	z-index: 1000;
	position: relative;
}

.nav_dropdown .dropdown-toggle::after {
	border: 0;
}

//for mobile
@media (max-width: 768px) {
	.side-nav-link .nav_dropdown .dropdown-menu .dropdown-item {
		font-size: 16px;
		line-height: 20px;
		font-weight: 500;
	}
}
