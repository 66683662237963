.event_loader {
	.card_event_overview {
		width: 100%;
		height: 250px;
		margin-bottom: 30px;
		rect {
			width: 100%;
		}
	}
	.card_group {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 40px;

		svg {
			width: 100%;
			border-radius: 10px;
			height: 250px;
		}
		rect {
			height: 250px;
			width: 100%;
		}
	}

	@media (max-width: 768px) {
		grid-template-columns: repeat(1, 1fr);
	}

	@media (max-width: 500px) {
		.card_group {
			display: flex;
			flex-direction: column;
			gap: 20px;
			svg {
				min-width: 100%;
				display: flex;
			}
		}
	}
}

.budget_tracker_loader {
	.card_loader {
		svg {
			width: 100%;
		}
	}
	.card_group_view {
		gap: 10px;
		.card_group {
			gap: 10px;
		}
		.card_event_overview {
			width: 100%;
		}
	}
}

.checklist_loader {
	width: 100%;
	svg,
	rect {
		width: 100%;
	}
}
