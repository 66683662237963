@import "./variables";

.text-color_base {
  color: $base;
}

.font-500 {
  font-weight: 500;
}

.font-600 {
  font-weight: 600;
}

.bg-site_base {
  background-color: $base;
}
