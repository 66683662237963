@import './variables.scss';

.forgot_password_form button {
	width: 200px;
	height: 50px;
	font-weight: bold;
}

.auth_layout {
	align-items: center;
	height: 100vh;
	.reset_password_form {
		.error_text {
			margin-top: -40px;
			font-size: 12px;
		}
	}
	.auth_left_column {
		background-color: #fceded;
		padding-left: 64px;
		padding-top: 36px;
		height: 100%;
		@media (max-width: $bp-dsk) {
			padding-left: 45px;
		}
		h1 {
			font-weight: 700;
			font-size: 48px;
			line-height: 70px;
			letter-spacing: 0.02em;
			color: $primary;
			max-width: 447px;
			margin-top: 8.3rem;
			@media (max-width: $bp-dsk) {
				margin-top: 7rem;
				font-size: 36px;
				line-height: 50px;
			}
		}
		.logo {
			height: 43px;
			width: 156px;
		}
	}
	.auth_right_column {
		padding: 2.5rem 9rem 2.5rem 4.5rem;
		height: 100%;
		@media (max-width: $bp-dsk) {
			padding: 2.5rem 5rem 2.5rem 2.5rem;
		}
		.text button {
			font-weight: 500;
			font-size: 20px;
			line-height: 30px;
		}
		h4 {
			font-weight: 600;
			font-size: 24px;
			line-height: 36px;
			color: $base;
			margin-bottom: 24px;
		}
		p {
			font-weight: 500;
			font-size: 20px;
			line-height: 30px;
			color: $base;
			span {
				color: $primary;
			}
		}
		form {
			margin-top: 3rem;
			@media (max-width: $bp-dsk) {
				margin-top: 2rem;
			}
			.form_element {
				margin-bottom: 2.2rem;
			}
			button {
				padding: 0.8rem 0;
			}
			button.submit {
				width: unset;
			}
		}
		.other_auth_method {
			position: relative;
			p {
				font-weight: 500;
				font-size: 20px;
				line-height: 30px;
				color: $base;
				text-align: center;
				margin: 0.5rem 0 2.5rem 0;
			}
			span {
				text-align: center;
			}
			.or_socials {
				margin: 2rem auto;
				height: 15px;
				div {
					position: absolute;
					width: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
				}
				hr {
					background-color: #a7a8b0;
					width: 100%;
					z-index: 1;
					position: relative;
				}
				span {
					position: absolute;
					background-color: white;
					z-index: 2;
					width: 60px;
				}
			}
		}
		.text {
			justify-content: center;
			.forgot_password {
				color: $primary;
				border: 0px;
				background-color: transparent;
				@media (max-width: 500px) {
					font-size: 16px;
					line-height: 18px;
				}
			}
		}
		.icons {
			display: flex;
			align-items: center;
			gap: 72px;
			margin: auto;
			justify-content: center;
			button {
				background-color: $primary;
				height: 60px;
				width: 60px;
				display: flex;
				border-radius: 50%;
				justify-content: center;
				align-items: center;
				border: 0;
				img {
					height: 30px;
					width: 30px;
				}
			}
		}
		.login_form {
			.forgot_password {
				color: $primary;
				border: 0px;
				background-color: transparent;
				@media (max-width: 500px) {
					font-size: 16px;
					line-height: 18px;
				}
			}
		}
	}
}

@media (max-width: $bp-tab) {
	.auth_layout .auth_left_column {
		padding: 20px;
		max-height: 350px;
	}
	.auth_layout .auth_left_column h1 {
		font-size: 32px;
		line-height: 48px;
		margin-top: 5rem;
	}
	.auth_layout .auth_right_column {
		padding: 20px;
		height: 100%;
	}

	.auth_layout .auth_right_column h4 {
		font-size: 20px;
		line-height: 18px;
		margin-top: 10px;
	}
	.auth_layout .auth_right_column p {
		font-size: 16px;
		line-height: 18px;
	}
	.auth_layout .auth_right_column form {
		margin-top: 2rem;
	}
	.auth_layout .auth_right_column form .form_element {
		margin-bottom: 1.2rem;
	}
	.form_element label {
		margin-bottom: 5px;
		line-height: 18px;
		font-size: 14px;
	}
	.form_element input,
	.form_element input::placeholder {
		font-size: 16px;
	}
	.auth_layout .auth_right_column form button.bg_red {
		margin-top: 40px;
	}

	.auth_layout .auth_right_column .other_auth_method p {
		font-size: 16px;
		line-height: 18px;
	}
}

@media (max-width: $bp-phone) {
	.auth_layout .auth_left_column .logo {
		height: 30px;
		max-width: 112px;
	}

	.auth_layout .auth_left_column {
		padding: 20px;
		max-height: 250px;
	}

	.auth_layout .auth_left_column h1 {
		font-size: 28px;
		line-height: 36px;
		margin-top: 4rem;
	}
}
