@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400,500&family=Manrope:wght@400,500,600,700&display=swap");
@import "./variables.scss";

.dashboard_layout div.dashboard_content {
	height: 100vh;
	overflow-y: scroll;
	padding-bottom: 100px !important;
}

.homepage {
	font-family: "Inter", sans-serif;
	display: flex;
	flex-direction: column;
	gap: 40px;

	.event_top_view {
		background: #fafafc;
		padding: 10px;
		border-radius: 6px;
		.event_overview_top_bar {
			display: flex;
			align-items: center;
			justify-content: space-between;
			button,
			a {
				border: 1px solid #5455660d;
				background-color: #fff;
				border-radius: 6px;
				height: 32px;
				font-size: 12px;
				font-weight: 500;
				line-height: 18px;
				letter-spacing: -0.025em;
				color: #404040;
				padding: 0px 10px;

				&.collaborators {
					display: flex;
					align-items: center;
					gap: 6px;
					span {
						background: #fafafc;
						font-size: 10px;
						font-weight: 500;
						line-height: 10px;
						color: #404040;
						height: 18px;
						width: 18px;
						display: flex;
						align-items: center;
						justify-content: center;
						border-radius: 18px;
					}
					&:hover {
						text-decoration: none;
					}
				}

				&.create_ceremony {
					background-color: #000;
					color: #fff;
					display: flex;
					align-items: center;
					justify-content: center;
					gap: 5px;
					svg {
						height: 12px;
						width: 12px;
					}
				}
			}
			.controls {
				display: flex;
				align-items: center;
				gap: 10px;
				justify-content: space-between;
				.ceremony {
					button.dropdown_toggle {
						display: flex;
						align-items: center;
						justify-content: center;
						padding: 0px 10px !important;
						text-wrap: nowrap;
						white-space: nowrap;
						svg {
							width: 16px;
							height: 16px;
						}
					}
				}

				.three_dots {
					.dropdown_toggle {
						height: 32px;
						width: 32px;
						display: flex;
						align-items: center;
						justify-content: center;
						span {
							display: none;
						}
						svg {
							height: 16px;
							width: 16px;
						}
					}
				}
			}

			.dropdown.pick_event_dropdown {
				background-color: #fff;
				border-radius: 6px;
				.dropdown_toggle {
					border: 1px solid #5455660d;
					padding: 6px 4px 6px 6px !important;
					height: 40px;
					border-radius: 6px;
					display: flex;
					text-wrap: nowrap;
					white-space: nowrap;
					align-items: center;
					.pick_an_event {
						display: flex;
						align-items: center;
						gap: 5px;
						font-family: "Inter", sans-serif;
						font-size: 13px;
						font-weight: 500;
						line-height: 22.4px;
						letter-spacing: -0.025em;
						text-align: left;
						color: #031729;
						.text {
							text-overflow: ellipsis;
							text-wrap: nowrap;
							white-space: nowrap;
							overflow: hidden;
							max-width: 140px;
						}
						button {
							border: none;
							transform: rotate(90deg);
							background: #fafafc;
							border-radius: 4px;
							height: 28px;
							width: 28px;
							padding: 0;
							box-shadow: none;
						}
						span {
							border: 1px solid #5455660d;
							height: 24px;
							width: 24px;
							border-radius: 4px;
							border: 1px solid #5455660d;
							display: flex;
							align-items: center;
							justify-content: center;
							svg {
								height: 12px;
								width: 12px;
								padding: 0px;
							}
						}
						svg {
							// border: 1px solid #5455660d;
							padding: 5px;
							transform: rotate(90deg);
						}
						+ span {
							display: none;
						}
					}
				}
			}
			.control_group {
				display: flex;
				align-items: center;
				gap: 10px;
			}
			@media (max-width: 768px) {
				.row_1 {
					width: 100%;
					display: flex;
					align-items: center;
					justify-content: space-between;
				}
			}
		}

		.info_cards {
			display: flex;
			align-items: center;
			gap: 10px;
			margin-top: 20px;
			.card_group {
				display: grid;
				width: 85%;
				grid-template-columns: repeat(3, 1fr);
				gap: 10px;
				.card {
					height: 96px;
					border-radius: 6px;
					border: 1px solid #e6e6e6;
					padding: 18px 16px 12px;
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					.row {
						display: flex;
						justify-content: space-between;
						width: 100%;
						align-items: flex-start;
						margin: 0px;
						h4 {
							font-size: 20px;
							font-weight: 500;
							line-height: 22px;
							letter-spacing: -0.035em;
							color: #031729;
							display: flex;
							align-items: center;
							text-transform: capitalize;
							text-overflow: ellipsis;
							text-wrap: nowrap;
							white-space: nowrap;
							overflow: hidden;
							max-width: 150px;
						}
						.event_location {
							gap: 10px;
						}
					}
					p {
						margin-bottom: 0;
						font-family: "Inter";
						font-size: 13px;
						font-weight: 500;
						line-height: 13.4px;
						letter-spacing: -0.025em;
						color: #404040;
						&.icon {
							font-size: 16px;
							margin-top: 2px;
						}
					}
					&.first {
						background-color: #f2f2f2;
					}
					&.second {
						background-color: #e7eeff;
					}
					&.third {
						background-color: #defdee;
						.weather {
							margin-top: -6px;
							.loader {
								.lds-ring div {
									border-color: #000 transparent transparent transparent !important;
								}
							}
							span {
								img {
									height: 24px;
									width: 24px;
								}
								h6 {
									color: #000;
									font-size: 14px;
								}
							}
						}
					}
				}
			}
			.card_img {
				height: 96px;
				border-radius: 6px;
				border: 1px solid #e6e6e6;
				width: 15%;
				position: relative;
				img {
					height: 100%;
					width: 100%;
					object-fit: cover;
					object-position: top center;
					border-radius: 6px;
				}
			}
			.add_image {
				width: 32px;
				height: 32px;
				border-radius: 4px;
				background-color: #6d849a;
				padding: 10px;
				display: flex;
				cursor: pointer;
				align-items: center;
				justify-content: center;
				min-height: 24px;
				min-width: 24px;
				position: absolute;
				right: 8px;
				z-index: 1;
				top: 8px;
				border: 1px solid #5455660d;
				input {
					position: absolute;
					width: 100%;
					height: 100%;
					z-index: 100;
					cursor: pointer;
					&::-webkit-file-upload-button {
						visibility: hidden;
					}
				}
				svg {
					min-height: 12px;
					min-width: 12px;
					position: relative;
					z-index: 1;
				}
				img {
					height: 32px;
					width: 32px;
					border-radius: 100%;
					min-height: 32px;
					min-width: 32px;
					object-fit: cover;
					object-position: top center;
				}
			}
		}
	}

	.grid_view {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 10px;
		background-color: #fafafc;
		border-radius: 6px;
		padding: 10px;
		.item_view {
			display: flex;
			flex-direction: column;
			width: 100%;
			background-color: #fff;
			border-radius: 6px;
			padding: 21px 24px;
			min-height: 400px;
			height: 100%;
			.row {
				display: flex;
				align-items: center;
				justify-content: space-between;
				width: 100%;
				margin: 0;
				button {
					font-size: 12px;
					font-weight: 500;
					line-height: 18px;
					letter-spacing: -0.025em;
					border-radius: 6px;
					border: 1px solid #5455660d;
					background-color: #fff;
					height: 32px;
					color: #606060;
					padding: 0px 10px;
				}
				h4 {
					font-size: 14px;
					font-weight: 600;
					line-height: 19.6px;
					letter-spacing: -0.025em;
					color: #000;
					display: flex;
					align-items: center;
					gap: 5px;
				}
				h5 {
					font-size: 20px;
					font-weight: 500;
					line-height: 20px;
					letter-spacing: -0.025em;

					span.done {
						color: #000;
						font-size: 20px;
						margin: 0;
						font-weight: 500;
					}
					color: #606060;
					span {
						font-size: 12px;
						font-weight: 400;
						line-height: 18px;
						letter-spacing: -0.025em;
						margin-left: 5px;
					}
				}
				svg {
					color: #9395aa;
					height: 13px;
					width: 13px;
				}
			}
			p {
				font-size: 12px;
				font-weight: 500;
				line-height: 18px;
				letter-spacing: -0.025em;

				color: #9395aa;
			}

			.item_group {
				display: flex;
				flex-direction: column;
				gap: 10px;
				.budget,
				.default_checklist_item {
					display: flex;
					gap: 20px;
					align-items: flex-start;
					width: 100%;
					justify-content: space-between;
					time {
						text-transform: uppercase;
						font-size: 10px;
						font-weight: 400;
						line-height: 10px;
						letter-spacing: -0.025em;
						color: #606060;
						white-space: nowrap;
						display: flex;
						flex-direction: column;
						justify-content: space-between;
						height: 100%;
						padding: 5px 0px;
						span {
							font-size: 16px;
						}
					}
					.item {
						height: 64px;
						border-radius: 12px;
						display: flex;
						width: 100%;
						align-items: flex-start;
						border: 1px solid #5455660d;
						padding: 12px;
						&.budget_list_view {
							justify-content: space-between;
							border-radius: 6px;
						}
						.budget_list {
							display: flex;
							align-items: flex-start;
						}
						img {
							height: 24px;
							width: 24px;
							border-radius: 50%;
							box-shadow: 0px 4px 8px 0px #0000000d;
							border: 2px solid #ffffff;
						}
						.text {
							margin-left: 10px;
							h5 {
								font-size: 13px;
								font-weight: 500;
								line-height: 18.2px;
								letter-spacing: -0.025em;

								color: #031729;
							}
							p,
							time {
								font-size: 12px;
								font-weight: 400;
								line-height: 18px;
								letter-spacing: -0.025em;
								color: #9395aa;
								padding: 0;
								margin: 0;
							}
						}
						p {
							font-family: "Manrope", sans-serif;
							font-size: 14px;
							font-weight: 600;
							line-height: 14px;
							color: #031729;
							padding: 0;
							margin: 0;
						}
					}
				}
				&.document_group {
					h6 {
						color: #9395aa;
						font-size: 12px;
						font-weight: 600;
					}
					.document_card_group {
						display: flex;
						align-items: center;
						padding: 0;
						list-style: none;
						gap: 10px;
						margin-top: 20px;
						li:first-child {
							border: 1px solid #5455660d;
							background: #f2f2f2;
							h6 {
								color: #404040;
							}
							span {
								background-color: #f2f2f2;
								color: #404040;
								border: 1px solid #5455660d;
							}
						}

						.document_card {
							height: 104px;
							border-radius: 6px;
							width: 100%;
							padding: 10px;
							svg {
								height: 16px;
								width: 16px;
							}
							.card_content {
								margin-top: 15px;
								padding: 0;
								h6 {
									font-family: "Inter", sans-serif;
									font-size: 13px;
									font-weight: 600;
									line-height: 18.2px;
									letter-spacing: -0.025em;
									text-align: left;
									margin: 0;
								}
								span {
									padding: 4px 8px;
									border-radius: 100px;
									width: fit-content;
									height: 18px;
									font-family: "Inter", sans-serif;
									font-size: 10px;
									font-weight: 600;
									line-height: 10px;
									text-align: center;
								}
							}
						}

						li:nth-child(2) {
							background: #fee4e5;
							border: 1px solid #eebebf;
							h6 {
								color: #7c494c;
							}
							span {
								color: #844849;
								background-color: #febfbd;
							}
						}

						li:nth-child(3) {
							background: #fdf0d1;
							border: 1px solid #f9edd4;
							h6 {
								color: #665537;
							}
							span {
								color: #844849;
								background-color: #ffdca3;
							}
						}

						li:nth-child(4) {
							background: #e7eeff;
							border: 1px solid #dce0ec;
							h6 {
								color: #3c4c69;
							}
							span {
								color: #3c4c69;
								background: #b2d0f8;
							}
						}
					}
					p {
						color: #606060;
						font-size: 12px;
						font-weight: 500;
						text-align: center;
						margin-top: 20px;
					}
				}
				&.calendar_view {
					padding-top: 20px;

					.react-datepicker {
						border: none;
					}
					.react-datepicker__header {
						background-color: white;
						border: none;
					}
					.react-datepicker__current-month {
						padding: 10px 0px;
						border-bottom: 1px solid gainsboro;
					}
					.react-date-picker__month-container {
						width: 100%;
					}

					.react-datepicker__month-container {
						width: 100%;
						border: none;
					}

					.react-datepicker__day-name {
						font-weight: bold;
						padding: 10px 30px;
					}

					.react-datepicker__navigation-icon::before,
					.react-datepicker__navigation-icon::after {
						border-color: black;
					}

					.react-datepicker__day {
						padding: 10px 30px;
					}

					button.react-datepicker__navigation.react-datepicker__navigation {
						margin-top: 10px;
						color: black;
					}

					.react-datepicker__day--today,
					.react-datepicker__day--keyboard-selected {
						background-color: unset;
						color: #4285f4;
						font-weight: bold;
					}
				}
			}

			.manage_checklist {
				border: 1px solid #5455660d;
				border-radius: 6px;
				padding: 6px;
				display: flex;
				align-items: center;
				gap: 50px;
				h5 {
					margin: 0;
					padding: 0;
					display: flex;
					align-items: center;
				}
			}
		}
		.todo_body .checklist_item h6 {
			max-width: 300px;

			@media (max-width: 560px) {
				max-width: 220px;
			}
		}
	}

	.tips_wrapper {
		background-color: #fafafc;
		padding: 6px;
		.tips_ideas {
			background-color: #fff;
			padding: 16px 12px;
			border-radius: 6px;
			h4 {
				font-size: 14px;
				font-weight: 600;
				line-height: 19.6px;
				letter-spacing: -0.025em;
			}
			.tips_group {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				gap: 10px;
				a {
					border: 1px solid #5455660d;
					padding: 0px 10px;
					border-radius: 6px;
					height: 32px;
					width: fit-content;
					font-size: 12px;
					font-weight: 500;
					line-height: 18px;
					letter-spacing: -0.025em;
					display: flex;
					align-items: center;
					color: #606060;
					text-wrap: nowrap;
					white-space: nowrap;
					&:hover {
						color: $base_red;
						border-color: $base_red;
						text-decoration: none;
					}
				}
			}
		}
	}
}

@media (max-width: 768px) {
	.dropdown_group {
		span {
			display: none;
		}
		text-wrap: nowrap;
		max-width: 100px;
		white-space: nowrap;
		text-overflow: ellipsis;
		flex-wrap: unset;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}
}
