body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a:active,
a:focus,
li:focus,
li:active {
  outline: none !important;
  border: none !important;
  text-decoration: none !important;
  box-shadow: none !important;
  -webkit-tap-highlight-color: transparent !important;
  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */
  user-select: none;
}

/* datepicker navigation button */
button.react-datepicker__navigation.react-datepicker__navigation span {
  display: flex;
  align-items: center;
  margin: auto;
  justify-content: center;
  position: relative;
  left: 0;
  right: 0;
  top: 0;
  font-size: unset;
}

button.react-datepicker__navigation.react-datepicker__navigation {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  height: 30px;
  width: 30px;
}
