@import "./variables.scss";

.tooltip {
	&-inner {
		color: white;
		background-color: #031729 !important;
		font-size: 10px !important;
		line-height: 16px !important;
		text-align: center;
	}
}
.more_details .save_btn {
	background: #031729;
	color: #fff;
	border: none;
	font-size: 0.75rem;
	font-weight: 500;
	line-height: 16.8px;
	letter-spacing: -0.025em;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 8px 10px;
	gap: 5px;
	width: 102px;
	height: 28px;
	border-radius: 6px;
}

.homepage .grid_view .item_view.budget_view .item_group .budget time span {
	font-size: 12px;
	font-weight: 500;
}

.delete_category_cost_item_modal,
.rename_category_cost_item_modal,
.delete_modal_view {
	.modal-dialog {
		display: flex;
		align-items: center;
		margin: auto;
		justify-content: center;
	}
	.modal-content {
		width: 400px;
		border-radius: 8px;
		padding: 24px;
		border: 0.5px solid #e72e50;
		box-shadow: 0px 4px 20px 0px #0000000d;

		.modal-body {
			width: 100%;
		}

		.delete_category_cost_item,
		.rename_category_cost_item {
			width: 100%;
			.icon_wrapper {
				display: flex;
				margin: auto;
				justify-content: center;
				margin-bottom: 30px;
				svg {
					stroke: #e72e50;
				}
			}
			h4 {
				font-family: "Inter", sans-serif;
				font-size: 14px;
				font-weight: 500;
				line-height: 19.6px;
				letter-spacing: -0.025em;
				text-align: center;
				color: #031729;
			}

			button {
				border-radius: 6px;
				padding: 12px;
				height: 36px;
				background-color: #e62e50;
				font-family: "Inter", sans-serif;
				font-size: 12px;
				font-weight: 600;
				line-height: 16.8px;
				letter-spacing: -0.025em;
				margin: auto;
				display: flex;
				color: white;
				border: none;
				align-items: center;
				margin-top: 25px;
			}
		}
	}
}

.budget_tracker {
	font-family: "Inter", sans-serif;
	.event_summary_overview {
		background-color: #fafafc;
		border-radius: 6px;
		padding: 10px;
		.event_budget_row {
			display: flex;
			flex-direction: row;
			justify-content: flex-end;
			margin-bottom: 15px;

			.button_group {
				display: flex;
				gap: 10px;
				width: 100%;
				justify-content: space-between;

				.right_button_group {
					display: flex;
					gap: 10px;
					align-items: center;
					.dropdown_toggle {
						display: flex;
						align-items: center;
						padding: 10px !important;
						height: 32px;

						span {
							display: none;
						}
					}
					button {
						border: none;
						background-color: #fff;
						border: 1px solid #5455660d;
						height: fit-content;
						padding: 10px;
						gap: 8px;
						border-radius: 6px;
						display: flex;
						align-items: center;
						font-family: "Inter", sans-serif;
						font-size: 0.75rem;
						letter-spacing: -0.025em;
						height: 32px;
						color: #303030;

						&.vendor {
							span {
								background: #fafafc;
								font-size: 10px;
								font-weight: 500;
								line-height: 10px;
								color: #404040;
								height: 18px;
								width: 18px;
								display: flex;
								align-items: center;
								justify-content: center;
								border-radius: 18px;
							}
						}

						&.three_dot svg {
							fill: #716e7a;
						}
					}
				}
			}
		}
		.summary {
			display: flex;
			gap: 16px;
			padding: 0px;
			border-radius: 6px;
			.budget_card {
				height: 96px;
				width: 100%;
				min-width: 219px;
				border-radius: 6px;
				padding: 12px 20px;
				display: flex;
				align-items: flex-start;
				justify-content: space-between;
				flex-direction: column;
				.top_row {
					display: flex;
					align-items: center;
					justify-content: space-between;
					width: 100%;
					h4 {
						font-family: "Inter", sans-serif;
						font-size: 16px;
						font-weight: 500;
						line-height: 22.4px;
						letter-spacing: -0.025em;
						text-align: left;
						color: #404040;
						margin-bottom: 0;
						span {
							font-size: 10px;
							color: #606060;
						}
					}
					svg {
						height: 14px;
						width: 14px;
						min-width: 14px;
						min-height: 14px;
					}
				}
				.title_info_row {
					display: flex;
					align-items: center;
					gap: 4px;
					.question {
						background-color: #888c8f;
						height: 8px;
						width: 8px;
						display: flex;
						align-items: center;
						justify-content: center;
						margin-top: 0px;
						padding: 0;
					}
				}
				h6 {
					font-family: "Inter", sans-serif;
					font-size: 11px;
					font-weight: 500;
					line-height: 13.75px;
					color: #404040;
					margin-bottom: 0;
				}
				&.first {
					background: #f2f2f2;
					border: 1px solid #5455660d;
					.question {
						background-color: #888c8f;
					}
				}

				&.second {
					border: 1px solid #dce0ec;
					background: #e7eeff;
					.question {
						background-color: #3c4c69;
					}
				}

				&.third {
					background: #defdee;
					border: 1px solid #d6f0e5;
					.question {
						background-color: #13503e;
					}
				}
				&.fourth {
					background: #fdf0d1;
					border: 1px solid #d6f0e5;
					.question {
						background-color: #665537;
					}
				}
				.prices {
					display: flex;
					flex-direction: column;
					gap: 10px;
					p {
						font-size: 0.625rem;
						letter-spacing: -0.025em;
						font-weight: 400;
						margin-bottom: 6px;
						svg {
							height: 10px;
							width: 10px;
						}
					}
					h6 {
						color: #000;
						font-weight: 500;
						font-size: 1rem;
						letter-spacing: -0.025em;
						line-height: 22.4px;
						span {
							font-weight: 400;
							font-size: 0.625rem;
							line-height: 12.1px;
							margin-left: 3px;
						}
					}
				}
				.info .zero_circle {
					border: 19px solid #fb3b52;
					height: 70px;
					width: 70px;
					border-radius: 50%;
					color: #000;
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}
		}
	}
	.budget_breakdown {
		background-color: #fafafc;
		border-radius: 6px;
		padding: 10px;
		display: flex;
		flex-direction: column;
		gap: 20px;
		margin-top: 40px;

		.budget_breakdown_title {
			font-family: "Inter", sans-serif;
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin: 10px 16px 0;
			.budget_breakdown_btns {
				display: flex;
				flex-direction: row;
				gap: 10px;
				button {
					border: none;
					background-color: #fff;
					border: 1px solid #5455660d;
					border-radius: 6px;
					color: #303030;
					padding: 4px;
					font-size: 0.75rem;
					font-weight: 500;
					letter-spacing: -0.025em;
					line-height: 16.8px;
					&.dropdown_toggle {
						padding: 4px 12px !important;
						height: 32px;
						display: flex;
						align-items: center;
						svg {
							height: 16px;
							width: 16px;
						}
						span {
							display: none;
						}
					}
					&.add_category {
						padding: 6px 8px;
						background-color: #031729;
						color: #fff;
						svg {
							height: 10px;
							width: 10px;
							stroke: #fff;
							path {
								fill: #fff;
								stroke: #fff;
							}
						}
					}
				}

				.list_style {
					background-color: #fff;
					border: 1px solid #5455660d;
					border-radius: 6px;
					display: flex;
					height: 32px;

					button {
						border: none;
						padding: 8px;
						border-radius: 4px;
						width: 32px;
						height: 32px;
						display: flex;
						align-items: center;
						justify-content: center;
						svg {
							height: 14px;
							width: 14px;
						}
					}
				}
			}
			h4 {
				font-weight: 500;
				font-size: 0.875rem;
				color: #031729;
				letter-spacing: -0.025em;
				line-height: 19.6px;
				margin: 0;
			}
		}
		.budget_breakdown_categories {
			display: flex;
			flex-direction: column;
			width: 100%;
			gap: 10px;
			position: relative;
			height: 55vh;
			margin-bottom: 30px;
			.category_names {
				display: flex;
				flex-direction: column;
				gap: 10px;
				height: 60vh;
				overflow-y: scroll;
			}
			.budget_category_card_wrapper {
				display: flex;
				flex-direction: row;
				gap: 10px;
				height: 400px;
				width: calc(100% - 245px);
				justify-content: flex-end;
				position: absolute;
				right: 0;
			}
			.budget_category_card {
				background-color: #fff;
				padding: 12px;
				height: fit-content;
				display: flex;
				flex-direction: column;
				animation-timing-function: ease-out;
				// animation-duration: 1s;
				cursor: pointer;
				gap: 10px;
				border-radius: 6px;
				width: 240px;
				height: 74px;
				&.hidden {
					display: none;
				}
				h6 {
					font-size: 0.8125rem;
					font-weight: 500;
					line-height: 18.2px;
					letter-spacing: -0.025em;
					text-align: left;
					margin: 0;
				}
				.icon_row {
					display: flex;
					align-items: center;
					justify-content: space-between;
					button {
						background-color: transparent;
						border: none;
						color: #303030;
						transform: roate(0deg);
						transition: transform ease-in 300ms;
						padding: 0;
					}
					svg {
						color: #888c8f;
						height: 12px;
						width: 12px;
					}
				}
				&:hover {
					h6 {
						color: #000;
					}
					svg {
						color: #000;
					}
				}
			}
			.budget_category_card.active {
				color: black;
				border: 1px solid #000;
				.icon_row {
					button {
						transform: rotate(-90deg);
					}
					svg {
						color: #000;
					}
				}
			}
			.budget_category_details {
				background-color: #fff;
				width: 100%;
				border: 1px solid #5455660d;
				border-radius: 6px;
				padding: 10px;
				position: absolute;
				right: 0;
				top: 0px;
				min-height: 78vh;
				.budget_category_header {
					display: flex;
					justify-content: space-between;
					background-color: #fafafc;
					border-radius: 6px;
					padding: 12px 16px;
					gap: 10px;
					border-bottom: 1px solid #5455660d;
					h5 {
						font-family: "Inter";
						font-size: 0.75rem;
						font-weight: 500;
						line-height: 16.8px;
						letter-spacing: -0.025em;
						text-align: left;
						margin: 0;
						display: flex;
						flex-direction: row;
						align-items: center;
						gap: 4px;
						color: #404040;
						span {
							font-size: 10px;
							// margin-top: 6px;
							&.amount {
								color: #fbbc05;
							}
						}
					}
					div:first-child {
						width: 350px;
						background-color: #f9f9f9bf;
					}

					div {
						border: 1px solid #5455660d;
						border-radius: 6px;
						background-color: #fff;
						padding: 4px 10px;
						height: 60px;
						width: 100%;
					}
					div {
						p {
							color: #888c8f;
							font-size: 12px;
							margin-bottom: 14px;
						}
						h5 {
							font-size: 14px;
							color: #404040;
						}
					}
				}
				.categories_table_wrapper {
					height: 100%;
					overflow-y: scroll;
					.categories {
						background: #f9f9f9bf;
						padding: 0px;
						border: 1px solid #5455660d;
						border-top: none;
						border-radius: 6px;
						width: 100%;
						border-radius: 6px;
						margin-top: 30px;
						border-top: 1px solid #5455660d;
						td {
							border-right: 1px solid #5455660d;
							min-width: unset;
							display: flex;
							align-items: center;
							width: 100%;
							padding: 0px 8px;
							width: 14%;
							margin: 0;
							height: 48px;
							color: #031729;
						}

						td.cost_item_price .input_wrapper {
							display: flex;
							align-items: center;
							border-radius: 4px;
							padding: 0;
							justify-content: space-between;
							background-color: #fff;
							height: 26px;
							border: 1px solid #5455660d;
							width: 80%;
							.deliverables {
								width: 100%;
								max-width: 100%;
								border: none;
								color: #404040;
								background-color: transparent;
								&::placeholder {
									color: #404040;
									font-size: 12px;
								}
							}
							.amount {
								width: 30%;
								max-width: 30%;
								background-color: #fbfbfb;
								border-radius: 2px;
								padding: 2px;
								border: none;
								height: 13px;
								font-weight: 400;
								letter-spacing: -0.025em;
								color: #e72e50;
								margin-right: 5px;
								text-align: center;
								font-size: 9px;
								&::placeholder {
									color: #e72e50;
									color: 9px;
								}
							}
						}

						td:first-child {
							width: 22%;
							min-width: 22%;
						}

						td:last-child {
							border-right: none;
						}

						.category .cost_item {
							min-width: 22%;
						}
						td.cost_btn_group {
							width: 60px;
							min-width: 60px;
						}

						.category {
							padding: unset;
							position: relative;
							&.active {
								height: 280px;
								align-items: flex-start;
								background-color: #f9f9f9bf;
								@media (max-width: 768px) {
									.cost_btn_group button:first-child svg {
										transform: unset;
									}
								}
							}
							.more_details {
								position: absolute;
								width: 100%;
								bottom: 20px;
								align-items: flex-end;
								justify-content: flex-start;
								gap: 20px;
								padding: 0px 10px;

								.card_group {
									display: flex;
									align-items: flex-start;
									justify-content: flex-start;
									gap: 20px;
								}
								.column_1 {
									display: flex;
									flex-direction: column;
									gap: 0px;
									width: 320px;
									background-color: white;
									padding: 8px 12px;
									border-radius: 6px;
									.cost_item {
										display: flex;
										align-items: center;
										gap: 40px;
										background-color: #fff;
										padding: 4px 8px;
										border-radius: 6px;
										width: 100%;
										justify-content: flex-start;
										align-items: center;
										p {
											text-wrap: nowrap;
											white-space: nowrap;
											padding: 0;
											margin: 0;
											color: #888c8f;
											font-size: 11px;
											max-width: 50px;
											min-width: 50px;
										}
										.input_wrapper {
											border: 1px solid #5455660d;
											height: 32px;
											padding: 0px 6px;
											min-width: 120px;
											max-width: 120px;
											border-radius: 6px;
											display: flex;
											align-items: center;
											justify-content: space-between;
											span {
												font-size: 8px;
												color: #888c8f;
											}
											p {
												width: 70%;
												min-width: 70%;
												border: none;
												padding: 0;
												font-size: #404040;
												font-size: 11px;
											}
											input {
												border: none;
												padding: 0px;
												width: 80%;
												height: 26px;
												border-radius: 0;
												background-color: white;
												color: #404040;
												font-size: 11px;
												&:focus {
													background-color: white;
												}
											}
										}
										input {
											border: 1px solid #5455660d;
											width: 100px;
											border-radius: 6px;
											padding: 6px;
											height: 32px;
											font-size: #404040;
											font-size: 11px;
										}
										.select_date_input_view {
											border: 1px solid #5455660d;
											padding: 4px;
											border-radius: 6px;
											height: 32px;
											width: 120px;
											min-width: 120px;
											text-transform: capitalize;
											input {
												width: 100%;
												border: none;
												margin-top: 0px;
												font-size: 11px;
												margin-bottom: 0px;
												padding-bottom: 0;
												padding-top: 0;
												padding: 0 3px;
												color: #404040;
												height: 20px;
												&::placeholder {
													color: #000;
													font-size: 12px;
												}
												&:focus {
													box-shadow: none;
												}
											}
										}

										.dropdown .dropdown_toggle {
											font-size: 12px;
											height: 32px;
											border-radius: 6px;
											padding: 0px 6px !important;
											font-size: 11px;
											border: 1px solid #5455660d;
											color: #404040;
											svg {
												height: 10px;
												width: 10px;
											}
										}
										.dropdown-menu.show {
											display: flex;
											flex-direction: column;
											font-size: 12px;
										}
									}
								}
								.column_2 {
									min-width: 240px;
									padding: 12px;
									height: 180px;
									border: 1px solid #5455660d;
									border-radius: 6px;
									background-color: white;
									.vendor_card_details {
										display: flex;
										flex-direction: column;
										gap: 10px;
										.vendor_initials {
											width: 16px;
											height: 16px;
											min-height: 16px;
											min-width: 16px;
											display: flex;
											align-items: center;
											justify-content: center;
											&.active {
												display: flex;
												font-weight: 500;
												align-items: center;
												justify-content: center;
												color: #fff;
												height: 16px;
												width: 16px;
												border-radius: 24px;
												font-size: 10px;
												font-weight: 500;
												gap: 0px;
												letter-spacing: -1px;
											}
										}
										.name {
											display: flex;
											flex-direction: column;
											align-items: flex-start;
											justify-content: flex-start;
											text-align: left;
										}
									}
								}

								.vendor_row_1 {
									width: 100%;
									justify-content: space-between;
									display: flex;
									align-items: center;
									background: #fbfbfb;
									border-radius: 4px;
									height: 42px;
									border: none;
									.name {
										display: flex;
										flex-direction: column;
										align-items: flex-start !important;
										justify-content: flex-start;
										min-width: 80%;
										margin-left: 5px;
										h5 {
											font-size: 12px;
											font-weight: 500;
											margin: 0;
											padding: 0;
										}
										p {
											font-size: 10px;
											margin: 0;
											font-weight: 400;
											color: #888c8f;
										}
									}
									svg {
										transform: rotate(90deg);
										height: 12px;
										width: 12px;
									}
									.vendor_initials {
										.icon_wrapper {
											background-color: #fff;
											width: 16px;
											height: 16px;
											min-height: 16px;
											min-width: 16px;
											display: flex;
											align-items: center;
											justify-content: center;
											border-radius: 50%;
											svg {
												transform: unset;
												height: 10px;
												width: 10px;
												stroke: black;
											}
										}
									}
								}

								.vendor_row_2 {
									width: 215px;
									min-width: 0px;
									margin-left: 26px;
									justify-content: space-between;
									display: flex;
									flex-direction: column;
									border-radius: 6px;
									gap: 10px;
									.vendor_contacts {
										display: flex;
										flex-direction: column;
										font-size: 12px;
										min-width: 55%;
										align-items: flex-start;
										a:first-child {
											color: #4285f4;
										}
										a:last-child {
											color: #888c8f;
											font-size: 10px;
											font-weight: 400;
										}
									}

									.bank_details {
										display: flex;
										flex-direction: column;
										align-items: flex-start;
										h6 {
											font-weight: 500;
											font-size: 12px;
											margin: 0;
											text-wrap: nowrap;
											white-space: nowrap;
										}

										p {
											margin: 0;
											padding: 0;
											color: #888c8f;
											font-size: 10px;
											font-weight: 400;
										}
									}
								}
								@media (max-width: 560px) {
									align-items: flex-start;
									top: 48px;
									min-height: 50vh;
									overflow-y: scroll;
									width: 100% !important;
									min-width: 100%;
									flex-direction: column;
									height: 60vh;
									z-index: 10000;
									background-color: white;
									left: 0;
									padding-top: 10px;
									.card_group {
										flex-direction: column;
									}
									.column_1 {
										padding: 0;
									}
								}
							}
							.cost_btn_group button:last-child {
								background: white;
								box-shadow: 0px 1px 10px 0px #0000000d;
								height: 24px;
								width: 24px;
								display: flex;
								align-items: center;
								border-radius: 6px;
							}
							.cost_btn_group button {
								border: none;
							}
							.cost_btn_group button:first-child {
								background-color: transparent;
								@media (max-width: 768px) {
									svg {
										transform: rotate(270deg);
									}
								}
							}
						}

						td:nth-child(5) {
							min-width: 20%;
						}
						.category_header {
							display: flex;
							align-items: center;
							padding: 0px 16px;
							height: 48px;
							background-color: #fff;
							padding: 0px;
							td:last-child {
								width: 60px;
							}
							td h6 {
								margin: 0;
								text-align: left;
								color: #000;
							}
							td p {
								color: gray;
								font-size: 10px;
								margin: 0;
								margin-top: 4px;
							}
							td:nth-child(2) {
								min-width: 20%;
							}
							td:nth-child(5) {
								min-width: 20%;
							}
							.cost {
								flex-direction: column;
								margin: 0;
								justify-content: center;
								text-align: left;
								align-items: flex-start;
							}
							h6 {
								font-size: 0.75rem;
								font-weight: 500;
								line-height: 12.1px;
							}
							&:hover {
								background: #f9f9f9bf;
							}
						}
						.category {
							display: flex;
							flex-direction: row;
							align-items: center;
							font-size: 0.75rem;
							font-weight: 500;
							height: 48px;
							line-height: 16.8px;
							letter-spacing: -0.025em;
							background-color: #fff;
							padding: 0px;
							border-top: 1px solid #5455660d;
							text-align: left;
							.cost_item input,
							.cost_item_price input {
								width: 100%;
								border-radius: 6px;
								padding: 6px;
								height: 26px;
								border: none;
								background-color: transparent;
								&:focus {
									border: 1px solid #5455660d;
								}
							}
							.cost_item {
								width: 20%;

								.btn.calender_icon_btn {
									display: none;
								}
							}
							td:nth-child(2) {
								width: 20%;
							}

							.cost_item_price {
								display: flex;
								gap: 40px;
								p {
									width: 100px;
									margin: 0;
								}
							}
							.cost_btn_group {
								gap: 5px;
								svg {
									height: 16px;
									width: 16px;
									color: #9395aa;
								}
							}

							.cost_item {
								margin: 0;
								// div {
								// 	display: flex;
								// 	flex-direction: row;
								// }
							}
							&:hover {
								background: #f9f9f9bf;
							}
						}
						.add_cost_row {
							display: flex;
							align-items: center;
							justify-content: flex-end;
							padding: 10px 16px;
							background-color: #fff;
							border-top: 1px solid #5455660d;
							td {
								min-width: unset !important;
								width: unset !important;
								height: unset;
							}
							.add_cost {
								background: #fafafc;
								border: none;
								color: #303030;
								font-size: 0.75rem;
								font-weight: 500;
								line-height: 16.8px;
								letter-spacing: -0.025em;
								text-align: center;
								display: flex;
								align-items: center;
								padding: 6px 8px;
								gap: 5px;
								border-radius: 6px;
								min-width: unset;
								height: 30px;
								svg {
									color: #303030;
									height: 10px;
									width: 10px;
								}
							}
							&:hover {
								background: #f9f9f9bf;
							}
						}
					}
				}
			}
		}

		&.grid {
			div.budget_breakdown_categories {
				display: grid;
				grid-template-columns: repeat(2, 1fr);

				.budget_category_details {
					display: none;
				}
				.budget_category_card {
					width: 100%;
				}
			}
		}
	}

	.dropdown {
		.dropdown_toggle {
			display: flex;
			align-items: center;
		}
		div.dropdown-menu.show {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			padding: 6px;
			button {
				border: none;
				width: 100%;
				text-align: left;
			}
		}
	}
}

.dashboard_modal.show.add_budget_category_modal {
	.modal-dialog .modal-header {
		.modal-title {
			font-size: 14px;
			line-height: 16px;
		}
		p {
			font-size: 10px;
		}
	}
}

@media (max-width: 768px) {
	.budget_tracker {
		.dropdown div.dropdown-menu.show {
			z-index: 1000;
			min-width: 160px;
			.dropdown-item {
				padding: 4px 10px;
			}
		}
		.event_summary_overview {
			margin: 0;

			.summary {
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				.budget_card {
					width: 100%;
					min-width: 100%;
					padding: 12px;
					.top_row h4 {
						font-size: 12px;
						display: flex;
					}
					.title_info_row {
						font-size: 11px;
					}
					h6 {
						font-size: 10px;
					}
				}
			}
			div.event_budget_row {
				.button_group {
					width: 100%;
					justify-content: space-between;
					.merged_buttons {
						button.filter {
							border: 1px solid #5455660d;
							border-radius: 6px;
						}
					}

					div.filter_group {
						display: none;
					}
				}
			}
		}
		.budget_breakdown {
			margin: 40px 0;

			.budget_breakdown_title {
				margin: 0;
				.budget_breakdown_btns button.add_category {
					padding: 0;
					height: 32px;
					width: 32px;
					span {
						display: none;
					}
					svg {
						font-size: 12px;
						height: 12px;
						width: 12px;
					}
				}
			}

			.budget_breakdown_categories {
				flex-direction: column;
				.budget_category_card_wrapper {
					position: relative;
					width: 100%;
					height: unset;
				}
				.category_names {
					display: flex;
					flex-direction: row;
					overflow-x: scroll;
					height: fit-content;
				}
				.budget_category_card {
					width: fit-content;
					position: relative;
					z-index: 100;
					h6 {
						text-wrap: nowrap;
						white-space: nowrap;
					}
				}
				.budget_category_details {
					z-index: 1;
					.budget_category_header {
						position: absolute;
						padding: 0;
						bottom: 40px;
						width: 95%;
						background-color: #fff;
						border-bottom: 0;
						z-index: 200;
					}
					.categories_table_wrapper {
						max-height: 400px;
						.categories .category_header {
							position: relative;
						}
						td:first-child {
							width: 100px !important;
							min-width: 100px !important;
							position: sticky;
							left: 0;
							z-index: 10;
							background-color: #f9f9f9;
						}
						.categories {
							margin-top: 0px;
							td {
								width: 140px !important;
								min-width: 140px !important;
								.input_wrapper {
									width: 100% !important;
								}
								&.add_cost {
									width: unset !important;
									min-width: unset !important;
									padding: 0 !important;
									height: unset;
								}
							}

							.category_header {
								background-color: #f9f9f9;
								td {
									background-color: #f9f9f9;
								}
								td:first-child {
									width: 100px !important;
									min-width: 100px !important;
									box-shadow: 4px 0px 10px 0px #0000000d;
								}
								td.cost_btn_group {
									width: fit-content !important;
									min-width: fit-content !important;
								}
							}
							.category {
								td {
									left: 0;
								}
								td:first-child {
									position: sticky;
									z-index: 100;
									left: 0;
									width: 100px !important;
									min-width: 100px !important;
									box-shadow: 4px 0px 10px 0px #0000000d;
									background-color: white;
								}
								td.cost_btn_group {
									width: fit-content !important;
									min-width: fit-content !important;
								}
							}
							.add_cost_row {
								justify-content: flex-start;
							}
						}
					}
					.budget_category_header div:first-child {
						display: none;
					}
				}
			}
		}
	}
}
