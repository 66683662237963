@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400,500&family=Manrope:wght@400,500,600,700&display=swap");
@import "./variables.scss";

section.vendor_view_wrapper {
	border-radius: 16px;
	display: flex;
	margin: 0px;
	justify-content: center;
	height: 100%;
	font-family: "Inter", sans-serif;
	min-height: 100%;
	height: calc(100vh - 100px);
	align-items: center;
	flex-direction: column;
	justify-content: flex-start;
	gap: 10px;
	button {
		background-color: transparent;
		display: flex;
		border: 1px solid #5455660d;
		align-items: center;
		font-size: 12px;

		gap: 5px;
		svg,
		span svg {
			height: 14px;
			width: 14px;
		}
	}

	.vendor_view {
		width: 100%;
		height: 100%;
		justify-content: flex-start;
		align-items: center;
		display: flex;
		flex-direction: column;
		background-color: #fafafc;
		border-radius: 6px;
		.top_bar {
			padding: 12px 16px;
			display: flex;
			justify-content: space-between;
			width: 100%;
			align-items: center;
			margin: 0px;
			height: 56px;
			min-height: 56px;
			h4 {
				font-size: 14px;
				color: #031729;
				font-weight: 500;
				line-height: 19.6px;
				letter-spacing: -0.025em;
				text-align: left;
				margin: 0;
			}
		}
		.vendors_table_view {
			background-color: #fff;
			height: 100%;
			width: 99%;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			padding: 0;
			border-radius: 6px;
			margin-bottom: 10px;
			border: 1px solid #5455660d;
			flex-direction: column;
			.table_wrapper {
				// padding: 0px 10px;
				width: 100%;
				table {
					width: 100%;
					thead {
						height: 58px;
						color: #031729;
						font-size: 12px;
						line-height: 18px;
						font-weight: 500;
						letter-spacing: -0.025em;
						text-align: left;
						background-color: #fff;

						tr {
							padding: 2px 0px;
							border-bottom: 1px solid #5455660d;
							height: 54px;
							td:first-child {
								padding-left: 15px;
								input {
									margin-top: 5px;
								}
							}
						}
					}
					tbody {
						tr {
							border-bottom: 1px solid #5455660d;
							height: 54px;
							background-color: #fff;

							&.even {
								background-color: #fbfbfb;
							}
							&:hover {
								background-color: #febfbd;
							}
							td {
								font-size: 13px;
								font-weight: 500;
								line-height: 18.2px;
								letter-spacing: -0.025em;
								color: #404040;

								&:first-child {
									padding-left: 15px;
									input {
										margin-top: 5px;
									}
								}

								.dropdown {
									width: 34px;
									height: 24px;
									button.dropdown_toggle {
										height: 24px;
										width: 100%;
										padding: 0px !important;
										display: flex;
										align-items: center;
										justify-content: center;
									}
								}

								&:nth-child(2) {
									div {
										border: 1px solid #5455660d;
										width: min-content;
										border-radius: 24px;
										text-wrap: nowrap;
										white-space: nowrap;
										padding: 8px 12px 8px 8px;
										display: flex;
										align-items: center;
										gap: 6px;
										span {
											height: 16px;
											width: 16px;
											display: flex;
											background-color: red;
											color: white;
											border-radius: 24px;
											font-size: 10px;
											justify-content: center;
											align-items: center;
										}
									}
								}
								&:nth-child(3),
								&:nth-child(5) {
									color: #888c8f;
								}
								&:nth-child(4) {
									color: #4285f4;
								}

								&:nth-child(6) {
									h6 {
										color: #031729;
										font-size: 12px;
										font-weight: 500;
										line-height: 18px;
										letter-spacing: -0.025em;
										text-align: left;
										margin: 0;
									}
									p {
										color: #888c8f;
										font-size: 10px;
										font-weight: 400;
										line-height: 12px;
										letter-spacing: -0.025em;
										text-align: left;
									}
								}
							}
						}

						tr:last-child {
							border-bottom: none;
						}
					}
				}
			}
		}

		.right_button_group {
			display: flex;
			align-items: center;
			gap: 6px;
			button svg {
				height: 14px;
				width: 14px;
			}
			.share_plan {
				background-color: #031729;
				border-radius: 6px;
				height: 32px;
				padding: 0px 10px;
				color: white;
			}
			button {
				border: 1px solid #5455660d;
				height: 32px;
				padding: 0px 9px;
				background-color: #fff;
				border-radius: 6px;
			}
		}
	}

	.dropdown {
		box-shadow: 0px 4px 20px 0px #0000000d;

		button {
			padding: 0px 12px 0px 9px !important;
			span svg {
				height: 10px;
				width: 10px;
			}
			&.dropdown_toggle {
				span {
					display: none;
				}
			}
		}
		.dropdown-menu.show {
			box-shadow: 0px 4.5px 15.75px 1.13px #3030301a;
			border: 0.5px solid #dddfe380;
			.dropdown-item svg {
				height: 14px;
				width: 14px;
			}
		}
	}

	.card_wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
		width: 100%;
		.no_vendor_card {
			box-shadow: 0px 4px 20px 0px #0000000d;
			border: 3px solid #ffffff;
			width: 480px;
			border: 0.5px solid $base_red;
			padding: 25px 0;
			border-radius: 6px;
			background-color: white;
			.icon_wrapper {
				border: 1px solid #5455660d;
				border-radius: 2px;
				margin: auto;
				height: 40px;
				width: 40px;
				display: flex;
				align-items: center;
				justify-content: center;
				svg {
					height: 20px;
					width: 20px;
					stroke: $base_red;
				}
			}
			.text {
				border-radius: 8px;
				padding: 16px 12px;
				h4 {
					font-size: 1rem;
					text-align: center;
					color: #000;
					font-family: "Inter", sans-serif;
					font-size: 0.875rem;
					font-weight: 500;
					line-height: 36px;
					margin-bottom: 0;
					letter-spacing: -0.02em;
				}
				p {
					text-align: center;
					font-size: 1rem;
					font-size: 0.75rem;
					font-weight: 400;
					line-height: 16.8px;
					letter-spacing: -0.025em;
					text-align: center;
					color: #9395aa;
					span {
						font-weight: bold;
						color: black;
					}
				}
			}

			button {
				height: 36px;
				padding: 12px 12px 12px 10px;
				gap: 8px;
				border-radius: 6px;
				font-family: "Manrope", sans-serif;
				font-size: 0.75rem;
				font-weight: 600;
				line-height: 14px;
				display: flex;
				align-items: center;
				text-align: center;
				color: #fff;
				margin: auto;
				background-color: #031729;
			}
		}
	}
	@media (max-width: 560px) {
		.no_floor_plan_card {
			width: 250px;
			img {
				height: 150px;
				width: 150px;
			}
			.floor_plan_text {
				h4 {
					font-size: 1rem;
				}
			}
			.button_group {
				flex-direction: column;
				button {
					width: fit-content;
					margin: auto;
				}
			}
		}
	}
}

@media (max-width: 768px) {
	section.vendor_view_wrapper .vendor_view .vendors_table_view {
		overflow-x: scroll;
		.table_wrapper table {
			thead {
				tr {
					width: 200px !important;
					overflow-x: scroll;
					max-width: 200px;
					td:first-child,
					td:nth-child(2) {
						position: sticky;
						// background-color: #f9f9f9;
					}
					td {
						min-width: 150px;
						background-color: #fff;
					}
					td:first-child {
						min-width: 50px;
						left: 0;
					}
					td:nth-child(2) {
						left: 50px;
					}
					td:last-child {
						min-width: 70px;
					}
				}
			}
			tbody {
				tr {
					td {
						min-width: 150px;
					}
					td:first-child {
						min-width: 50px;
						position: sticky;
						left: 0;
						// background-color: #f9f9f9;
					}
					td:last-child {
						min-width: 70px;
					}
					&.even {
						td {
							background-color: #fbfbfb;
						}
					}
					&.odd {
						td {
							background-color: #fff;
						}
					}
				}
				td:nth-child(2) {
					position: sticky;
					left: 50px;
				}
			}
		}
	}
}
