@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400,500&family=Manrope:wght@400,500,600,700&display=swap");
@import "./variables.scss";

.react-datepicker__time-container
	.react-datepicker__time
	.react-datepicker__time-box
	ul.react-datepicker__time-list
	li.react-datepicker__time-list-item--selected {
	background-color: #ff0000;
}

.display_status {
	margin-left: 5px;
	.icon {
		display: flex;
		align-items: center;
		gap: 4px;
		flex-direction: row;
		font-weight: 400;
		font-size: 12px;
		span {
			height: 12px;
			width: 12px;
			border-radius: 2px;
			display: flex;
			align-items: center;
			background-color: #ff0000;
			justify-content: center;
			color: white;
			margin-right: 2px;
		}
		svg {
			height: 12px;
			width: 12px;
		}
		&.urgent {
			color: #ff0000;
		}
		&.medium {
			color: #4285f4;
			svg {
				stroke: #4285f4;
				.line_3 {
					stroke: #b0cbf9;
				}
			}
		}
		&.high {
			color: #fbbc05;
			svg {
				stroke: #fbbc05;
			}
		}
		&.low {
			color: #888c8f;
			svg {
				stroke: #888c8f;
				.line_2,
				.line_3 {
					stroke: #c0c1c3;
				}
			}
		}
	}
}

.dashboard_modal.show.add_checklist_modal .modal-dialog .modal-content .modal-body {
	gap: 0px;
	padding: 0;
}

.dashboard_modal.show.add_event_day_task_modal .modal-dialog {
	width: 90%;
	min-width: 90%;

	.add_checklist_modal .checklist_group .checklist_row {
		.dropdown_wrapper .custom.dropdown .dropdown_toggle {
			font-size: 12px;
			text-wrap: nowrap;
			white-space: nowrap;
		}
		.custom_input_view,
		.select_date_input_view,
		.dropdown_wrapper {
			max-width: 120px;
			min-width: 120px;
			width: 120px;
			@media (max-width: 560px) {
				max-width: 100%;
				min-width: 100%;
				width: 100%;
			}
		}
	}

	.add_checklist_modal .checklist_group .checklist_row {
		.dropdown_wrapper {
			.timepicker {
				margin: 10px 15px;
				display: flex;
				align-items: center;
				justify-content: center;
				label {
					padding: 0;
				}
				.custom_input_view {
					max-width: 60px;
					min-width: 60px;
				}
			}
			&.select_duration {
				.custom.dropdown .dropdown-menu.show {
					min-width: 100px;
					margin-left: -8px !important;
					margin-top: 10px !important;
				}
			}
		}
	}
	.add_checklist_modal .checklist_group .checklist_row .task_element.custom_input_view {
		min-width: 280px;
	}
	.vendor_input {
		min-width: 170px;
		position: relative;
		@media (max-width: 768px) {
			min-width: 40%;
		}
		@media (max-width: 560px) {
			min-width: 100%;
		}
		span {
			display: flex;
			align-items: center;
			color: white;
			background-color: #e845ba;
			font-size: 9px;
			height: 15px;
			width: 15px;
			border-radius: 50%;
			justify-content: center;
			// position: relative;
			// left: 2px;
			// z-index: 10;
			// bottom: -1.5px;
		}
		input {
			padding-left: 35px;
			font-size: 11px;
		}
		.vendor_input_wrapper {
			border-radius: 8px;
			height: 54px;
			border: 1px solid #30303026;
			padding-bottom: 0px;
			font-size: 12px;
			font-weight: 500;
			letter-spacing: -0.025em;
			padding-top: 15px;
			padding: 4px 15px;
			label {
				font-size: 10px;
				color: #404040;
				left: 14px;
				top: 3px;
				margin-bottom: 0;
				font-weight: 400;
			}
		}
	}
	@media (max-width: 560px) {
		min-width: 100%;
	}
}

.add_checklist_modal {
	padding: 30px 25px 15px;
	width: 100%;
	.checklist_group {
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 15px;
		align-items: center;
		.checklist_row {
			width: 100%;
			display: flex;
			align-items: center;
			gap: 10px;
			.select_date_input_view {
				width: 200px;
				border: 1px solid #30303026;
				border-radius: 8px;
				height: 54px;
				label {
					font-size: 10px;
					padding: 0px 10px;
					margin-bottom: 10px !important;
					margin-top: 4px;
				}
				.select_date_input button {
					display: none;
				}
				input {
					border: none;
					font-size: 12px;
					height: 20px;
				}
				input::placeholder {
					font-size: 12px;
				}
				input:focus {
					outline: none;
					box-shadow: none;
				}
				.react-datepicker-wrapper {
					margin-top: -10px;
				}
			}
			.form_input_element {
				width: 100%;
			}

			.dropdown_wrapper {
				border: 1px solid #30303026;
				height: 54px;
				min-width: 140px;
				border-radius: 8px;
				label {
					font-size: 10px;
					padding: 0px 10px;
					color: #404040;
				}
				.custom.dropdown {
					display: flex;
					border-radius: 8px;
					padding-left: 5px;
					margin-top: -6px;
					.dropdown_toggle {
						width: 100%;
						display: flex;
						align-items: center;
						padding-right: 20px;
						&:focus {
							outline: none;
							box-shadow: none;
						}
						.display_status {
							display: flex;
							text-wrap: nowrap;
							white-space: nowrap;
							span svg {
								height: 14px;
								width: 14px;
							}
						}
						span svg {
							height: 10px;
							width: 10px;
							margin-right: 10px;
						}
					}
				}
			}
			.task_element {
				width: 100%;
			}
			.action_button_group {
				background-color: #fbfbfb;
				height: 52px;
				display: flex;
				align-items: center;
				width: 32px;
				min-width: 32px;
				justify-content: center;
				border-radius: 4px;
				button {
					background-color: transparent;
					border: none;
					height: 24px;
					width: 24px;
					background-color: white;
					box-shadow: 0px 1px 10px 0px #0000000d;
					padding: 0px 6px;
					border-radius: 4px;
					svg {
						height: 12px;
						width: 12px;
						stroke: #888c8f;
					}
				}
			}
		}
	}
	.add_new {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		margin-top: 100px;
		button {
			background-color: #fafafc;
			padding: 6px 8px;
			border-radius: 6px;
			height: 30px;
			font-size: 12px;
			font-weight: 500;
			color: #404040;
			border: none;
			display: flex;
			align-items: center;
			gap: 5px;
			svg {
				height: 14px;
				width: 14px;
			}
		}
	}

	.modal_footer {
		border-top: 1px solid #5455660d;
		width: 100%;
		padding: 10px 25px;
		.button_group {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			gap: 40px;
			button {
				height: 36px;
				font-family: "Inter", sans-serif;
				font-size: 13px;
				line-height: 18.2px;
				letter-spacing: -0.025em;
				border-radius: 6px;
				padding: 0px 12px;
				&.cancel_btn {
					font-weight: 400;
					background-color: #fff;
					border: 1px solid #5455660d;
					color: #404040;
				}
				&.create_btn {
					font-weight: 600;
					color: #fff;
					background-color: #e72e50;
					padding: 0px 24px;
				}
			}
		}
	}
}

.todo_body {
	background-color: white;
	padding: 24px;
	display: flex;
	flex-direction: column;
	gap: 10px;
	min-height: 500px;
	border-radius: 6px;
	.not_available {
		font-size: 12px;
		color: #000;
	}
	.checklist_item {
		background-color: #fff;
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		align-items: center;
		gap: 20px;
		&.checklist_completed {
			input {
				accent-color: #34a853;
			}
			.checklist_text_view {
				h6,
				.period span {
					color: #34a853;
				}
				.period svg path {
					stroke: #34a853;
				}

				h6 {
					max-width: 200px;
				}
			}
		}
		.checklist_text_view {
			.checklist_actions {
				button:first-child {
					margin-top: -10px;
				}
			}
		}
		.period {
			.display_status {
				margin-left: 5px;
				.icon {
					display: flex;
					align-items: center;
					gap: 4px;
					flex-direction: row;
					font-weight: 400;
					font-size: 12px;
					span {
						height: 12px;
						width: 12px;
						border-radius: 2px;
						display: flex;
						align-items: center;
						background-color: #ff0000;
						justify-content: center;
						color: white;
						margin-right: 2px;
					}
					svg {
						height: 12px;
						width: 12px;
					}
					&.urgent {
						color: #ff0000;
					}
					&.medium {
						color: #4285f4;
						svg {
							stroke: #4285f4;
							.line_3 {
								stroke: #b0cbf9;
							}
						}
					}
					&.high {
						color: #fbbc05;
						svg {
							stroke: #fbbc05;
						}
					}
					&.low {
						color: #888c8f;
						svg {
							stroke: #888c8f;
							.line_2,
							.line_3 {
								stroke: #c0c1c3;
							}
						}
					}
				}
			}
			.no_priority {
				font-family: "Inter", sans-serif;
				font-size: 12px;
				font-weight: 400;
				line-height: 18px;
				letter-spacing: -0.025em;
				margin-left: 5px;
			}
		}
		img {
			height: 32px;
			width: 32px;
			border-radius: 50%;
			object-fit: cover;
			object-position: bottom;
		}
		div {
			display: flex;
			flex-direction: column;
			gap: 20px;
			h6 {
				font-family: "Inter", sans-serif;
				font-size: 13px;
				font-weight: 500;
				line-height: 18.2px;
				letter-spacing: -0.025em;
				text-align: left;
				color: #102126;
				margin-bottom: 0;
				max-width: 500px;
				text-overflow: ellipsis;
				text-wrap: nowrap;
				white-space: nowrap;
				overflow: hidden;
			}
			span {
				background: #fbfbfb;
				padding: 4px 6px;
				border-radius: 3px;
				font-family: "Inter", sans-serif;
				font-size: 10px;
				font-weight: 500;
				line-height: 12.1px;
				text-align: left;
				color: #34a853;
			}
		}
		time {
			background: #f7f7f7;
			padding: 4px 6px;
			border-radius: 4px;
			font-size: 12px;
			font-weight: 400;
			line-height: 16.8px;
			letter-spacing: -0.025em;
			text-align: left;
			color: #ff0000;
			display: flex;
			align-items: center;
			gap: 5px;
			span {
				height: 10px;
				width: 10px;
				border-radius: 50%;
				display: flex;
				background-color: #ff0000;
			}
		}
		.checklist_wrapper {
			display: flex;
			align-items: center;
			width: 100%;
			flex-direction: row;
			justify-content: space-between;
			background-color: #fafafc;
			padding: 8px 12px;
			border-radius: 6px;
			height: 54px;
		}
		.checklist_text_view {
			flex-direction: column;
			gap: 2px;
			.period {
				display: flex;
				flex-direction: row;
				align-items: center;
				gap: 0px;
				span {
					color: #000;
					background-color: transparent;
				}
				hr {
					width: 1px;
					height: 12px;
					background-color: #e9e9e9;
					margin: 0 5px;
				}
			}
		}
		.checklist_actions {
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: 10px;
			button {
				border: none;
				background-color: transparent;
				fill: black;
				height: 24px;
				width: 24px;
				display: flex;
				align-items: center;
				svg {
					stroke: #888c8f;
					height: 14px;
					min-height: 14px;
					width: 14px;
					min-width: 14px;
				}
				&:nth-child(2) {
					background-color: white;
					display: flex;
					align-items: center;
					margin: auto;
					height: 24px;
					width: 24px;
					border-radius: 6px;
					box-shadow: 0px 1px 10px 0px #0000000d;
				}
				&:first-child,
				&:last-child {
					margin-top: -3px;
				}
			}
		}
	}
}

.checklist_page {
	font-family: "Inter", sans-serif;
	display: flex;
	flex-direction: column;
	gap: 10px;

	.title_row {
		display: flex;
		align-items: center;
		justify-content: space-between;
		background-color: #fafafc;
		border-radius: 6px;
		padding: 12px 24px;
		h4 {
			font-size: 14px;
			font-weight: 500;
			line-height: 19.6px;
			letter-spacing: -0.025em;
			text-align: center;
			color: #031729;
		}
		svg {
			height: 14px;
			width: 14px;
		}
		.button_row {
			display: flex;
			align-items: center;
			gap: 12px;
			.dropdown_toggle {
				background-color: #fbfbfb;
				font-weight: 500;
				line-height: 16.8px;
				letter-spacing: -0.025em;
				text-align: left;
				color: #303030;
				display: flex;
				align-items: center;
				border: 1px solid #5455660d;
				gap: 5px;
				font-size: 12px;
				padding: 6px 8px !important;
				border-radius: 6px;
				height: 32px;
				button svg {
					height: 12px;
					width: 12px;
					stroke: #000;
				}
				hr {
					width: 1px;
					height: 16px;
					background-color: #e9e9e9;
					margin: 0px 5px;
				}
				span svg {
					height: 10px;
					width: 10px;
					color: #404040;
					stroke-width: 0.2;
				}
				svg {
					height: 14px;
					width: 14px;
				}
			}
			.custom.dropdown .dropdown-menu.show {
				flex-direction: column;
			}
			button {
				border: none;
				background: white;
				font-size: 12px;
				font-weight: 500;
				line-height: 16.8px;
				letter-spacing: -0.025em;
				text-align: left;
				height: 32px;
				border-radius: 6px;
				padding: 10px;
				svg {
					height: 14px;
					width: 14px;
				}
			}
			button:last-child {
				background-color: transparent;
				border: 0.67px solid #5455660d;
				display: flex;
				align-items: center;
				border-radius: 4px;
				svg {
					height: 12px;
					width: 12px;
				}
			}
			button.add_task {
				background-color: #031729;
				display: flex;
				align-items: center;
				gap: 4px;
				color: white;
				border: 1px solid #5455660d;
			}
		}
	}

	.checklist_cards {
		background-color: #fafafc;
		border-radius: 6px;
		padding: 6px;
		display: flex;
		align-items: center;
		gap: 10px;
		.card {
			background-color: white;
			width: 100%;
			box-shadow: 0px 1px 2px 0px #0000000d;
			padding: 12px 20px;
			border-radius: 6px;
			h4 {
				color: #000;
				font-family: "Manrope", sans-serif;
				font-size: 24px;
				font-weight: 600;
				line-height: 32.78px;
				letter-spacing: -0.035em;
				text-align: left;
			}
			p {
				font-size: 10px;
				font-weight: 400;
				line-height: 12.1px;
				letter-spacing: -0.025em;
				text-align: left;
				color: #9395aa;
			}
			.left_side {
				display: flex;
				align-items: flex-start;
				flex-direction: column;
				gap: 20px;
				p,
				h4 {
					margin: 0;
				}
				p {
					display: flex;
					align-items: center;
					gap: 5px;
				}
			}
		}
	}

	.checklist_view {
		background-color: #fafafc;
		border-radius: 6px;
		padding: 6px;
		.title_row {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 12px;
			h3 {
				font-family: "Inter", sans-serif;
				font-size: 14px;
				font-weight: 500;
				line-height: 19.6px;
				letter-spacing: -0.025em;
				text-align: left;
				color: #031729;
			}
		}
		.controls {
			display: flex;
			align-items: center;
			padding: 6px;
			height: 32px;
			border: 1px solid #5455660d;
			border-radius: 6px;
			background: #ffffff;

			button {
				border: none;
				height: 26px;
				width: 30px;
				padding: 0px 12px;
				svg {
					height: 14px;
					width: 14px;
					min-height: 12px;
					min-width: 12px;
				}
			}
			button:first-child {
				background-color: #fbfbfb;
				border-radius: 4px;
				color: #000;
				padding: 0px 8px;
			}
		}
		.checklist_group {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			gap: 40px;
			margin-top: 10px;
			.todos {
				width: 100%;
				border-radius: 6px;
				.todo_status {
					border-radius: 6px 6px 0px 0px;
					padding: 0px;
					height: 60px;
					font-size: 14px;
					font-weight: 500;
					line-height: 19.6px;
					letter-spacing: -0.025em;
					text-align: left;
					color: #e72e50;
					h6 {
						color: #e72e50;
						font-size: 14px;
						svg {
							height: 14px;
							width: 14px;
							margin: 0px 5px;
						}
						span {
							background-color: white;
							border-radius: 50%;
							margin: 0px 10px;
							padding: 4px 6px;
							height: 28px;
							width: 28px;
							font-weight: 600;
						}
					}
					.completed_icon_view {
						display: flex;
						align-items: center;
						gap: 10px;
						color: #000;
						svg {
							height: 14px;
							width: 14px;
							color: #000;
						}
						.icon {
							h6 {
								margin-bottom: 0;
								color: #000;
								span {
									background-color: transparent;
									color: gray;
									position: unset;
									top: unset;
									right: unset;
									font-size: 14px;
									margin: 0;
								}
							}
						}
					}
				}

				.todo_footer {
					height: 100px;
					background-color: white;
					display: flex;
					align-items: center;
					justify-content: flex-end;
					border-top: 1px solid #5455660d;
					padding: 0px 24px;
					button {
						border: none;
						background: #fafafc;
						border-radius: 6px;
						padding: 6px 8px;
						font-size: 12px;
						font-weight: 500;
						line-height: 16.8px;
						letter-spacing: -0.025em;
						text-align: center;
						color: #303030;
						height: 32px;
						svg {
							stroke: #71798c;
							height: 14px;
							width: 14px;
						}
					}
				}

				&.completed {
					.todo_status h6 {
						color: #34a853;
					}
				}
			}
		}
	}

	.checklist_view {
		.title_row div {
			display: flex;
			align-items: center;
			gap: 4px;
			h3 {
				margin-bottom: 0;
			}
		}
		.checklist_group {
			grid-template-columns: 2fr 1fr;
			gap: 10px;
			.todos {
				.todo_status {
					display: flex;
					align-items: center;
					justify-content: space-between;
					h6 {
						display: flex;
						align-items: center;
						position: relative;
						color: #000;
						span {
							position: absolute;
							right: -24px;
							top: -4px;
							background-color: #e72e50;
							color: #ffff;
							font-size: 8px;
							height: 14px;
							width: 14px;
							display: flex;
							align-items: center;
							justify-content: center;
							line-height: 10px;
						}
					}
					button {
						border: 1px solid #5455660d;
						font-size: 12px;
						border-radius: 6px;
						height: 32px;
						width: 70px;
						background-color: #fff;
					}
				}
			}
		}
	}
}
.checklist_tab_button_group + .coming_soon_feature {
	margin-top: 30px;
}
.coming_soon_feature {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	position: relative;
	padding: 40px;
	margin: 20px 0px;
	border-radius: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: auto;
	overflow: hidden;
	.shadow_wrapper {
		background-color: #000000c2;
		position: absolute;
		height: 100%;
		border-radius: 10px;
		width: 100%;
	}
	.default_view {
		position: absolute;
		width: 250px;
		height: 150px;
	}
}

section.event_day_flow {
	.default_view {
		border: 0.5px solid #fbbc05;
		.icon_wrapper svg {
			stroke: #fbbc05;
		}
		button {
			background-color: #031729;
		}
	}
}

@media (max-width: 768px) {
	.todo_body {
		min-height: unset;
		.checklist_item .checklist_text_view .period {
			span.date {
				text-wrap: nowrap;
				white-space: nowrap;
			}
			.no_priority {
				text-wrap: nowrap;
				white-space: nowrap;
				font-size: 10px;
			}
		}
	}

	section.checklist_page {
		padding: 0;
		margin: 0;
		.checklist_view .checklist_group {
			display: flex;
			flex-direction: column;
			.todos {
				flex-direction: column;
				display: flex;
				.todo_status {
					height: unset;
				}
			}
		}
	}
}

@media (max-width: 560px) {
	.add_checklist_modal .checklist_group .checklist_row {
		flex-direction: column;
		align-items: flex-start;
	}
	.todo_body .checklist_item div h6 {
		max-width: 150px;
		text-overflow: ellipsis;
		overflow: hidden;
		text-wrap: nowrap;
		white-space: nowrap;
	}
}
