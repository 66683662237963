@import "./variables.scss";

button.react-datepicker__navigation.react-datepicker__navigation span {
	color: #f0f0f0;
}

.estimate_n_actual_input_group {
	label {
		display: none;
		@media (max-width: 768px) {
			display: flex;
		}
	}
	.input_error {
		border: 1px solid #cb3050;
	}
}

@media (max-width: 500px) {
	.event_budget {
		.event_budget_view {
			height: 150px;
		}
		.budget_amount_group {
			max-height: 50px;
		}

		.budget_amount_info_group {
			height: 30px;
		}
	}
	.budget_tracker
		.event_budget
		.budget_breakdown_view
		.budget_list
		.budget_dropdown
		.budget_list_dropdown
		.select_due_date
		.select_date_input_view
		button.react-datepicker__navigation--next {
		right: 5px;
		top: 0px;
	}
	.budget_tracker
		.event_budget
		.budget_breakdown_view
		.budget_list
		.budget_dropdown
		.budget_list_dropdown
		.select_due_date
		.select_date_input_view
		button.react-datepicker__navigation--previous {
		right: unset;
		left: 5px;
		top: 0px;
	}
}

.add_payment_modal {
	.select_search .select__control {
		height: 50px;
		border: 1px solid #a7a8b0;
		border-radius: 0.25rem;
		transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	}
	.select_search .select__control:hover,
	.select_search .select__control--is-focused {
		border-color: #e695a5;
		transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
		box-shadow: 0 0 0 0.1rem rgba(203, 48, 80, 0.25);
	}
	.select_search:focus-visible {
		box-shadow: 0 0 0 0.1rem rgba(203, 48, 80, 0.25);
		outline: 0;
		border-color: #e695a5;
		border-radius: 0.25rem;
		transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	}
}

.select_date_input_view label {
	margin-bottom: 4px !important;
}

.text_with_info {
	.question span {
		display: flex;
		border-radius: 50%;
		align-items: center;
		justify-content: center;
		margin: auto;
		text-align: center;
		background-color: rgba(203, 48, 80, 0.8);
		height: 100%;
		width: 100%;
	}
}

form.modal_form {
	display: flex;
	flex-direction: column;
	gap: 32px;

	button.bg_red {
		height: 46px;
		width: 119px;
		font-weight: 600;
		font-size: 20px;
		line-height: 30px;
		@media (max-width: $bp-lap) {
			font-size: 18px;
			line-height: 24px;
		}
	}
}
.budget_tracker {
	font-family: "Poppins";
	font-style: normal;
	color: #545565;

	.event_filter {
		margin-bottom: 30px;
		h3 {
			font-weight: 600;
			font-size: 24px;
			line-height: 36px;
			margin-bottom: 0px;
			@media (max-width: $bp-lap) {
				font-size: 20px;
				line-height: 28px;
			}
		}
	}

	.budget_list_dropdown_view .dropdown_icon img {
		transform: rotate(0deg);
		transition: transform 0.5s;
	}

	.budget_list_dropdown_view.active .dropdown_icon img {
		transform: rotate(180deg);
		transition: transform 0.5s;
	}

	.add_tags {
		background-color: white;
		width: 100%;
		border-radius: 5px;
		padding: 18px 24px;
	}
	.event_budget {
		margin-top: 10px;
		.dropdown {
			a {
				color: #545565;
			}
			a:hover {
				background: rgba(84, 85, 101, 0.2);
			}
		}
		.section_title {
			margin-bottom: 19px;
			button {
				background-color: #545565;
				color: white;
				height: 46px;
				width: 140px;
				padding-left: 0px;
				display: flex;
				align-items: center;
				justify-content: space-evenly;
			}
			button.vendor {
				width: 200px;
			}
		}
		h3 {
			font-weight: 600;
			font-size: 24px;
			line-height: 36px;
			margin-bottom: 0px;
			@media (max-width: $bp-lap) {
				font-size: 20px;
				line-height: 28px;
			}
		}
		.event_budget_view {
			background: #ffffff;
			border: 1px solid #e598a7;
			border-radius: 8px;
			padding: 26px 38px 0px;
			display: flex;
			align-items: center;
			@media (max-width: $bp-phone) {
				display: grid !important;
				grid-template-columns: repeat(2, 1fr);
				.budget_amount_info_group {
					height: unset;
				}
			}
			.budget_amount_info {
				.question span {
					display: flex;
					border-radius: 50%;
					align-items: center;
					justify-content: center;
					margin: auto;
					text-align: center;
					background-color: rgba(203, 48, 80, 0.8);
				}
			}
			label {
				font-weight: 400;
				font-size: 20px;
				margin-bottom: 0px;
				@media (max-width: $bp-lap) {
					font-size: 18px;
				}
			}
			p {
				margin-top: 8px;
			}
			.title {
				display: flex;
				align-items: center;
				text-wrap: nowrap;
				white-space: nowrap;
				img {
					margin-left: 10px;
				}
			}
			.percentagebar {
				position: relative;
				.text-content {
					position: absolute;
					top: 60px;
					left: 0;
					text-align: center;
					.d-flex {
						width: 170px;
						align-items: center;
						justify-content: center;
					}
					&.low {
						color: black;
					}
					&.ok {
						color: #cb3050;
					}
					h6 {
						font-weight: 700;
						font-size: 20px;
						line-height: 30px;
						@media (max-width: $bp-lap) {
							line-height: 24px;
							font-size: 18px;
						}
					}
					p {
						font-weight: 400;
						font-size: 16px;
						line-height: 24px;
						color: #545565;
						text-align: center;
						margin: auto;
					}
					img {
						position: absolute;
						right: -5px;
					}
				}
			}
		}
		.budget_breakdown_view {
			box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
			border-radius: 8px;
			padding: 34px 38px;
			@media (max-width: $bp-lap) {
				padding: 24px 28px;
			}

			.budget_list {
				background: #ffffff;
				border: 1px solid #d8d8d8;
				border-radius: 5px;
				margin-bottom: 28px;
				padding: 20px;
				label {
					font-weight: 400;
				}
				@media (max-width: 500px) {
					padding: 15px;
					h4 {
						max-width: 200px;
					}
					p {
						margin-bottom: 0;
					}
				}

				h4 {
					font-weight: 600;
					font-size: 20px;
					line-height: 30px;
					@media (max-width: $bp-lap) {
						font-size: 18px;
						line-height: 24px;
					}
				}
				h6 {
					font-weight: 500;
					font-size: 16px;
					line-height: 20px;
					span {
						color: #cb3050;
					}
				}
				.budget_rate {
					button {
						border: 0;
						background-color: transparent;
					}
					.prices {
						gap: 40px;
					}
					@media (max-width: 560px) {
						.cost_item_category_buttons {
							display: flex;
							flex-direction: column;
							align-items: flex-end;
							.delete_category_btn img {
								width: 16px;
								height: 16px;
							}
						}
					}
				}
				.budget_dropdown {
					.divider {
						margin: 32px 0px 36px 0px;
						background-color: #e5e5e5;
					}
					.bg_red {
						width: unset;
						height: 48px;
					}
					.top {
						display: flex;
						justify-content: flex-end;
						margin-bottom: 21px;
						.dropdown_header {
							display: flex;
							margin-right: 210px;
							gap: 130px;
							div {
								text-align: center;
								color: #545565;
								h4 {
									font-weight: 600;
									font-size: 20px;
									line-height: 30px;
									@media (max-width: $bp-lap) {
										font-size: 18px;
										line-height: 24px;
									}
								}
								p {
									font-weight: 500;
									font-size: 16px;
									line-height: 20px;
									margin-bottom: 0px;
								}
							}
						}
					}
					.estimate_actual {
						margin-bottom: 22px;
						justify-content: space-between;
						gap: 10px;
						.estimate_actual_top {
							max-width: 290px;
							@media (max-width: 1024px) and (min-width: 769px) {
								max-width: 190px;
							}
							@media (max-width: 768px) {
								max-width: 400px;
							}
							.title {
								p {
									max-width: 230px;
									overflow-x: clip;
									text-overflow: ellipsis;
									text-wrap: nowrap;
									white-space: nowrap;
									@media (max-width: 768px) {
										max-width: 400px;
										overflow-x: visible;
										font-weight: 500;
									}
									@media (max-width: 500px) {
										width: fit-content;
										overflow-x: clip;
									}
								}
							}
						}
						.budget_title_input {
							border-bottom: 1px solid #dadada;
							border-top: 0px;
							border-left: 0px;
							border-right: 0px;
							max-width: 200px;
							@media (max-width: 768px) {
								max-width: 100%;
							}
						}
						.title {
							@media (max-width: $bp-lap) {
							}
							@media (max-width: $bp-phone) {
								max-width: 150px;
								p {
									font-size: 16px;
									text-transform: capitalize;
								}
								button {
									height: unset;
									width: unset;
								}
							}
						}
						p {
							font-weight: 400;
							font-size: 20px;
							line-height: 30px;
							color: #545565;
							margin-bottom: 0px;
							@media (max-width: $bp-lap) {
								font-size: 16px;
								line-height: 20px;
							}
						}
						.input_group {
							gap: 24px;
							@media (max-width: $bp-lap) {
								gap: 20px;
							}
							input.form-control {
								border-radius: 0px;
								border: 1px solid #dadada;
							}
							.icon_group {
								display: flex;
								gap: 20px;
								@media (max-width: $bp-lap) {
									gap: 15px;
								}
							}
						}
					}
					textarea {
						border: 1px solid #dadada;
					}
					.budget_list_dropdown.event_budget_dropdown {
						.select_due_date {
							height: unset;
						}
					}
					.budget_list_dropdown {
						padding-top: 24px;
						margin: 32px 0px 32px 0px;
						border-top: 1px solid #e5e5e5;
						border-bottom: 1px solid #e5e5e5;
						.input.error input,
						.input_error input.form-control {
							border: 1px solid #cb3050;
						}
						.form_element.select .form-control.input_error {
							border: 1px solid #cb3050;
						}
						.select_due_date {
							height: 40px;
							.select_date_input_view {
								margin-bottom: 0px;
								gap: 5px;
								@media (max-width: 768px) {
									gap: 0px;
								}
								button {
									margin-bottom: 0;
									@media (max-width: $bp-phone) {
										right: 5px;
									}
								}
								.select_date_input {
									margin-bottom: 0px;
								}
								label {
									font-weight: 400;
									margin-bottom: 0px;
								}
								@media (max-width: $bp-phone) {
									gap: 0px;
								}
							}
						}
						.d-flex {
							margin-bottom: 32px;
							button.bg_red {
								width: unset;
								min-width: 120px;
							}
							@media (max-width: $bp-lap) {
								&.d-flex.select_date_input {
									padding-right: 0px !important;
								}
							}
							.paid_input {
								.icon {
									right: 24px;
									@media (max-width: 768px) {
										right: 15px;
									}
								}
							}
							.form_element .icon {
								top: 55px;
							}
							label {
								font-weight: 400;
								@media (max-width: $bp-lap) {
									font-size: 18px;
									line-height: 24px;
								}
								@media (max-width: $bp-tab) {
									font-size: 14px;
									line-height: 20px;
									margin-bottom: 0px;
								}
							}
							input,
							select {
								border-radius: 0px;
								border: 1px solid #dadada;
							}

							@media (max-width: $bp-tab) {
								input {
									max-width: unset;
								}
							}
							// .checkbox {
							// 	label {
							// 		margin-bottom: 30px;
							// 	}
							// }
						}
						.button_group {
							margin-right: 15px;
							@media (max-width: $bp-lap) {
								margin-right: 0px;
							}
							button {
								margin-bottom: 0px;
							}
						}
						button {
							margin-bottom: 26px;
							padding: 12px 10px;
						}
						.vendor {
							select {
								width: 100%;
								appearance: none;
								background: url("https://firebasestorage.googleapis.com/v0/b/plan-a-day-12b4c.appspot.com/o/select_arrow.svg?alt=media&token=9056509d-c3bb-450d-abe0-91884643ef77")
									no-repeat;
								-webkit-appearance: none;
								padding-right: 10px !important;
								background-position: calc(100% - 0.75rem) center !important;
								-moz-appearance: none !important;
							}
							.vendor_name {
								width: 42%;
							}
							.vendor_ig {
								width: 25%;
							}
							@media (max-width: $bp-lap) {
								.vendor_name,
								.vendor_ig {
									width: unset;
								}
							}
						}
					}
				}
			}
		}
	}
}

@media (min-width: 600px) {
	.event_budget_view {
		display: flex;
		flex-direction: row;
		align-items: center;
	}
}

.estimate_actual_group {
	@media (min-width: 1024px) {
		max-width: 250px;
	}
}

@media (max-width: $bp-tab) {
	.modal-body form.modal_form {
		gap: 20px;
		.input_row {
			gap: 20px;
		}
	}
	.budget_tracker {
		.event_budget {
			h3 {
				font-size: 20px;
				line-height: 26px;
			}
			.icon_group {
				justify-content: flex-end;
				gap: 15px;
				button {
					width: unset;
					height: unset;
					img {
						margin: 0px !important;
					}
				}
			}
			.budget_list p {
				font-size: 12px;
				line-height: 16px;
			}
			.budget_breakdown_view .budget_list h4 {
				font-size: 16px;
				line-height: 20px;
			}
			.event_budget_view {
				label {
					font-size: 16px;
				}
				padding: 20px 30px;
				h4 {
					font-size: 18px;
					line-height: 24px;
				}
				.title {
					text-align: center;
					margin: auto;
					justify-content: center;
				}
				.amount {
					text-align: center;
				}
			}
			.button_group.d-flex.justify-content-between {
				flex-direction: column;
				padding: 0px;
				margin-right: 0px !important;
			}
		}
		.event_filter {
			display: flex !important;
			gap: 15px;
			h3 {
				font-size: 20px;
				font-weight: bold;
			}
			.filter {
				width: fit-content;
				button {
					width: 235px;
				}
			}
			.budget_tracker.mobile_filter {
				display: none !important;
			}
		}
	}

	.budget_tracker .event_budget .budget_breakdown_view .budget_list .budget_rate .prices {
		gap: 10px;
	}

	.budget_tracker .event_budget .budget_breakdown_view .budget_list h6 {
		font-size: 14px;
		line-height: 20px;
	}
	.budget_tracker .event_budget .budget_breakdown_view .budget_list .budget_rate button {
		padding: 0;
	}
	.budget_tracker .event_budget .budget_breakdown_view {
		padding: 24px;
		gap: 20px;
		display: flex;
		flex-direction: column;
	}
	.budget_tracker .event_budget .budget_breakdown_view .budget_list {
		margin-bottom: 0;
	}

	// budget breakdown
	.budget_tracker .event_budget .budget_breakdown_view .budget_list .budget_dropdown .estimate_actual .input_group {
		gap: 20px;
		width: 100%;
	}

	.budget_tracker .event_budget .budget_breakdown_view .budget_list .budget_dropdown .budget_list_dropdown .d-flex {
		margin-bottom: 32px;
		width: 100%;
	}

	.budget_tracker .event_budget .budget_breakdown_view .budget_list .budget_dropdown .budget_list_dropdown .vendor .vendor_name,
	.budget_tracker .event_budget .budget_breakdown_view .budget_list .budget_dropdown .budget_list_dropdown .vendor .vendor_ig {
		width: 100%;
	}

	.budget_list_dropdown .form_element.checkbox {
		display: flex;
		align-items: center;
		gap: 10px;
	}
	.budget_tracker .event_budget .budget_breakdown_view .budget_list .budget_dropdown .budget_list_dropdown .d-flex .checkbox label {
		margin-bottom: 0px;
	}

	.budget_tracker .event_budget .budget_breakdown_view .budget_list .budget_dropdown .budget_list_dropdown label {
		margin-bottom: 5px;
		line-height: 18px;
		font-size: 16px;
		font-weight: 400;
	}

	.budget_tracker .budget_list_dropdown_view input,
	.budget_tracker .budget_list_dropdown_view input::placeholder {
		font-size: 14px;
	}

	.budget_tracker .event_budget .budget_breakdown_view .budget_list .budget_dropdown .estimate_actual p {
		font-size: 14px;
		line-height: 18px;
	}

	.budget_tracker .event_budget .budget_breakdown_view .budget_list .budget_dropdown .divider {
		margin: 18px 0px 18px 0px;
	}

	.estimate_actual .budget_title_input {
		max-width: 130px;
		border-bottom: 1px solid #dadada;
	}

	.estimate_actual_top .icon_group button {
		padding: 0px;
	}

	.estimate_actual_top .title img {
		height: 18px;
		width: 18px;
	}

	.icon_group {
		gap: 10px;
	}

	.estimate_actual_group {
		width: 100%;
		@media (min-width: 1024px) {
			max-width: 220px;
		}
		button {
			width: 100%;
			height: 100%;
			padding: 0;
		}
		.icon_group .trash {
			img {
				height: 16px;
				width: 16px;
			}
		}
	}

	.estimate_actual_top {
		width: 100%;
		display: flex;
	}
	.budget_tracker .event_budget .budget_breakdown_view .budget_list .budget_dropdown .budget_list_dropdown .d-flex .form_element .icon {
		top: 58px;
		font-size: 10;
		height: 12px;
		width: 12px;
	}
	.section_title .dropdown-menu .dropdown-item {
		font-size: 16px;
		line-height: 20px;
	}
	.percentagebar {
		height: 140px;
	}
}

.budget_tracker .event_filter .mobile_filter {
	display: none !important;
}

@media (max-width: $bp-phone) {
	.budget_tracker .event_budget .budget_breakdown_view .budget_list .budget_dropdown .budget_list_dropdown .d-flex .form_element .icon {
		top: 45px;
	}
	.budget_tracker .event_filter {
		display: flex !important;
		flex-direction: column;
		align-items: flex-start !important;

		.event_overview span {
			font-size: 11px;
		}
	}
	.input_row {
		gap: 20px;
	}
	.button_group {
		gap: 20px !important;
	}
}

@media (max-width: 380px) {
	.budget_tracker .event_filter {
		.event_overview {
			gap: 10px;
			h3 {
				font-size: 18px;
			}
			span {
				font-size: 10px;
				padding: 5px 10px;
			}
		}
	}
	.budget_tracker .event_budget .budget_breakdown_view {
		padding: 14px 16px;
		gap: 15px;
	}
	.estimate_actual_top {
		display: flex;
		flex-direction: column;
	}
}

@media (max-width: 320px) {
	.budget_tracker .event_filter {
		.event_overview {
			gap: 6px;
			h3 {
				font-size: 16px;
				line-height: 20px;
			}
			span {
				font-size: 8px;
				line-height: 10px;
				padding: 5px 8px;
			}
		}
	}
}
