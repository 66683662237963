@import "./variables.scss";

.dashboard_loader {
	.card-grid {
		gap: 40px;
		display: grid;
		flex-wrap: wrap;
		grid-template-columns: repeat(3, 1fr);
		justify-content: space-between;
		svg {
			height: 150px;
			width: 100%;
		}
		@media (max-width: $bp-tab-one-pixel-down) {
			display: flex;
			flex-wrap: wrap;
			gap: 20px;
		}
	}
	.table_loader {
		svg {
			width: 100%;
			height: 100%;
		}
	}
}

.text-dark {
	color: black;
}

.dashboard_content {
	button.btn:focus,
	button.btn.focus {
		box-shadow: none;
	}
	.dropdown_toggle {
		display: flex;
	}
	.guest_dashboard {
		.order_form {
			width: 100%;
			.order_form_details {
				display: grid;
				grid-template-columns: repeat(3, 1fr);
				gap: 30px;
				width: 100%;
				margin-bottom: 40px;
				@media (max-width: 768px) {
					grid-template-columns: repeat(2, 1fr);
				}

				@media (max-width: 450px) {
					grid-template-columns: repeat(1, 1fr);
				}

				.orderform_card {
					width: 100%;
					.order_form_banner {
						height: 120px;
						width: 100%;
						background-color: #545566;
						border-top-left-radius: 5px;
						border-top-right-radius: 5px;
					}

					.details {
						width: 100%;
						height: 80px;
						padding: 15px 0px 18px 18px;
						background-color: white;
						border-bottom-left-radius: 5px;
						border-bottom-right-radius: 5px;
						h6 {
							color: #545566;
							font-weight: bold;
							font-size: 18px;
						}
						p {
							color: #545566;
						}
					}
				}
				.orderform_card:hover {
					text-decoration: none;
					opacity: 0.8;
				}
			}
		}
		@media (max-width: 560px) {
			.mobile_hidden {
				display: none;
			}
		}
		tbody {
			tr {
				cursor: pointer;
				button {
					svg {
						transition: all 1s;
					}
					&.active_table_row {
						svg {
							transform: rotate(180deg);
						}
					}
				}
			}

			.table_details {
				width: 100%;
				min-width: 100%;
				position: relative;
				background-color: rgba(188, 118, 118, 0.156);
				.table_list_details {
					padding-left: 20px;
					gap: 10px;
					flex-direction: column;
					display: flex;
					border-bottom: 0px;

					div strong {
						text-wrap: nowrap;
						white-space: nowrap;
					}
					.dropdown-menu {
						flex-direction: column;
						width: fit-content;
						border-bottom: 0;
					}
					div {
						width: 100%;
						display: flex;
						flex-direction: row;
						gap: 10px;
						p {
							text-wrap: nowrap;
							white-space: nowrap;
						}
						.list,
						.group_list {
							border-bottom: 0px;
						}
					}
					@media (max-width: 560px) {
						.table_asoebi_view {
							display: flex;
							flex-direction: column;
						}
					}
				}
				.table_list_details > div {
					border-bottom: 1px solid #bec7d1;
				}
			}
		}
		section.card_view {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			@media (max-width: $bp-tab) {
				grid-template-columns: repeat(2, 1fr);
				.card {
					min-height: unset;
					h2 {
						font-size: 20px;
					}
					h4 {
						font-size: 16px;
					}
				}
			}
			@media (max-width: $bp-phone) {
				display: flex;
				flex-direction: column;
				.card {
					min-height: unset;
					h2 {
						font-size: 20px;
					}
					h4 {
						font-size: 16px;
					}
				}
			}
		}
		.filter_audit_select {
			max-width: 100%;
		}

		h1 {
			font-size: 28px;
		}
		.asoebi_table_options {
			@media (min-width: $bp-lap) {
				min-width: 230px;
			}
		}
		.search_audits input.form-control {
			height: 40px;
			border: 1px solid #a7a8b0;
		}
		.search_audits .search_cta {
			position: absolute;
			right: 45px;
			top: 8px;
		}
	}
	.dropdown-menu .dropdown-item {
		color: #cb3050;
		font-weight: 600;
		font-size: 16px;
	}
	.dropdown-item.active,
	.dropdown-item:active {
		color: white;
	}
	.filter_by_status {
		@media (max-width: $bp-tab) {
			select {
				width: 100%;
				min-width: 100%;
			}
			.form_element {
				min-width: fit-content;
			}
		}
	}
	.table {
		th {
			text-wrap: nowrap;
			white-space: nowrap;
			border-width: 1px;
			padding: 20px 20px 15px 0px;
			@media (min-width: $bp-lap) {
				min-width: 140px;
			}
		}
		td {
			padding: 20px 20px 15px 0px;
		}
	}
	.table_dropdown {
		button {
			padding: 0px;
		}
		.toggle {
			background-color: transparent !important;
			border: none;
		}
		.dropdown_menu {
			a {
				color: black;
			}
		}
	}
	.card_view {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		gap: 25px;

		&.user {
			grid-template-columns: repeat(3, 1f);
		}
		.card {
			padding: 20px;
			height: fit-content;
			max-height: 110px;
			box-shadow: 0rem 0rem 0.5rem rgba(0, 0, 0, 0.1);
			h4 {
				font-size: 16px;
				font-weight: bold;
			}
			h2 {
				font-size: 24px;
			}
			p {
				font-size: 18px;
			}
		}
	}
	.title_view {
		h1 {
			font-size: 24px;
			color: black;
		}
		button {
			right: 30px;
		}
	}
}

.view_asoebi_order_foorm_modal {
	.grid {
		display: grid;
	}
	.option_details {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		border-radius: 5px;
	}
	.asoebi_images img {
		height: 250px;
		width: 300px;
		border: 1px solid black;
		display: flex;
		min-width: 300px;
	}

	.asoebi_images {
		display: flex;
		flex-direction: row;
		gap: 10px;
		overflow-x: scroll;
		margin-bottom: 20px;
		width: 100%;
	}
}
