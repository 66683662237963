@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400,500&family=Manrope:wght@400,500,600,700&display=swap");
@import "./variables.scss";

button#radix-\:rg\:,
button#radix-\:ro\:,
.tlui-menu button:nth-child(3),
.tlui-menu button:nth-child(3) + .tlui-menu__group button:first-child,
.tlui-menu__submenu__content div:nth-child(3) {
	display: none !important;
}

.spinner_wrapper {
	height: 600px;
	width: 100%;
}

.tlui-kbd > span {
	color: white;
}

.sample-canvas {
	height: 500px;
}

.create_new_floor_plan.modal {
	.modal-dialog {
		min-width: 650px;
		.modal-content .modal-body .floor_plans button {
			h6 {
				font-size: 14px;
			}
			p {
				font-size: 9px;
			}
		}
	}
}

section.floor_plan {
	border-radius: 16px;
	display: flex;
	margin: 0px;
	justify-content: center;
	height: 100%;
	font-family: "Inter", sans-serif;
	min-height: 100%;
	height: calc(100vh - 100px);
	align-items: center;
	flex-direction: column;
	justify-content: flex-start;
	gap: 10px;
	button {
		background-color: transparent;
		display: flex;
		border: 1px solid #5455660d;
		align-items: center;
		font-size: 12px;

		gap: 5px;
		svg,
		span svg {
			height: 14px;
			width: 14px;
			min-width: 14px;
		}
	}
	.floor_plan_group {
		display: flex;
		align-items: flex-start;
		width: 100%;
		gap: 10px;
		border-bottom: 1px solid #5455660d;
		justify-content: flex-start;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		button {
			border: none;
			padding-bottom: 10px;
			&.current {
				color: #e845ba;
				border-bottom: 1px solid #e845ba;
			}
			span {
				text-wrap: nowrap;
				max-width: 140px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
		.added_floor_plans {
			display: flex;
			max-width: 90%;
			overflow-x: scroll;
			input {
				border: none;
				background-color: transparent;
				padding: 0px 5px;
				text-wrap: nowrap;
				white-space: nowrap;
				text-overflow: ellipsis;
				&.active {
					border: 1px solid #404040;
				}
			}
		}

		.floor_plan_left_group {
			flex-direction: row;
			display: flex;
			overflow-x: scroll;
			max-width: 80%;
		}

		.added_floor_plans {
			display: flex;
			flex-direction: row;
		}

		.right_group {
			display: flex;
			&.portrait {
				button:last-child {
					color: #e845ba;
					border-bottom: 1px solid #e845ba;
				}
			}
			&.landscape {
				button:first-child {
					color: #e845ba;
					border-bottom: 1px solid #e845ba;
				}
			}
		}
	}
	.floor_plan_view {
		width: 100%;
		height: 100%;
		justify-content: flex-start;
		align-items: center;
		display: flex;
		flex-direction: column;
		background-color: #fafafc;
		border-radius: 6px;
		.floor_plan_top_bar {
			padding: 12px 16px;
			display: flex;
			justify-content: space-between;
			width: 100%;
			align-items: center;
			margin: 0px;
			height: 56px;
			min-height: 56px;
			.title {
				font-size: 12px;
				color: #404040;
				display: flex;
				align-items: center;
				gap: 4px;
				svg {
					height: 14px;
					width: 14px;
					stroke: #404040;
				}
				input {
					border: none;
					background-color: transparent;
					padding: 0px 5px;
					text-wrap: nowrap;
					white-space: nowrap;
					text-overflow: ellipsis;
					&:focus {
						border: 1px solid #404040;
					}
				}
			}
		}
		.canvas__view {
			width: 100%;
			padding: 6px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			&.portrait {
				flex-direction: row;
				align-items: flex-start;

				.canvas_wrapper {
					width: 75%;
					.canvas_board .draw_actions_bottom {
						flex-direction: column;
						width: 50px;
						display: flex;
						align-items: flex-end;
						justify-content: flex-end;
						right: 10px;
						bottom: 150px;
						.upload_wrapper {
							position: relative;
							bottom: unset;
							height: 40px;
							width: 40px;
						}
					}
				}

				.pick_elements {
					height: 100%;
					width: 25%;
					z-index: 10000;
					.pick_elements_top_bar {
						display: flex;
						flex-direction: column;
						align-items: flex-start;

						.top_bar_header .pick_element span {
							display: none;
						}
					}
					.elements_group .table_chairs_elements {
						flex-direction: column;
						.floor_plan_element .table_chair_group {
							flex-wrap: wrap;
						}
					}
				}
			}
		}
		.floor_plan_canvas_view {
			background-color: #fff;
			height: 100%;
			width: 98%;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 16px 21px 24px 21px;
			border-radius: 6px;
			margin-bottom: 10px;
		}
		.floor_plan_button_group {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 22%;
			.right_button_group {
				display: flex;
				align-items: center;
				gap: 6px;
				button svg {
					height: 14px;
					width: 14px;
				}
				.share_plan {
					background-color: #031729;
					border-radius: 6px;
					height: 32px;
					padding: 0px 10px;
					color: white;
				}
				button {
					border: 1px solid #5455660d;
					height: 32px;
					padding: 0px 9px;
					background-color: #fff;
					border-radius: 6px;
				}
				#dropdown {
					box-shadow: 0px 4px 20px 0px #0000000d;

					button {
						padding: 0px 12px 0px 9px !important;
						box-shadow: none;
						span svg {
							height: 10px;
							width: 10px;
						}
						&.dropdown_toggle {
							span {
								display: none;
							}
						}
					}
					.dropdown-menu.show {
						box-shadow: 0px 4.5px 15.75px 1.13px #3030301a;
						border: 0.5px solid #dddfe380;
						z-index: 20000;
						.dropdown-item svg {
							height: 14px;
							width: 14px;
						}
					}
				}
			}
			.left_button_group {
				display: flex;
				gap: 6px;
				button {
					height: 32px;
					width: 32px;
					border-radius: 6px;
					padding: 8px 9px;
					background-color: #fff;
					&.edit {
						width: unset;
						svg {
							height: 14px;
							width: 14px;
							stroke: #404040;
						}
					}
				}
			}
			hr {
				height: 100%;
				min-height: 20px;
				width: 1px;
				border-left: 1px solid #e9e9e9;
				margin: 0;
			}
		}
		.canvas_wrapper {
			padding: 6px;
			background-color: #fff;
			height: calc(100% - 220px);
			width: 99%;
			margin-top: 5px;
		}
		.pick_elements {
			height: 160px;
			width: 99%;
			background-color: #fff;
			border-radius: 0px;
			margin-top: 6px;
			.pick_elements_top_bar {
				display: flex;
				align-items: flex-start;
				justify-content: space-between;
				padding: 20px 6px 10px;
				gap: 20px;

				.top_bar_header {
					display: flex;
					width: 100%;
					border-bottom: 1px solid #5455660d;
					.pick_element {
						padding: 0px 15px;
						font-size: 12px;
						padding-bottom: 6px;
						color: #888c8f;
						border: none;
						svg {
							height: 12px;
							width: 12px;
						}
						&.active {
							color: #e845ba;
							border-color: #e845ba;
							border-bottom: 1px solid #e845ba;
						}
					}
				}

				.right_view .search_tools_element {
					width: 240px;
					display: flex;
					align-items: center;
					gap: 5px;
					border: 1px solid #5455660d;
					height: 28px;
					border-radius: 4px;
					padding: 8px 10px;
					min-height: 28px;
					position: relative;
					input {
						border: none;
						font-size: 12px;
					}
					svg {
						height: 12px;
						width: 12px;
					}
					.spinner_element_wrapper {
						position: absolute;
						right: -10px;
						z-index: 100;
						width: fit-content;
					}
				}
			}

			.no_element {
				font-family: "Inter", sans-serif;
				font-size: 12px;
				font-weight: 400;
				line-height: 12px;
				letter-spacing: -0.025em;
				text-align: left;
				color: #000;
			}
			.elements_group {
				display: flex;
				flex-wrap: wrap;
				flex-direction: row;
				gap: 10px;
				padding: 12px 20px;
				button {
					padding: 0;
					border: none;

					.element_icon {
						display: flex;
						flex-direction: column;
						height: 60px;
						width: 64px;
						padding: 6px 4px;
						font-size: 10px;
						border: 1px solid #5455660d;
						border-radius: 4px;
						justify-content: space-between;
						p {
							margin: 0;
							text-wrap: nowrap;
							white-space: nowrap;
							padding: 0;
							font-family: "Inter", sans-serif;
							font-size: 9px;
							font-weight: 400;
							line-height: 12px;
							letter-spacing: -0.025em;
							text-align: center;
							sup {
								font-size: 6px;
							}
						}
						.title_wrapper {
							background: #fafafc;
							padding: 0px 6px;
							border-radius: 2px;
							height: 26px;
							display: flex;
							align-items: center;
							justify-content: center;
							svg {
								height: 28px !important;
								width: 28px !important;
							}
							div.title {
								border: 0.2px solid #404040;
								height: 32px;
								background-color: #d9d9d9;
								font-size: 12px;
								font-weight: 500;
								color: #404040;
								text-align: center;
								display: flex;
								align-items: center;
								justify-content: center;
								text-wrap: nowrap;
								white-space: nowrap;
								font-size: 6.5px;
								border-radius: 2px;
								height: 14px;
								padding: 0px 5px;
							}
							svg {
								height: unset;
								width: unset;
							}
						}
						&:hover {
							border-color: #e845ba;
							color: #e845ba;
							svg {
								path,
								rect {
									fill: #e845ba;
								}
							}
						}
					}
				}

				.table_chairs_elements {
					display: flex;
					align-items: flex-start;
					gap: 50px;
					flex-wrap: wrap;
				}

				.floor_plan_element {
					display: flex;
					flex-direction: column;
					h6 {
						font-family: "Inter", sans-serif;
						font-size: 10px;
						font-weight: 400;
						line-height: 12px;
						letter-spacing: -0.025em;
						text-align: left;
						white-space: nowrap;
					}
					.table_chair_group {
						display: flex;
						flex-direction: row;
						gap: 10px;
					}
				}
			}
		}
	}
	.no_floor_plan_card {
		box-shadow: 0px 14.4px 72px 0px #0000000d;
		border: 3px solid #ffffff;
		width: 480px;
		border: 0.5px solid #e845ba;
		padding: 25px 0;
		border-radius: 6px;
		background-color: white;
		.image_wrapper {
			border: 1px solid #5455660d;
			border-radius: 2px;
			width: fit-content;
			margin: auto;
			height: 40px;
			width: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
			svg {
				height: 20px;
				width: 20px;
				stroke: #e845ba;
			}
		}
		.floor_plan_text {
			border-radius: 8px;
			padding: 16px 12px;
			h4 {
				font-size: 1rem;
				text-align: center;
				color: #000;
				font-family: "Inter", sans-serif;
				font-size: 0.875rem;
				font-weight: 500;
				line-height: 36px;
				margin-bottom: 0;
				letter-spacing: -0.02em;
			}
			p {
				text-align: center;
				font-size: 1rem;
				font-size: 0.75rem;
				font-weight: 400;
				line-height: 16.8px;
				letter-spacing: -0.025em;
				text-align: center;
				color: #9395aa;
				span {
					font-weight: bold;
					color: black;
				}
			}
		}
		.button_group {
			gap: 15px;
			display: flex;
			justify-content: center;
			button {
				height: 40px;
				padding: 12px 16px;
				gap: 8px;
				border-radius: 8px;
				font-family: "Manrope", sans-serif;
				font-size: 0.75rem;
				font-weight: 600;
				line-height: 14px;
				display: flex;
				align-items: center;
				text-align: center;
				&.btn_red {
					background-color: #404040;
					color: #fff;
					border: none;
				}
				&.btn_bordered {
					border: 1px solid #30303026;
					background-color: #fff;
				}
			}
		}
	}
	@media (max-width: 560px) {
		.no_floor_plan_card {
			width: 250px;
			img {
				height: 150px;
				width: 150px;
			}
			.floor_plan_text {
				h4 {
					font-size: 1rem;
				}
			}
			.button_group {
				flex-direction: column;
				button {
					width: fit-content;
					margin: auto;
				}
			}
		}
	}
}

.dashboard_modal.show.drawer .modal-dialog .modal-content .modal-body {
	margin: 0;
	padding: 0;
}

section.floor_plan_templates {
	overflow-y: scroll;
	display: flex;
	height: 100%;
	width: 100%;
	min-height: 100%;
	flex-direction: column;

	.template_content {
		display: flex;
		flex-direction: row;
		height: 85%;
		min-height: 85%;
		.search_view {
			width: 240px;
			height: 100%;
			min-width: 200px;
			border-right: 1px solid #5455660d;
			padding: 15px;
			.search {
				background-color: #f5f5f580;
				padding: 12px;
				border-radius: 8px;
				font-weight: 500;
				font-family: "Manrope", sans-serif;
				display: flex;
				align-items: center;
				gap: 10px;
				input {
					border: none;
					background-color: transparent;
					width: 80%;
					&::placeholder {
						color: #9395aa;
					}
				}
			}
			.space_type_dropdown {
				margin: 10px 0px;
				border-width: 1px 0px 1px 0px;
				border-style: solid;
				font-weight: bold;
				padding: 5px 0px;
				border-color: #5455660d;
				font-family: "Inter", sans-serif;
				button.dropdown_toggle {
					width: 100%;
					cursor: pointer;
					display: flex;
					justify-content: space-between;
					font-weight: 600;
					padding: 5px 10px !important;
					&:focus {
						box-shadow: none;
						outline: none;
					}
				}
				div.dropdown-menu.show {
					box-shadow: 0px 4.5px 15.75px 1.13px #3030301a;
					border: 1px solid #30303026;
					width: 100%;
					margin: 0px;
					border-radius: 8px;
					padding: 10px;
					margin-top: 16px;
				}
				.option {
					padding: 8px 14px;
					cursor: pointer;
					&:hover {
						background-color: #fbfbfb;
						border-radius: 8px;
						color: $base_red;
						position: relative;
						&::after {
							content: url("../assets/img/checkIconRed.svg");
							position: absolute;
							right: 5px;
						}
					}
				}
			}
		}
		.template_view {
			height: 100%;
			.select_template_view {
				display: grid;
				padding: 50px 20px;
				grid-template-columns: repeat(3, 1fr);
				gap: 25px;
				.hall {
					border: 2px solid #5455660d;
					border-radius: 8px;
					background: #fbfbfb;
					height: fit-content;
					cursor: pointer;
					&:hover,
					&.active {
						background-color: $base_red;
						div {
							p {
								color: white;
							}
						}
					}
					img {
						height: 120px;
						width: 200px;
						border-top-left-radius: 8px;
						border-top-right-radius: 8px;
						object-fit: cover;
						object-position: center;
					}
					div {
						height: 50px;
						display: flex;
						align-items: center;
						justify-content: center;
						p {
							padding: 0;
							color: #031729;
							font-size: 0.875rem;
							font-weight: 600;
							font-family: "Inter", sans-serif;
							line-height: 21px;
							letter-spacing: -0.02em;
							margin: 0;
						}
					}
				}
			}
			@media (max-width: 1024px) {
				.select_template_view {
					padding: 20px;
					grid-template-columns: repeat(2, 1fr);
				}
			}
		}
	}
	.template_footer {
		border-radius: 8px;
		border: 1px solid #e9e9e9;
		height: 100px;
		padding: 24px 32px;
		background-color: #f7f7f7;
		display: flex;
		margin: 5px;
		align-items: center;
		justify-content: space-between;
		button {
			width: 240px;
			height: 48px;
			padding: 0px 16px 0px 16px;
			border-radius: 8px;
			&.cancel {
				border: 1px solid $base_red;
				color: $base_red;
				background-color: transparent;
			}
			&.next {
				background-color: $base_red;
				color: #fff;
				border: none;
			}
		}
	}
}
