@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400,500&family=Manrope:wght@400,500,600,700&display=swap");
@import "./variables.scss";

.planning_timeline {
	font-family: "Inter", sans-serif;
	.event_day_view {
		display: flex;
		flex-direction: column;

		.months_group {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			button {
				border: 1px solid #5455660d;
				height: 24px;
				width: 24px;
				display: flex;
				align-items: center;
				justify-content: center;
				background-color: transparent;
				border-radius: 3px;
			}
			.monthly_task_view {
				display: flex;
				gap: 20px;
				div.month_view {
					position: relative;
					.handlebar {
						width: 2px;
						border-radius: 6px;
						position: absolute;
						left: -2px;
						display: flex;
						top: 5px;
						height: 54px;
					}
					justify-content: space-between;
					border: 1px solid #5455660d;
					border-radius: 6px;
					height: 64px;
					width: 145px;
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					padding: 5px 20px;
					h4 {
						font-size: 13px;
						font-weight: 500;
						line-height: 18.2px;
						letter-spacing: -0.025em;
						text-align: center;
						margin: 0;
					}
					h6 {
						font-size: 16px;
						font-weight: 500;
						line-height: 22.4px;
						letter-spacing: -0.025em;
						text-align: left;
						color: #888c8f;
						margin: 0;
						sup {
							font-size: 10px;
							font-weight: 400;
							line-height: 12px;
							letter-spacing: -0.025em;
							text-align: left;
						}
					}
				}
			}
		}
		.table_view {
			display: flex;
			flex-direction: column;
			margin-top: 40px;
			width: 100%;
			.table_row {
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-bottom: 15px;
				margin-left: 20px;
				h3 {
					font-size: 14px;
					font-weight: 500;
					line-height: 19.6px;
					letter-spacing: -0.025em;
					text-align: left;
					margin-bottom: 0;
					svg path {
						stroke: #4285f4;
					}
				}
				.action_group {
					display: flex;
					align-items: center;
					gap: 10px;
					button {
						background: #fafafc;
						border: none;
						font-size: 12px;
						font-weight: 500;
						line-height: 18px;
						letter-spacing: -0.025em;
						text-align: center;
						display: flex;
						align-items: center;
						gap: 4px;
						border-radius: 6px;
						height: 30px;
						padding: 6px 8px;
						svg {
							height: 14px;
							width: 14px;
						}
					}
				}
			}
			table {
				border-width: 1px;
				border-style: solid;
				border-color: #5455660d;
				border: 1.2px solid #e9e9e9;
				border-radius: 6px;
				border-left: 2px solid #4285f4;
				border-radius: 6px;
				td {
					border: 1.2px solid #e9e9e9;
					height: 42px;
					font-size: 12px;
					font-weight: 500;
					line-height: 18px;
					letter-spacing: -0.025em;
					text-align: left;
					padding: 0px 12px;
					margin: 0;
					input {
						max-width: 134px;
						border: 1px solid #5455660d;
						height: 22px;
						border-radius: 4px;
					}
					.stage_element {
						min-width: 100px;
						.dropdown {
							height: unset;
							width: fit-content;
							border-radius: 100px;
							button {
								padding: 4px 10px;
							}
						}
					}
				}
				td:first-child input {
					width: 14px;
					height: 14px;
					border-radius: 4px;
					border: 1.2px solid #888c8f;
					margin: 0;
					padding: 0;
				}
				tbody {
					td:last-child div {
						display: flex;
						align-items: center;
						background: #fbfbfb;
						border-radius: 6px;
						padding: 2px 2px 2px 4px;
						gap: 4px;
						height: 28px;
						border: none;
						button {
							border: none;
							background-color: transparent;
							svg {
								height: 12px;
								stroke: #9395aa;
								width: 12px;
							}
						}
						button:last-child {
							background-color: white;
							box-shadow: 0px 1px 10px 0px #0000000d;
							border-radius: 4px;
							padding: 6px;
							height: 24px;
							display: flex;
							align-items: center;
						}
						button:first-child {
							padding: 0;
						}
					}
					td:nth-child(2) input,
					td:nth-child(7) input {
						border: none;
					}
					td:nth-child(2) input {
						width: 200px;
						max-width: 200px;
					}
					td:nth-child(3) {
						width: 120px;
						input {
							border-radius: 20px;
							text-align: left;
							width: 80px;
							padding-left: 25px;
						}
					}
					td:nth-child(4) input {
						border-radius: 4px;
						width: 80px;
						max-width: 80px;
						text-align: center;
					}
					td:nth-child(5) {
						width: 150px;
						input {
							border-radius: 4px;
							width: fit-content;
							text-align: center;
						}
					}
					td:nth-child(7) input {
						width: 190px;
						max-width: 190px;
					}
					td:nth-child(8) {
						width: 100px;
						max-width: 100px;
						.button_group {
							width: 46px;
							margin: auto;
						}
					}
				}
			}
			&.this_month_list {
				h3 {
					color: #4285f4;
				}
				table {
					border-left: 2px solid #4285f4;
				}
			}
			&.next_month_list {
				h3 {
					color: #26cf9f;
				}
				table {
					border-left: 2px solid #26cf9f;
				}
			}
		}
	}
}

.stage_element {
	min-width: 130px;
	p.label {
		font-weight: 400;
		line-height: 17.5px;
		letter-spacing: 0.01em;
		text-align: center;
		font-size: 10px !important;
		margin-left: 10px !important;
		margin-top: 5px !important;
		margin-bottom: -2px !important;
	}
	.dropdown {
		background-color: #fff;
		border: 1px solid #30303026;
		border-radius: 8px;
		height: 54px;
		display: flex;
		align-items: center;
		flex-direction: column;
		align-items: flex-start;
		.dropdown-toggle::after {
			display: none;
		}

		button {
			font-size: 12px;
			font-family: "Inter", sans-serif;
			box-shadow: none;
			border: none;
			outline: none !important;
		}
		button:focus {
			border: none !important;
			outline: none !important;
			box-shadow: none;
		}
		.dropdown-menu.show {
			padding: 5px 15px;
			font-size: 12px;
			width: 130px;
			max-width: 130px;
			min-width: 130px;
			font-family: "Inter", sans-serif;
			a.dropdown-item {
				margin: 10px 0px;
				cursor: pointer;
				background-color: white;
				border: none;
				color: #000;
				font-size: 12px;
				padding: 0px;
				font-weight: normal;
				svg {
					position: relative;
				}
			}
		}
	}
}
