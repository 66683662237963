@import "./variables.scss";

.checklist_item_modal {
	select.form-control {
		height: 38px;
		min-width: 100%;
	}
}
.checklist {
	font-family: "Poppins", sans-serif;
	font-style: normal;

	.event_filter .event_overview {
		flex-direction: column;
		align-items: flex-start !important;
		gap: 5px;
	}

	.event_filter h3 {
		font-weight: 600;
		font-size: 24px;
		line-height: 36px;
		color: #545565;
		@media (max-width: $bp-lap) {
			font-size: 20px;
			line-height: 28px;
		}
	}

	.search_spinner {
		top: 10px;
		right: 50px;
	}

	.top {
		h1 {
			font-weight: 600;
			font-size: 24px;
			line-height: 36px;
			color: #545565;
		}
	}

	.summary {
		.bar {
			height: 10px;
		}
		.progress_outer {
			background: #fceded;
			border-radius: 20px;
			width: 100%;
		}
		.progress_inner {
			background: #cb3050;
			border-radius: 20px;
		}
	}

	.search_form {
		button {
			right: 20px;
			top: 0px;
			border: 0px;
			background-color: transparent;
		}
		button {
			height: 46px;
		}
		.form-control {
			border: 1px solid #545565;
			border-radius: 5px;
			width: 300px;
			height: 46px;
			@media (max-width: $bp-lap) {
				width: 230px;
			}
		}
		input.form-control::placeholder {
			font-weight: 500;
			font-size: 16px;
			line-height: 20px;
			color: rgba(84, 85, 101, 0.5);
		}
	}

	.task-view {
		padding: 24px 32px;
		margin-top: 25px;
		border: 1px solid #e598a7;
		border-radius: 8px;
		margin-bottom: 48px;

		h4 {
			font-weight: 600;
			font-size: 20px;
			line-height: 30px;
			margin-bottom: 20px;
			@media (max-width: $bp-lap) {
				font-size: 18px;
				line-height: 24px;
			}
			span {
				color: #cb3050;
			}
		}
	}

	.icons {
		gap: 29px;
		img {
			width: 24px;
			height: 24px;
			@media (max-width: $bp-tab) {
				height: 16px;
				width: 16px;
			}
		}
	}
	.checklist_todo {
		box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
		border-radius: 8px;
	}
	.todo-header {
		border-bottom: 1px solid #e5e5e5;
		padding: 28px 32px;
		.todo-left {
			button {
				font-size: 20px;
				line-height: 30px;
				border-radius: 0px;
				padding-bottom: 23px;
				@media (max-width: $bp-lap) {
					font-size: 18px;
					line-height: 24px;
					padding-bottom: 26px;
				}
			}
			button:focus {
				outline: none;
				box-shadow: none;
			}
			button.active {
				border-bottom: 2px solid #cb3050;
			}
		}
		button {
			font-size: 16px;
			line-height: 20px;
		}
	}
	.todo-body {
		width: 100%;
		padding: 32px;

		.todo-item.done {
			h5 {
				text-decoration: line-through;
			}
		}
		.suggested_checklist {
			background: #fceded;
			border-radius: 5px;
			margin-bottom: 32px;
			padding: 28px;
			display: flex;
			flex-direction: column;
			font-size: 20px;
			line-height: 30px;
			color: #545565;
			gap: 6px;
			@media (max-width: $bp-lap) {
				padding: 15px;
				font-size: 16px;
				line-height: 20px;
			}
			.title {
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-bottom: 10px;
				@media (max-width: $bp-tab) {
					flex-direction: column;
					font-size: 16px;
					align-items: flex-start;
					gap: 10px;
				}
				div {
					display: flex;
					align-items: center;
					@media (max-width: $bp-tab) {
						justify-content: space-between;
						width: 100%;
					}
					h6 {
						color: #cb3050;
						font-weight: 500;
						font-size: inherit;
						margin-bottom: 0;
					}
					button {
						background-color: transparent;
						border: 0px;
						margin-left: 40px;
						@media (max-width: $bp-tab) {
							margin-left: 0px;
						}
					}
				}
				h6 {
					font-weight: 500;
					color: #545565;
					font-size: inherit;
					margin-bottom: 0;
					span {
						color: #cb3050;
						margin-left: 8px;
					}
				}
			}
			.suggested_checklist_item {
				background-color: white;
				height: 62px;
				padding: 16px;
				p.text {
					font-weight: 400;
					margin-bottom: 0;
					font-size: inherit;
					@media (max-width: $bp-tab) {
						font-size: 14px;
					}
				}
				input {
					accent-color: #545565;
				}
			}
		}

		.todo-item {
			display: flex;
			justify-content: space-between;
			width: 100%;
			background: #ffffff;
			border: 1px solid #d8d8d8;
			border-radius: 5px;
			align-items: center;
			margin-bottom: 28px;
			padding: 18px 24px;
			position: relative;

			.icons {
				button {
					border: 0;
					background-color: transparent;
				}
			}
			.todo_left {
				input {
					accent-color: #cb3050;
					border-radius: 5px;
					height: 30px;
					width: 30px;
				}
				.date_group {
					@media (max-width: $bp-phone) {
						flex-direction: column;
						align-items: flex-start !important;
						.future_date_tag {
							margin-left: 0px;
						}
					}
					.future_date_tag {
						border-radius: 5px;
						background: #c0c6a6;
						color: #000;
						font-size: 12px;
						font-weight: 600;
						padding: 5px 15px;
						text-transform: uppercase;
						text-wrap: nowrap;
						white-space: nowrap;
						@media (max-width: $bp-phone) {
							font-size: 9px;
						}
					}
				}
			}
			.text {
				margin-left: 24px;

				h5 {
					font-weight: 600;
					font-size: 20px;
					line-height: 30px;
					color: #545565;
					@media (max-width: $bp-lap) {
						font-size: 18px;
						line-height: 24px;
					}
				}
				p {
					margin-top: 11px;
					font-weight: 500;
					font-size: 16px;
					line-height: 24px;
					color: #cb3050;
				}
			}
			.note_text {
				padding: 17px;
				position: absolute;
				border: 1px solid #d8d8d8;
				right: 30px;
				max-width: 430px;
				background: white;
				top: 110px;
				z-index: 1;
			}
		}
	}
}
@media (max-width: $bp-lap) and (min-width: 769px) {
	.event_filter .event_overview {
		grid-gap: 5px;
		gap: 5px;
		flex-direction: column;
		align-items: flex-start !important;
	}
	.checklist .filter button.bg_dark {
		width: 230px;
		padding: 0px 15px !important;
		justify-content: space-between !important;
	}
}

@media (max-width: $bp-tab) {
	.select_date {
		button.react-datepicker__navigation.react-datepicker__navigation {
			overflow: visible;
		}
		.react-datepicker__navigation--next {
			right: 10px;
			top: -2px;
		}
		.react-datepicker__navigation--previous {
			left: 10px;
			top: -2px;
		}
	}
	.checklist {
		.filter {
			display: flex;
			flex-direction: row !important;
			align-items: center;
			justify-content: flex-end;
			width: unset;
			gap: 20px;
			.search_form {
				margin-bottom: 0px !important;
			}
		}

		.summary .top {
			display: flex !important;
			flex-direction: row;
			align-items: flex-start;
			align-items: center !important;
			justify-content: space-between;
		}

		button.button.mobile_filter {
			padding: 12px !important;
			background-color: #545565;
			display: flex;
			align-items: center;
			justify-content: center;
			height: 45px;
			position: relative;
			top: unset;
			right: unset;
			img {
				margin-left: 0px !important;
				display: flex;
				align-items: center;
				justify-content: center;
				margin: auto;
			}
		}
	}
	.filter {
		width: 100%;
	}
	.checklist .search_form .form-control {
		width: 100%;
	}
	.checklist .todo-body {
		padding: 20px;
	}

	.checklist .icons {
		gap: unset;
		grid-gap: unset;
		height: 16px;
		width: 16px;
		align-items: center;
		justify-content: center;
	}

	.checklist .todo-body .todo-item .todo_left input {
		width: 16px;
		height: 16px;
	}

	.checklist .todo-body .todo-item .text h5 {
		font-size: 16px;
		line-height: 20px;
		max-width: 150px;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	.checklist .todo-body .todo-item .todo_left button img {
		height: 18px;
		width: 18px;
	}
	.checklist .todo-body .todo-item .todo_left button {
		height: 18px;
		width: 18px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.checklist .todo-body .todo-item .text {
		margin-left: 14px;
	}

	.checklist .todo-body .todo-item {
		padding: 10px 16px;
		margin-bottom: 16px;
	}

	.checklist .todo-body .todo-item .text p {
		font-size: 13px;
		line-height: 16px;
		margin-top: 5px;
	}

	.checklist .summary .top {
		display: flex !important;
		flex-direction: row;
		align-items: flex-start;
	}
	.checklist .top h1 {
		font-size: 20px;
		line-height: 28px;
	}
	.checklist .summary .search_form {
		gap: 20px;
	}
	.checklist .todo-header {
		padding: 14px 16px;
	}

	.checklist .event_filter button.bg_dark {
		max-width: 240px;
		height: 45px;
		display: none !important;
	}

	.checklist .event_filter .mobile_filter {
		position: absolute;
		right: 15px;
		top: 50px;
		background-color: #545565;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.checklist .todo-header .hidden_on_mobile {
		display: none !important;
	}

	.checklist .task-view {
		padding: 16px;
		margin: 40px 0px;
	}

	.checklist .task-view h4 {
		font-size: 16px;
		line-height: 24px;
		margin-bottom: 10px;
	}

	.modal-title.h4 {
		font-size: 20px;
		line-height: 24px;
		margin-bottom: 10px;
		font-weight: 600;
	}

	.checklist .todo-header .todo-left button {
		font-size: 16px;
		line-height: 18px;
		border-radius: 0px;
		padding-bottom: 10px;
	}
	.checklist_item_modal {
		gap: 20px;
		select.form-control {
			height: 38px;
			min-width: 100%;
		}
	}
	form.checklist_item_modal label,
	form.checklist_item_modal .label {
		margin-bottom: 5px;
		line-height: 18px;
		font-size: 16px;
	}
	.checklist_item_modal .select_date button {
		height: 40px;
		width: fit-content;
	}

	.d-flex.form_group .col-7 {
		max-width: 100% !important;
	}

	.d-flex.form_group {
		flex-direction: column;
		width: 100%;
		gap: 20px;
	}

	.d-flex.form_group .col-5 {
		max-width: 100%;
	}

	form.checklist_item_modal input,
	form.checklist_item_modal input::placeholder {
		font-size: 16px;
	}
}

@media (max-width: $bp-phone) {
	.position-relative.search_form,
	.checklist .filter {
		width: 100%;
	}

	.checklist .summary .top {
		flex-direction: column;
		align-items: flex-start !important;
		width: 100%;
		justify-content: flex-start;
	}

	.checklist .event_filter {
		gap: 20px;
		.event_overview {
			gap: 10px;
			h3 {
				font-size: 18px;
			}
			span {
				font-size: 10px;
				padding: 5px 10px;
			}
		}
	}
}

@media (max-width: 380px) {
	.checklist .event_filter {
		.event_overview {
			gap: 5px;
			h3 {
				font-size: 18px;
				line-height: 24px;
			}
			span {
				font-size: 10px;
				padding: 5px 8px;
			}
		}
	}
}

@media (max-width: 320px) {
	.checklist .event_filter {
		.event_overview {
			gap: 5px;
			flex-direction: column;
			align-items: flex-start !important;
		}
	}
}
