@import "./variables.scss";

.select__option:hover {
	background-color: #e695a5;
	color: white;
}

.dashboard_modal.full_sidebar.invite_collaborator_modal.show .modal-dialog {
	left: 0;
}

.select__option.select__option--is-selected {
	background-color: #e695a5;
}

.dashboard_modal.show.normal .modal-dialog .modal-header .modal-title {
	font-size: 14px;
	line-height: 16px;
	margin-bottom: 0;
}

.collaborators_list .modal-title.h4 {
	text-transform: none;
}

.dashboard_modal.show.add_budget_category_modal .modal-dialog .modal-content {
	.modal-body {
		padding: 30px 0px 15px;

		.add_budget_category {
			padding: 0px 25px;
		}
	}
	.modal_footer {
		padding: 15px 25px 15px;
	}
}

.add_vendor_modal {
	&.modal.show .modal-dialog {
		min-width: 90%;
		.modal-header {
			background-color: #f9f9f9bf;
		}
		.modal-content .modal-body {
			padding: 0px;
		}
	}

	.add_vendor_form {
		margin-top: 20px;
		width: 100%;

		.form_element.select.custom {
			width: 100%;
			max-width: 200px;
			border-radius: 8px;
			height: 54px;
			border: 1px solid #30303026 !important;
			padding-bottom: 0px;
			font-size: 12px;
			padding-top: 15px;
			label {
				position: absolute;
				font-size: 10px;
				color: #717984;
				left: 14px;
				top: 3px;
				font-weight: 400;
			}
			select.form-control {
				font-size: 12px;
				margin-top: -2px;
				&:focus {
					box-shadow: none;
				}
			}
		}

		.input_row {
			display: flex;
			align-items: center;
			gap: 10px;
			padding: 0px 25px;
			.custom_input_view:first-child {
				min-width: 250px;
			}
		}

		.modal_footer {
			border-top: 1px solid #5455660d;
			width: 100%;
			margin-top: 20px;
			padding: 10px 25px;
			padding-bottom: 14px !important;

			.button_group {
				display: flex;
				align-items: center;
				justify-content: flex-end;
				gap: 30px;
				button {
					height: 36px;
					font-family: "Inter", sans-serif;
					font-size: 13px;
					line-height: 18.2px;
					letter-spacing: -0.025em;
					border-radius: 6px;
					padding: 0px 12px;
					&.cancel_btn {
						font-weight: 400;
						background-color: #fff;
						border: 1px solid #5455660d;
						color: #404040;
					}
					&.create_btn {
						font-weight: 600;
						color: #fff;
						background-color: #e72e50;
						padding: 0px 24px;
					}
				}
			}
		}
	}
}

.collaborators_list_modal {
	.payment_status {
		&.ACTIVE {
			background-color: green;
			color: white;
			&.FREE {
				background: #c0c6a6;
			}
		}
		&.EXPIRED {
			background-color: $primary;
			color: white;
		}
		border-radius: 5px;
		background: #c0c6a6;
		color: #000;
		font-size: 12px;
		font-weight: 600;
		padding: 5px 10px;
		text-transform: uppercase;
	}
	button.renew {
		background-color: green;
		color: white;
		padding: 5px 10px;
		font-weight: 900;
		font-size: 12px;
		margin: 0px 10px;
	}
}

.select_user_type_modal {
	div.modal-dialog {
		min-width: 500px;
		margin-top: 50px;

		@media (max-width: 560px) {
			min-width: 96%;
		}
	}
	.select_user_type {
		display: flex;
		flex-direction: column;
		gap: 20px;
		margin-top: 20px;
		button {
			background-color: #fff;
			border: 1px solid #5455660d;
			height: 40px;
			font-size: 14px;
			padding: 24px 32px;
			border-radius: 8px;
			&:hover {
				border: 1px solid $base_red;
			}
		}
		.select_option h4 {
			font-size: 16px;
			text-align: left;
		}

		.select_option p {
			font-size: 12px;
		}

		.select_option {
			width: 100%;
			padding: 16px 20px;
		}

		.position-relative {
			.spinner {
				right: 20px;
				top: 20px;
			}
		}
	}

	form {
		.form_row {
			display: flex;
			flex-direction: column;
			.section_title {
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-bottom: 10px;
				h6 {
					color: $base_red;
					font-weight: 600;
					margin: 0;
					padding: 0;
					font-size: 0.8125rem;
					font-family: "Inter", sans-serif;
				}
				div {
					display: flex;
					align-items: center;
					gap: 2px;
				}
				button {
					height: 32px;
					width: fit-content;
					margin: unset;
					padding: 7px 8px !important;
					background-color: #fbfbfb;
					color: #031729;
					font-family: "Inter", sans-serif;
					font-size: 0.75rem;
					font-weight: 500;
					line-height: 18px;
					letter-spacing: -0.025em;
					text-align: center;

					svg {
						stroke: #031729;
						height: 12px;
						width: 12px;
					}
				}
			}
			input {
				margin-bottom: 10px;
				color: #000;
			}
			.input_group {
				display: flex;
				gap: 10px;
				.location_input {
					min-width: 180px;
				}
				.select_input {
					min-width: 70px;
				}
				.select_date_input_view {
					min-width: 110px;
				}
			}
			.ceremonies {
				display: flex;
				flex-direction: column;
				gap: 10px;
			}
			div.button_action_group {
				display: flex;
				flex-direction: row;
				gap: 6px;
				.dropdown_toggle {
					span {
						display: none;
					}
				}
				.dropdown-menu.show {
					display: flex;
					flex-direction: column;
				}
			}
		}

		.input_list_group {
			display: flex;
			align-items: flex-start;
			flex-direction: row;
			.input_list {
				display: flex;
				flex-direction: column;
				width: 100%;
				gap: 10px;

				.upload_group {
					display: flex;
					align-items: center;
				}
			}
			.business_name {
				display: flex;
				position: relative;
			}
			.upload_event_banner {
				width: 32px;
				height: 32px;
				border-radius: 50%;
				background-color: #fafafc;
				padding: 10px;
				margin-left: 10px;
				display: flex;
				position: absolute;
				right: 10px;
				bottom: 10px;
				cursor: pointer;
				align-items: center;
				justify-content: center;
				min-height: 32px;
				min-width: 32px;
				z-index: 1;
				input {
					position: absolute;
					width: 100%;
					height: 100%;
					z-index: 100;
					cursor: pointer;
					&::-webkit-file-upload-button {
						visibility: hidden;
					}
				}
				svg {
					min-height: 12px;
					min-width: 12px;
					position: relative;
					z-index: 1;
				}
				img {
					height: 32px;
					width: 32px;
					border-radius: 100%;
					min-height: 32px;
					min-width: 32px;
					object-fit: cover;
					object-position: top center;
				}
			}
		}

		.button_group button {
			background-color: $base_red;
			border: none;
		}
	}
}

.ceremony_picker_select:focus-visible {
	outline: $primary auto 1px;
}

.form_element.search_select .select__control:hover {
	border: 1px solid $primary;
}

.vendor_assistance {
	h5 {
		color: #cb3050 !important;
	}
}

.vendor_assistance_modal {
	h5 {
		color: #cb3050 !important;
	}
	.form_element label {
		font-size: 15px;
		line-height: 18px;
	}
}

.modal-body {
	.vendor_assistance {
		max-height: 500px;
		overflow-y: scroll;
	}
}

.pricing_table_body .table_row {
	a.contact_btn {
		background-color: $primary;
		color: white;
		min-height: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-weight: 800;
		font-size: 14px;
		&:hover {
			text-decoration: none;
			opacity: 0.9;
		}
	}
}

.pricing_carousel_view {
	position: relative;
	button.controls {
		border: 0px;
		position: absolute;
		top: 43%;
		background-color: $primary;
	}
	button.left_control.controls:disabled,
	button.right_control.controls:disabled {
		opacity: 0.5;
	}
	button.left_control {
		left: 0;
	}
	button.right_control {
		right: 0;
	}
	.pricing_carousel_view_item {
		background-color: #cb305042;
		padding: 20px 0px 30px;
		margin: 10px 0px;
		border-radius: 5px;
		text-align: center;
		&.Basic,
		&.Essential,
		&.Premium,
		&.Custom {
			p.collaborator,
			p.events {
				display: flex;
				flex-wrap: wrap;
			}
			.collaborator {
				p.events,
				p.collaborator {
					display: flex;
					flex-wrap: wrap;
				}
				text-wrap: wrap;
				flex-wrap: wrap;
				height: 50px;
			}
		}

		p {
			border-bottom: 1px solid #c4bebe;
			height: 50px;
			display: flex;
			font-size: 14px;
			align-items: center;
			justify-content: center;
			margin-bottom: 0;
			span {
				margin-right: 3px;
			}
		}
		h4 {
			font-weight: 800;
			font-size: 20px;
		}
		.description {
			font-weight: 600;
		}
		.add_plan {
			width: 200px;
			font-weight: 600;
			justify-content: space-between;
			display: flex;
			margin: auto;
			@media (max-width: $bp-phone) {
				font-size: 14px;
			}
		}
	}
}

// modal
.modal-dialog .modal-header {
	border: 0;
	padding: 0px;
	padding-top: 30px;
	padding-left: 1rem;
}

.modal-title.h4 {
	font-weight: 600;
	text-transform: capitalize;
}

.modal .modal-dialog {
	max-width: 650px;
}

.modal.bigger .modal-dialog {
	max-width: 1150px;
}

.modal.medium .modal-dialog {
	max-width: 900px;
}

.modal .close_icon {
	width: 15px;
	height: 18px;
}

.modal .modal-content {
	padding: 0px 20px 10px;
}

// download modal
.download_modal {
	margin-top: 40px;
	margin-bottom: 10px;
}

.download_modal input.email_input,
.download_modal button.btn.send,
.vendor_assistance button.btn.send,
.vendor_assistance input.email_input {
	height: 40px;
}

.download_modal button.btn.send {
	padding: 0px 40px;
}

.vendor_assistance button.btn.send {
	padding: 0px 30px;
}

.download_modal input.email_input,
.vendor_assistance input.email_input {
	border: 1px solid #a7a8b0;
	border-radius: 5px;
}

.download_modal .checkbox {
	display: flex;
	align-items: center;
}

.download_modal input.form-check-input {
	border: 1px solid #c4c4c4;
	border-radius: 4px;
	height: 20px;
	width: 20px;
	accent-color: $primary;
}

.download_modal .checkbox label.form-check-label {
	margin-left: 10px;
}

// vendorassistance modal
div.vendor_assistance p {
	color: $base;
}
.vendor_assistance .whatsapp_message {
	margin-top: 25px;
	border: 1px solid $primary;
	padding: 24px 20px 10px;
}

.vendor_assistance h6 {
	color: $primary;
	font-weight: 600;
}

.vendor_assistance .form_view {
	border: 0;
	margin-top: 35px;
	padding: 22px 20px 16px;
}

.pricing_table {
	.active {
		background-color: #cb305042;
	}

	h5 {
		font-size: 16px;
		margin-bottom: 20px;
	}

	.pricing_table_header {
		div {
			border-bottom: 1px solid rgb(196, 190, 190);
		}
		// div.free {
		// 	width: 100px;
		// }
		h6 {
			font-weight: 600;
		}
		p {
			font-weight: 400;
			font-size: 14px;
		}
		div {
			width: 200px;
			padding: 10px;
			p {
				margin-bottom: 0;
			}
		}
		div.features {
			width: 220px;
			font-size: 14px;
		}
	}
	.pricing_table_body {
		display: flex;
		flex-direction: column;
		.table_row {
			display: flex;
			flex-direction: row;
			// div.free {
			// 	width: 100px;
			// }
			div {
				width: 200px;
				padding: 10px;
				border-bottom: 1px solid rgb(196, 190, 190);
				height: 50px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				font-size: 14px;
				p {
					margin-bottom: 0;
				}
			}
			&.bigger_row div {
				height: 100px;
			}
			.feature {
				width: 220px;
				font-size: 14px;
			}

			&.last_row {
				display: flex;
				div {
					padding-top: 15px;
					border-bottom: none;
					button {
						background-color: $primary;
						color: white;
						border: none;
						width: 95%;
						padding: 10px;
						height: 40px;
						display: flex;
						align-items: center;
						justify-content: space-between;
						font-size: 16px;
						font-weight: 800;
						span {
							font-size: 12px;
						}
					}
				}
			}
		}
	}
}

@media (max-width: $bp-lap) {
	.modal-title.h4 {
		font-size: 20px;
		margin-bottom: 20px;
	}
}

@media (max-width: $bp-phone) {
	.vendor_details_button_group {
		width: 100%;
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
	}
}

@media (max-width: $bp-tab) {
	.pricing_carousel_view {
		.Custom {
			p.last {
				margin-bottom: 30px;
			}
		}
		a.bg-red.contact_btn {
			background-color: #cb3050;
			color: white;
			/* margin-top: 120px !important; */
			padding: 10px 20px;
			border-radius: 4px;
			font-weight: bold;
			@media (max-width: $bp-phone) {
				font-size: 14px;
			}
		}
	}
	.download_modal button.btn.send {
		padding: 0px 20px;
	}
	div.modal-body {
		padding: 0px;
	}
	div.modal-body button span {
		font-size: 16px;
	}

	div.modal-body section button.bg_red {
		height: fit-content;
		width: fit-content;
		padding: 10px 20px;
	}

	div.modal-body .collaborators {
		h5 {
			font-size: 16px;
			font-weight: 600;
			margin-bottom: 20px;
		}
		.collaborator_group {
			gap: 15px;
		}
	}

	.modal-body p,
	.modal-body form label {
		font-size: 15px;
		margin-bottom: 0px;
	}

	.form_element.input.col-6 {
		padding: 0px;
	}

	.modal-body form input,
	.modal-body form input::placeholder {
		font-size: 14px;
		line-height: 18px;
	}

	.modal-content .modal-header {
		padding-left: 0px;
	}

	.modal-header button.btn {
		position: absolute;
		right: 10px;
		top: 10px;
	}
	.budget__button-group button.btn {
		width: 220px;
		justify-content: space-between;
	}
	.download_modal {
		margin-top: 20px;
	}

	.modal-title.h4 {
		font-size: 20px;
		margin-bottom: 20px;
	}

	.vendor_assistance button.btn.send {
		padding: 0px 15px;
	}
	.download_modal input.email_input,
	.vendor_assistance input.email_input {
		font-size: 12px;
	}
}

.dashboard_modal.show {
	right: 0;
	position: fixed;
	.modal-dialog {
		min-width: 68%;
		margin-top: 10%;
		font-family: "Inter", sans-serif;

		@media (min-width: 1560px) {
			min-width: 50%;
		}

		.modal-header {
			border-bottom: 1px solid #e9e9e9;
			background-color: #f5f5f5;
			border-top-left-radius: 16px;
			border-top-right-radius: 16px;
			padding: 16px 24px 12px;
			display: flex;
			gap: 5px;
			flex-direction: column;
			position: static;
			.modal-title {
				font-size: 24px;
				font-weight: 600;
				line-height: 36px;
				letter-spacing: -0.01em;
				text-align: center;
				color: #000;
			}
			p {
				font-size: 10px;
				font-weight: 400;
				line-height: 17.5px;
				letter-spacing: 0.01em;
				text-align: left;
				margin: 0;
			}
		}
		.modal-content {
			padding: 0;
			background-color: white;
			margin: auto;
			.modal-body {
				padding: 30px 25px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				margin: auto;
				align-items: center;
				gap: 60px;
				.floor_plans {
					display: flex;
					gap: 10px;
					button {
						border-radius: 8px;
						text-align: left;
						background-color: transparent;
						border: 1px solid #5455660d;
						padding: 16px;
						.icon_wrapper {
							border: 0.4px solid #30303026;
							width: fit-content;
							border-radius: 4px;
							padding: 2px;
							height: 26px;
							width: 26px;
							display: flex;
							align-items: center;
							justify-content: center;
							margin: 10px 0px;
							svg {
								height: 14px;
								width: 14px;
							}
						}
						.group {
							display: flex;
							align-items: center;
							justify-content: space-between;
							span {
								display: none;
								background-color: #e9e9e980;
								padding: 8px 12px;
								border-radius: 8px;
								font-family: "Manrope", sans-serif;
								font-size: 0.75rem;
								font-weight: 600;
								line-height: 12px;
								color: #26cf9f;
							}
						}
						h6 {
							font-size: 18px;
							font-weight: 500;
							line-height: 27px;
							letter-spacing: -0.02em;
							text-align: left;
						}
						p {
							font-size: 12px;
							font-weight: 400;
							line-height: 12px;
							letter-spacing: 0.01em;
							text-align: left;
							color: #9395aa;
						}
						&:hover {
							border: 1px solid $base_red;
							color: $base_red;
							box-shadow: 0px 4.5px 15.75px 1.13px #d8354e1a;

							.group {
								span {
									display: block;
								}
							}
							.icon_wrapper {
								border-color: $base_red;
							}
							p {
								color: $base_red;
							}
						}
					}
				}
				p {
					margin: 0;
					font-size: 14px;
					font-weight: 400;
					line-height: 17.5px;
					letter-spacing: 0.01em;
					text-align: center;
				}
				select {
					border: navajowhite;
					background-color: transparent;
				}
			}
		}
	}

	.modal-header {
		position: sticky;
		top: 0;
		button {
			position: absolute;
			right: 15px;
			border: 0;
			top: 10px;
			background-color: transparent;
			svg {
				height: 12px;
				width: 12px;
			}
		}
	}
	// @media (max-width: 1200px) {
	// 	width: calc(100% - 180px) !important;
	// 	left: 180px;
	// }
}

.dashboard_modal.drawer.show {
	display: flex !important;
	align-items: center;
	justify-content: flex-end;
	.modal-dialog {
		height: 100vh;
		margin-top: 0;
		width: 100%;
		min-width: 100%;
		right: 0;
		margin: 0;
		justify-content: flex-end;
		display: flex;
		align-items: center;
		z-index: 10000;
		cursor: pointer;
	}
	.modal-content {
		position: absolute;
	}
}

.dashboard_modal.show.add_event_day_task_modal .modal-dialog .modal-content {
	max-width: 1040px;
}

.fade {
	&.modal-backdrop.dashboard_modal_backdrop.show {
		&.icon_sidebar {
			width: calc(100% - 80px);
			left: 80px;
		}
		&.full_sidebar {
			width: calc(100% - 240px);
			left: 240px;
		}
	}

	&.dashboard_modal.normal.show {
		&.icon_sidebar {
			width: calc(100% - 80px);
			left: 80px;
		}
		&.full_sidebar {
			left: 240px;
			width: calc(100% - 240px);
		}
	}

	&.dashboard_modal.drawer.show {
		&.icon_sidebar {
			width: calc(100% - 80px);
			right: 0;
			left: unset;
			.modal-content {
				max-width: calc(100% - 100px);
				left: unset;
				right: 0;
			}
		}
		&.small_width {
			.modal-content {
				width: 480px;
				min-height: 100%;
				overflow-y: scroll;
			}
		}
		&.full_sidebar {
			right: 0;
			left: unset;
			width: calc(100% - 240px);
			.modal-content {
				max-width: calc(100% - 100px);
				left: unset;
				right: 0;
			}
		}
		.modal-content {
			height: 100%;
		}
	}

	@media (max-width: 1280px) {
		&.modal-backdrop.dashboard_modal_backdrop.show {
			.icon_sidebar {
				width: calc(100% - 80px);
				left: 80px;
			}
			&.full_sidebar {
				width: calc(100% - 180px);
				left: 180px;
			}
		}

		&.dashboard_modal.drawer.show {
			&.icon_sidebar {
				width: calc(100% - 80px);
				.modal-content {
					max-width: calc(100% - 50px);
				}
			}
			&.full_sidebar {
				width: calc(100% - 180px);
				.modal-content {
					max-width: calc(100% - 50px);
				}
			}
			.modal-body {
				background-color: white;
			}
		}

		&.dashboard_modal.normal.show {
			&.icon_sidebar {
				width: calc(100% - 80px);
				left: 80px;
			}
			&.full_sidebar {
				width: calc(100% - 180px);
				left: 180px;
			}
		}
	}
}

@media (max-width: 768px) {
	.fade.dashboard_modal.normal.show.icon_sidebar {
		width: 100%;
		left: 0;
	}

	.fade.dashboard_modal.drawer.show.icon_sidebar .modal-content,
	.fade.dashboard_modal.drawer.show.icon_sidebar {
		max-width: 100%;
		width: 100%;
	}

	.dashboard_modal div.modal-body button span {
		font-size: 12px;
	}

	.dashboard_modal.show.drawer .modal-dialog .modal-header {
		padding: 8px 24px !important;
		width: 100%;
	}

	.fade.modal-backdrop.dashboard_modal_backdrop.show.icon_sidebar {
		width: 100%;
		left: 0;
	}

	.dashboard_modal.show.filter_by_ceremony {
		div.modal-dialog {
			width: 100%;
			min-width: 100%;
			margin-left: 0;
			.modal-content {
				width: 90%;
				margin: auto;
				left: 0;
				padding: 0;
			}
		}
	}
	.dashboard_modal.add_vendor_modal.show .add_vendor_form {
		.input_row {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			padding: 0px 20px;
		}
		&edit_form {
			flex-direction: column;
			display: flex;
			padding: 0px;
		}
	}
}

@media (max-width: 1100px) {
	.dashboard_modal.show.add_event_day_task_modal .modal-dialog {
		.modal-content .modal-body {
			width: 100%;
			div.element_group {
				display: grid;
				width: 100%;
				grid-template-columns: repeat(2, 1fr);
			}
			div.element_group > div {
				width: 100% !important;
				max-width: 100% !important;
			}
			.checklist_group .checklist_row .task_element.custom_input_view {
				min-width: 100% !important;
			}
		}
	}
}

@media (max-width: 560px) {
	.dashboard_modal.add_vendor_modal.show {
		div.modal-dialog .modal-content .modal-body {
			width: 100%;
			padding: 0px 20px;

			.add_vendor_form {
				width: 100%;
				.input_row {
					flex-direction: column;
					display: flex;
					padding: 0px;
				}
				.form_element.select.custom {
					max-width: unset;
				}
				.modal_footer {
					padding-right: 0;
				}
			}
		}
	}
}
