@import "./variables.scss";

.asoebi_homepage {
	position: relative;
	.heart {
		position: absolute;
	}
	.heart1 {
		left: 0;
	}

	a:hover {
		text-decoration: none;
	}
	.link {
		text-decoration: underline;
	}

	.heart2 {
		right: 0px;
		bottom: 40%;
	}

	.heart3 {
		top: 60%;
		right: 0px;
		width: fit-content;
		height: fit-content;
	}

	.heart4 {
		bottom: 0;
		right: 0;
	}

	.asoebi_hero {
		color: black;
		.hero_text {
			p {
				font-size: 18px;
			}
		}
		h1 {
			font-weight: bold;
		}
		@media (max-width: $bp-tab) {
			h1 {
				font-size: 32px;
				text-align: center;
			}
			p {
				text-align: center;
				margin: 10px 0px;
			}
			button {
				margin: auto;
				// background-color: white;
				// 	color: $primary;
			}
		}
	}

	.pricing {
		.pricing_card {
			border-radius: 20px;
			justify-content: space-between;
			color: black;
			h3 {
				font-size: 60px;
				text-align: center;
			}
			h6 {
				font-weight: bold;
				text-align: center;
				font-weight: 18px;
			}
			p {
				font-size: 16px;
				color: black;
			}
			.amount {
				text-align: center;
			}
			.text {
				p {
					margin-bottom: 0px;
					font-size: 15px;
				}
			}
			.icon_wrapper {
				height: 32px;
				width: 32px;
				margin-right: 5px;
				background-color: rgb(249, 180, 180);
				border-radius: 50%;
				display: flex;
				align-items: center;
				justify-content: center;
				svg {
					height: 24px;
					width: 24px;
				}
			}
			button {
				width: 100%;
				background-color: $primary;
				height: 50px;
				color: white;
				border: 0px;
				font-weight: bold;
				svg {
					transform: rotate(-90deg);
				}
			}
		}
	}

	.asoebi_view {
		img {
			width: 100%;
			object-fit: cover;
			border-radius: 10px;
			object-position: center;
			transition: all 1s;
			z-index: 10;
			position: relative;
		}
		img:hover {
			transform: scale(1.05);
		}
		@media (max-width: $bp-tab) {
			margin-bottom: 30px;
		}
	}

	.form_img {
		height: 100%;
		width: 100%;
		transition: all 1s;
		z-index: 10;
		position: relative;
	}

	.sub_title {
		font-family: "Montserrat", sans-serif;
		font-style: normal;
		font-weight: 700;
		font-size: 32px;
		line-height: 40px;
		letter-spacing: 0.02em;
		color: #545565;
		@media (max-width: $bp-tab) {
			font-size: 24px;
			line-height: 32px;
		}
	}

	.container p {
		font-family: "Montserrat", sans-serif;
		font-style: normal;
		font-weight: 500;
		font-size: 20px;
		line-height: 28px;
		letter-spacing: 0.02em;
		color: #cb3050;
		max-width: 647px;
		margin-bottom: 1.75rem;
		@media (max-width: $bp-tab) {
			font-size: 16px;
			line-height: 24px;
			margin-bottom: 0rem;
		}
	}

	.form_img:hover {
		transform: scale(1.05);
	}
	.create_icon {
		height: 40px;
		width: 40px;
	}

	@media (max-width: $bp-tab) {
		h2 {
			font-size: 24px;
		}

		h4 {
			font-size: 18px;
		}
	}

	button.btn {
		font-family: "Montserrat", sans-serif;
		font-style: normal;
		font-size: 16px;
		line-height: 20px;
		letter-spacing: 0.02em;
		padding: 1.2rem 1.5rem;
		font-weight: 600;
		width: fit-content;
		display: flex;
		border-radius: 15px;
		align-items: center;
	}

	button:hover {
		opacity: 0.9;
	}

	.faq {
		margin: 15px auto;
		border-radius: 15px;
		width: 100%;
		svg {
			transition: all 1s;
		}
		&.active {
			svg {
				transform: rotate(180deg);
			}
		}
		.faq_content {
			padding-bottom: 20px;
		}
		button {
			border: 0px;
			background-color: transparent;
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			@media (max-width: $bp-lg-phone) {
				svg {
					height: 20px;
					width: 20px;
				}
			}
		}
		h6 {
			font-size: 16px;
			font-weight: bold;
			text-align: left;
			width: 80%;
		}
		p {
			font-size: 16px;
			width: 100%;
			min-width: 100%;
			margin-bottom: 0;
		}
	}
}
