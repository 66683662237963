@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400,500&family=Manrope:wght@400,500,600,700&display=swap");
@import "./variables.scss";

.invite_collaborator_modal {
	font-family: "Inter", sans-serif;
	.modal-dialog {
		margin-top: 25%;
		left: 10%;
		.modal-content {
			border-radius: 6px;
			border: 1px solid #5455660d;
			width: 65%;
			box-shadow: 0px 4px 20px 0px #0000000d;
		}
	}

	.modal-body {
		width: 100%;

		.invite_modal_content {
			flex-direction: column;
			display: flex;
			align-items: center;
			gap: 16px;
		}

		.invite_modal_content,
		.input_group,
		.top_row {
			width: 100%;
		}
		.top_row {
			button {
				padding: 8px 16px;
				svg {
					height: 12px;
					width: 12px;
				}
				&.invite_btn {
					svg {
						height: unset;
						width: unset;
					}
				}
			}
		}
		.dropdown_toggle {
			display: flex;
			text-wrap: nowrap;
			white-space: nowrap;
			background-color: #fbfbfb;
			font-size: 12px;
			height: 36px;
			min-height: 36px;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 8px 16px !important;
		}

		.input_view {
			border: 1px solid #30303026;
			width: 100%;
			padding: 10px;
			border-radius: 6px;
			margin-right: 15px;
			height: 40px;
			display: flex;
			align-items: center;
			input {
				border: none;
				width: 100%;
				font-size: 12px;
				font-family: "Inter", sans-serif;
			}
			svg {
				height: 12px;
				width: 12px;
				margin-right: 6px;
			}
			input::placeholder {
				font-size: 12px;
				font-family: "Inter", sans-serif;
			}
		}

		.action_button_group {
			display: flex;
			align-items: center;
			gap: 20px;
		}

		.form_element.input2 input {
			height: 40px;
			padding-top: 0px !important;
		}

		.input_view:focus {
			border: 1px solid #4285f4;
			box-shadow: 0 0 0 0.1rem rgba(203, 48, 80, 0.25);
		}

		.form_element.input2 input::placeholder {
			font-size: 12px;
			font-family: "Inter", sans-serif;
			top: 12px;
		}

		.button_group button,
		.button_group .dropdown_toggle {
			font-size: 12px;
			display: flex;
			align-items: center;
			flex-wrap: nowrap;
			text-wrap: nowrap;
			white-space: nowrap;
			height: 36px;
			padding: 8px 16px !important;
			gap: 10p;
		}
	}

	.top_row {
		display: flex;
		align-items: center;
		justify-content: space-between;
		.button_group {
			display: flex;
			align-items: center;
			gap: 10px;
			button {
				padding: 6px;
				border-radius: 4px;
				border: 0;
			}

			button.invite_btn {
				background-color: #031729;
				color: #fff;
				gap: 10px;
			}
		}
		button.permission {
			background-color: #fbfbfb !important;
			color: black !important;
		}
	}
	.input_group {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		gap: 10px;
	}
	img {
		height: 280px;
		width: 280px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: auto;
	}
	font-family: "Inter", sans-serif;
	.text_content {
		border: 1.11px solid #5455660d;
		padding: 16px 8px 0px;
		border-radius: 8px;
		margin-bottom: 12px;
		h4 {
			font-size: 24px;
			font-weight: 600;
			color: #031729;
			line-height: 36px;
		}
		.text_1 {
			margin-bottom: 20px;
			color: #031729;
		}
		p {
			font-weight: 400;
			color: $slate_gray;
			font-size: 12px;
			span {
				color: $primary;
			}
		}
		letter-spacing: -0.02em;
		text-align: center;
	}
}

@media (max-width: 1300px) {
	.modal div.modal-dialog {
		max-width: 50%;
	}
}

@media (max-width: 768px) and (min-width: 561px) {
	div.modal div.modal-dialog {
		width: 100%;
		max-width: 50%;
	}

	.welcome_modal .wrapper img {
		width: 80%;
		height: 80%;
	}

	.welcome_modal .wrapper .text_content h4 {
		font-size: 1rem;
	}

	.welcome_modal .button_group button {
		font-size: 0.75rem;
	}

	section.welcome_modal {
		padding: 0px;
		margin: 10px 0;
	}
}

@media (max-width: 560px) {
	div.modal div.modal-dialog {
		width: 100%;
		max-width: 95%;
	}

	.welcome_modal .wrapper img {
		width: 100%;
		height: 100%;
	}

	.welcome_modal .wrapper .text_content h4 {
		font-size: 1rem;
	}

	.welcome_modal .button_group button {
		font-size: 0.75rem;
	}

	section.welcome_modal {
		padding: 0px;
		margin: 10px 0;
	}

	div.invite_collaborator_modal .modal-dialog .modal-content {
		width: 100%;
	}

	div.invite_collaborator_modal .modal-dialog {
		left: 0;
	}
}
