@import "./variables.scss";

.default_view {
	border: 0.5px solid #e72e50;
	box-shadow: 0px 4px 20px 0px #0000000d;
	box-shadow: 0px 14.4px 72px 0px #0000000d;
	border: 3px solid #ffffff;
	width: 480px;
	font-family: "Inter", sans-serif;
	border: 0.5px solid #e72e50;
	padding: 25px 0;
	border-radius: 6px;
	background-color: white;
	margin: 100px auto 150px;
	height: 240px;
	.icon_wrapper {
		border: 1px solid #5455660d;
		border-radius: 2px;
		width: fit-content;
		margin: auto;
		height: 40px;
		width: 40px;
		border-radius: 2px;
		display: flex;
		align-items: center;
		svg {
			height: 20px;
			width: 20px;
			margin: 10px;
			display: flex;
			align-items: center;
			justify-content: center;
			margin: auto;
			stroke: $base_red;
		}
	}
	.text {
		border-radius: 8px;
		padding: 16px 12px;
		h4 {
			font-size: 1rem;
			text-align: center;
			color: #000;
			font-family: "Inter", sans-serif;
			font-size: 0.875rem;
			margin-bottom: 5px;
			font-weight: 500;
			letter-spacing: -0.02em;
		}
		p {
			text-align: center;
			font-size: 1rem;
			font-size: 0.75rem;
			font-weight: 400;
			line-height: 16.8px;
			letter-spacing: -0.025em;
			text-align: center;
			color: #606060;
			span {
				font-weight: bold;
				color: black;
			}
		}
	}
	button {
		height: 36px;
		padding: 12px 12px 10px;
		border-radius: 6px;
		font-family: "Manrope", sans-serif;
		font-size: 0.75rem;
		font-weight: 600;
		line-height: 12px;
		display: flex;
		align-items: center;
		margin: auto;
		text-align: center;
		background-color: $base_red;
		color: #fff;
		border: none;
		gap: 5px;
		svg {
			height: 16px;
			width: 16px;
		}
	}
	@media (max-width: 768px) {
		width: 85%;
	}
}
