@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@400,600&display=swap");
@import "./variables.scss";

.onboarding_layout main .content.auth {
	display: flex;
	flex-direction: column;
	gap: 50px;
	justify-content: unset;
	padding: 40px;
	height: 100%;
	overflow-y: scroll;

	.auth_tab {
		background-color: $gray_50;
		display: flex;
		align-items: center;
		font-family: "Inter", sans-serif;
		font-size: 1rem;
		padding: 4px;
		border-radius: 8px;
		button {
			padding: 0px 12px 0px 12px;
			height: 32px;
			font-size: 0.8125rem;
			background-color: $gray_50;
			border: none;
			font-weight: 500;
			color: #606060;
			&.active {
				border-radius: 4px;
				background-color: $white;
				color: $dark_red;
				box-shadow: 0px 1px 2px 0px #0000000d;
			}
		}
	}
	.auth_content {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		height: 100%;
		h4 {
			font-family: "Inter", sans-serif;
			font-size: 1.25rem;
			font-weight: 500;
			color: #606060;
			letter-spacing: -0.025em;
			margin-bottom: 20px;
			margin-top: 30px;
		}
		form {
			width: 400px;
			margin-bottom: 100px;
			input {
				width: 100%;
			}
			.divider {
				display: flex;
				align-items: center;
				margin: auto;
				width: 100%;
				justify-content: center;
				position: relative;
				hr {
					background-color: $dark_50;
					width: 100%;
				}
				span {
					font-family: "Inter", sans-serif;
					font-weight: 400;
					font-size: 0.8125rem;
					color: #9395aa;
					text-align: center;
					margin: auto;
					display: flex;
					justify-content: center;
					position: absolute;
					background-color: white;
					padding: 0px 10px;
				}
			}
			.form_element.input {
				position: relative;
				button {
					background-color: transparent;
					bottom: 5px;
				}
			}

			.form_element.input label {
				position: absolute;
				font-size: 10px;
				color: #606060;
				left: 14px;
				top: 3px;
				font-family: "Inter", sans-serif;
				font-weight: 400;
			}

			.form_element.input input:focus {
				border: 1px solid #4285f4;
				box-shadow: none;
			}

			.form_element.input input {
				border-radius: 8px;
				height: 54px;
				border: 1px solid #30303026;
				padding-bottom: 0px;
				font-size: 0.8125rem;
				font-weight: 500;
				font-family: "Inter", sans-serif;
				padding-top: 15px;
			}
			.form_element.input input::placeholder {
				color: #d9d9d9;
				font-size: 0.8125rem;
				letter-spacing: -0.02px;
				position: absolute;
				bottom: 6px;
			}

			.form_element.input input:focus::placeholder {
				color: #031729;
			}

			.form_element.input input:focus + label {
				font-weight: 400;
				color: $light_gray;
			}

			button {
				background-color: $base_red;
				color: white;
				height: 42px;
				border-radius: 8px;
				border: none;
				font-family: "Inter", sans-serif;
				font-weight: 600;
				font-size: 0.8125rem;
				&.google_btn {
					background-color: $dark_50;
					color: #606060;
					position: relative;
					font-size: 0.8125rem;
					span {
						position: absolute;
						left: 15px;
					}
				}
			}
		}
		.privacy_note {
			width: 45%;
			text-align: center;
			font-family: "Inter", sans-serif;
			font-weight: 500;
			font-size: 0.75rem;
			line-height: 18px;
			color: #d9d9d9;
			a {
				color: #9395aa;
			}
		}
		.copyright {
			color: #828282;
			font-weight: 400;
			font-family: "Inter", sans-serif;
			font-size: 1rem;
			line-height: 24px;
		}
		@media (min-width: 1500px) {
			height: 75%;
		}
		.button_group {
			display: flex;
			width: 50%;
			justify-content: space-between;
			align-items: center;
			@media (min-width: 1500px) {
				width: 35%;
			}
			.underline_text {
				text-decoration: underline;
				border: none;
				background-color: transparent;
				font-size: 12px;
				color: #222222;
				margin: 0;
			}
			button {
				height: 42px;
				border-radius: 8px;
				font-size: 0.8125rem;
				line-height: 18px;
				font-family: "Inter", sans-serif;
				font-weight: 400;
				width: 105px;
				&.prev_btn {
					background-color: transparent;
					border: 1px solid #5455660d;
					color: #606060 !important;
					&.stage_1 {
						border: none;
					}
				}
				&.next_btn {
					background-color: $base_red;
					color: white;
					border: none;
					display: flex;
					align-items: center;
					padding: 0px 15px;
					justify-content: center;
					&.loading {
						width: 120px;
					}
				}
			}
			.skip_btn {
				border: none;
				background-color: transparent;
				color: $base_red;
			}
		}
	}
}

@media (max-width: 768px) {
	.onboarding_layout main .content.auth .auth_content form {
		width: 100%;
	}

	.onboarding_layout main .content.auth .auth_content .privacy_note {
		width: 100%;
	}
	.onboarding_layout main .content.auth {
		padding: 40px 25px;
	}
	.onboarding_layout.auth_page aside .aside_content {
		justify-content: center;
	}
	.onboarding_layout.auth_page aside div.aside_content p.title {
		position: relative;
		width: 90%;
		left: unset;
		text-align: center;
		bottom: unset;
	}
}
