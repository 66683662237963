@import "./variables.scss";

.select_option {
	border: 1px solid #5455660d;
	padding: 24px 32px;
	border-radius: 8px;
	background-color: white;
	width: fit-content;
	display: flex;
	align-items: center;
	gap: 20px;

	&:hover {
		border: 2px solid $base_red;
		cursor: pointer;
		.svg_wrapper {
			svg {
				display: block;
			}
		}
		span {
			border: 1px solid $base_red;
			svg {
				stroke: $base_red;
			}
		}
		div {
			color: $base_red;
		}
	}

	span {
		border: 1px solid #5455660d;
		border-radius: 4px;
		padding: 10px;
		svg {
			display: flex;
		}
	}

	div {
		display: flex;
		flex-direction: column;
		font-family: "Inter", sans-serif;
		color: #303030;
		h4 {
			font-weight: 600;
			font-size: 1.125rem;
		}
		p {
			font-weight: 400;
			font-size: 1rem;
		}
	}

	.svg_wrapper {
		width: 24px;
		height: 24px;
		svg {
			display: none;
		}
	}
}
