@import './variables.scss';

.loading {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fceded;
  img {
    width: 100%;
  }
  .heart1,
  .heart2,
  .heart3,
  .heart4 {
    position: absolute;

    img.logo-img {
      height: 100%;
      width: 100%;
    }
  }

  .heart1 {
    top: 50px;
    left: 0px;

    @media screen and (max-width: 767px) {
      height: 80px;
    }
  }

  .heart2 {
    top: 15rem;
    right: 0px;

    @media screen and (max-width: 767px) {
      height: 80px;
    }
  }

  .heart3 {
    bottom: 10rem;
    left:0rem;

    @media screen and (max-width: 767px) {
      bottom: 5rem;
      left: 2rem;
      height: 70px;
    }
  }

  .heart4 {
    bottom: 8rem;
    right: 3rem;

    @media screen and (max-width: 767px) {
      right: 2rem;
      bottom: 6rem;
      height: 60px;
    }
  }
}
