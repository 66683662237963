.App {
	text-align: center;
}

/* .text-sample{
  width: 100% ;
} */
button,
button:focus {
	outline: none;
}

.mobile-calculate-btn {
	display: none !important;
}
.lds-ring {
	display: inline-block;
	position: relative;
	width: 5px;
	height: 5px;
}
.lds-ring.dark div {
	border: 4px solid #cb3050;
	border-color: #cb3050 transparent transparent transparent !important;
}
.lds-ring div {
	box-sizing: border-box;
	display: block;
	position: absolute;
	width: 4px;
	height: 25px;
	margin: -10px 0 10px 20px;
	border: 4px solid #e5e5e5;
	opacity: 0.5;
	border-radius: 50%;
	animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	border-color: #e5e5e5 transparent transparent transparent !important;
}

.lds-ring div:nth-child(1) {
	animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
	animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
	animation-delay: -0.15s;
}
@keyframes lds-ring {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}
.default_bg {
	background-image: linear-gradient(5deg, #cb3050, #951382);
	height: 100%;
	width: 100%;
}

.loading_layout {
	height: 100%;
	width: 100%;
}

.spinner {
	animation: rotate 2s linear infinite;
	z-index: 2;
	margin: -25px 0 0 -25px;
	width: 100px;
	height: 100px;

	& .path {
		stroke: white;
		stroke-linecap: round;
		animation: dash 1.5s ease-in-out infinite;
	}
}

@keyframes rotate {
	100% {
		transform: rotate(360deg);
	}
}

@keyframes dash {
	0% {
		stroke-dasharray: 1, 150;
		stroke-dashoffset: 0;
	}
	50% {
		stroke-dasharray: 90, 150;
		stroke-dashoffset: -35;
	}
	100% {
		stroke-dasharray: 90, 150;
		stroke-dashoffset: -124;
	}
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

@media screen and (max-width: 650px) {
	.mobile-calculate-btn {
		display: inline !important;
	}
	.desktop-calculate-btn {
		display: none !important;
	}
}
