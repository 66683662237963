.vendor_loader {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.vendor_picker {
	display: flex;
	flex-direction: column;
	position: relative;
	.vendor_name {
		gap: 4px;
		align-items: center;
		display: flex;
		height: 26px;
		text-wrap: nowrap;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		max-width: fit-content;
		min-width: fit-content;
		background-color: transparent;
		span {
			color: #fff;
			height: 16px;
			min-height: 16px;
			width: 16px;
			min-width: 16px;
			border-radius: 24px;
			font-size: 10px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		.icon_wrapper {
			height: 16px;
			min-height: 16px;
			width: 16px;
			min-width: 16px;
			background-color: white;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 50%;
			svg {
				height: 10px;
				width: 10px;
				stroke: black;
			}
		}
	}
	button {
		border: 1px solid #5455660d;
		padding: 4px 8px;
		height: 24px;
		border-radius: 24px;
		// min-width: 100px;
		color: #404040;
		font-size: 11px;
		display: flex;
		align-items: center;
		font-weight: 500;
		background: #fff;
	}
	.vendors_list {
		background-color: #fff;
		border: 1px solid grey;
		border-radius: 6px;
		position: absolute;
		top: 30px;
		box-shadow: 0px 4.5px 15.75px 1.13px #3030301a;
		border: 1px solid #5455660d;
		width: 240px;
		max-width: 240px;
		left: -50px;
		padding-top: 5px;
		z-index: 100;
		.vendor_list_group {
			padding: 8px;
			.unassigned {
				background: #fbfbfb;
				border-radius: 8px;
				height: 34px;
				width: 100%;
				padding: 8px;
				color: #404040;
				font: 11px;
				font-weight: 500;
				display: flex;
				align-items: center;
				gap: 10px;
				margin: 10px 0;
				cursor: pointer;
			}
			.vendors_list_view {
				display: flex;
				flex-direction: column;
				gap: 5px;
				.vendor {
					display: flex;
					align-items: center;
					border: 1px solid #5455660d;
					border-radius: 24px;
					padding: 4px 8px;
					span {
						color: #fff;
						height: 16px;
						width: 16px;
						border-radius: 24px;
						font-size: 10px;
					}
				}
			}
		}
		.vendor_input_search {
			padding: 0px 15px;
			background: #fbfbfb;
			margin: 0px 6px;
			border-radius: 8px;
			display: flex;
			align-items: center;
			border: 1px solid #5455660d;
			height: 35px;
			margin-bottom: 6px;
			input {
				font-size: 12px;
				border: none !important;
				background-color: #fbfbfb;
			}
			svg {
				height: 14px;
				width: 14px;
				stroke: #888c8f;
			}
		}
		hr {
			margin: 10px 0px 0px;
			width: 100%;
			border-top: 1px solid #5455660d;
		}
		button {
			font-size: 12px;
			display: flex;
			align-items: center;
			justify-content: center;
			width: fit-content !important;
			padding: 5px;
			margin: 0px 6px;
			background-color: transparent;
			border: none;
			font-weight: 500;
			gap: 5px;
			color: #404040;
			padding: 0px 4px;
			svg {
				height: 14px;
				width: 14px;
				stroke: #000;
			}
		}
	}
}
