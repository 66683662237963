@import "./variables.scss";

.dashboard_modal.show.add_planning_timeline_modal .modal-dialog .add_checklist_modal div.checklist_group div.checklist_row {
	.task_element.custom_input_view.small_element {
		max-width: 100px;
		min-width: 100px;
	}
	div.task_element.custom_input_view {
		min-width: 200px;
		max-width: 200px;
	}

	div.task_element.custom_input_view {
		max-width: 200px;
		min-width: 200px;

		&.note {
			min-width: 255px;
		}
	}
}

.add_budget_category_modal {
	.modal-body {
		width: 100%;
	}

	.add_budget_category {
		display: flex;
		flex-direction: column;
		width: 100%;

		.budget_category_input {
			position: relative;
			.input_group button svg {
				stroke: #a7a8b0;
			}
			@media (min-width: 1300px) {
				width: 50%;
			}
			.budget_category_icon_dropdown {
				position: absolute;
				box-shadow: 0px 4.5px 15.75px 1.13px #3030301a;
				border: 0.5px solid #dddfe380;
				background-color: #fff;
				left: 0;
				height: 260px;
				width: 400px;
				border-radius: 6px;
				z-index: 1;
				.tab {
					display: flex;
					border-bottom: 1px solid #5455660d;
					height: 47px;
					width: 100%;
					align-items: center;
					gap: 20px;
					padding: 0px 16px;
					button {
						border: 0px;
						color: #404040;
						font-family: "Inter", sans-serif;
						font-weight: 500;
						font-size: 12px;
						padding: 0;
						background-color: transparent;
						&.active {
							border-bottom: 2px solid #031729;
							height: 100%;
							border-radius: 1px;
						}
					}
				}
				.tab_content {
					padding: 24px 16px;
					.budget_icons_grid {
						display: grid;
						grid-template-columns: repeat(8, 1fr);
						gap: 10px;
					}

					button {
						border: none;
						background-color: transparent;
						padding: 0;
					}

					.upload_custom_image {
						display: flex;
						align-items: center;
						margin: auto;
						justify-content: center;
						height: 170px;
						img {
							height: 100%;
							width: 100%;
							object-fit: contain;
						}
						button {
							position: absolute;
							right: 10px;
							bottom: 10px;
							color: white;
							font-weight: 500;
							color: #fff;
							background-color: #e72e50;
							padding: 8px 16px;
							font-family: "Inter", sans-serif;
							font-size: 13px;
							line-height: 18.2px;
							letter-spacing: -0.025em;
							border-radius: 6px;
							border: none;
						}
					}
				}
			}
		}
		.upload_image {
			width: 32px;
			height: 32px;
			border-radius: 50%;
			background-color: #fafafc;
			padding: 10px;
			border: 0;
			display: flex;
			cursor: pointer;
			align-items: center;
			justify-content: center;
			min-height: 32px;
			min-width: 32px;
			position: absolute;
			right: 15px;
			z-index: 1;
			bottom: 12px;
			padding: 0;
			object-fit: cover;
			overflow: hidden;
			img {
				height: 100%;
				width: 100%;
			}
			svg {
				min-height: 12px;
				min-width: 12px;
				position: relative;
				z-index: 1;
			}
			&.upload_category_image {
				left: unset;
				right: unset;
				bottom: unset;
				height: 50px;
				width: 50px;

				input {
					position: absolute;
					width: 100%;
					height: 100%;
					z-index: 100;
					cursor: pointer;
					&::-webkit-file-upload-button {
						visibility: hidden;
					}
				}
				img {
					height: 100%;
					width: 100%;
				}
			}
		}
		.input_group {
			width: 70%;
			position: relative;
			.budget_dropdown {
				display: flex;
				flex-direction: column;
				button {
					position: relative;
					padding: 8px 14px;
					border: none;
					display: flex;
					align-items: center;
					justify-content: space-between;
					width: 100%;
					background-color: white;
					color: #000;
					font-family: "Inter", sans-serif;
					font-size: 12px;
					font-weight: 500;
					line-height: 18px;
					letter-spacing: -0.025em;
					text-align: center;

					&:hover {
						background-color: #fbfbfb;
						border-radius: 8px;
						color: $base_red;
						position: relative;
						&::after {
							content: url("../assets/img/checkIconRed.svg");
							position: absolute;
							right: 5px;
							transform: scale(0.7);
						}
					}
				}
			}
		}

		.add_cost_items {
			.top_row {
				display: flex;
				align-items: center;
				justify-content: space-between;

				h4 {
					//styleName: inter/sm/medium;
					font-family: "Inter", sans-serif;
					font-size: 13px;
					font-weight: 500;
					line-height: 18.2px;
					letter-spacing: -0.025em;
					text-align: left;
				}

				button {
					background: #fafafc;
					border-radius: 6px;
					border: none;
					padding: 6px 8px;
					font-family: "Inter", sans-serif;
					font-size: 12px;
					font-weight: 500;
					line-height: 18px;
					letter-spacing: -0.025em;
					text-align: center;
					color: #404040;
					svg {
						height: 12px;
						width: 12px;
					}
				}
			}
			.add_cost_item_group {
				display: flex;
				flex-direction: column;
				gap: 16px;
				margin-top: 20px;
				.cost_item_group {
					display: flex;
					align-items: center;
					flex-direction: row;
					gap: 10px;
					.vendor_input {
						min-width: 170px;
						position: relative;
						@media (max-width: 768px) {
							min-width: 40%;
						}
						span {
							display: flex;
							align-items: center;
							color: white;
							background-color: #e845ba;
							font-size: 9px;
							height: 15px;
							width: 15px;
							border-radius: 50%;
							justify-content: center;
							position: relative;
							left: 2px;
							z-index: 10;
							bottom: -1.5px;
						}
						input {
							padding-left: 35px;
							font-size: 11px;
						}
						.vendor_input_wrapper {
							border-radius: 8px;
							height: 54px;
							border: 1px solid #30303026;
							padding-bottom: 0px;
							font-size: 12px;
							font-weight: 500;
							letter-spacing: -0.025em;
							padding-top: 15px;
							padding: 4px 15px;
							label {
								font-size: 10px;
								color: #404040;
								left: 14px;
								top: 3px;
								margin-bottom: 0;
								font-weight: 400;
							}
						}
					}
					.action_button_group {
						display: flex;
						align-items: center;
						gap: 20px;
						button {
							border: none;
							box-shadow: 0px 1px 10px 0px #0000000d;
							border-radius: 4px;
							height: 24px;
							width: 24px;
							background-color: #fff;
							display: flex;
							align-items: center;
							justify-content: center;
							svg {
								height: 12px;
								width: 12px;
								stroke: #404040;
							}
						}
					}
					.cost_item_input_group {
						display: flex;
						width: 100%;
						gap: 10px;
					}
				}
			}
		}
	}

	.modal_footer {
		border-top: 1px solid #5455660d;
		width: 100%;
		padding-top: 10px;
		.button_group {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			gap: 40px;
			button {
				height: 36px;
				font-family: "Inter", sans-serif;
				font-size: 13px;
				line-height: 18.2px;
				letter-spacing: -0.025em;
				border-radius: 6px;
				padding: 0px 12px;
				&.cancel_btn {
					font-weight: 400;
					background-color: #fff;
					border: 1px solid #5455660d;
					color: #404040;
				}
				&.create_btn {
					font-weight: 600;
					color: #fff;
					background-color: #e72e50;
					padding: 0px 24px;
				}
			}
		}
	}

	@media (max-width: 768px) {
		.add_budget_category .add_cost_items .add_cost_item_group .cost_item_group {
			flex-direction: column;
		}

		.add_budget_category .input_group {
			width: 100%;
			.add_cost_items .add_cost_item_group .cost_item_group .cost_item_input_group {
				display: flex;
				gap: 10px;
				width: 100%;
			}
		}

		.dashboard_modal.show .modal-dialog .modal-content .modal-body {
			padding: 30px 16px;
		}
	}
}
