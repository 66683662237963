@import './variables.scss';

.edit_profile {
	h1 {
		margin-bottom: 4rem;
		font-size: 24px;
		font-weight: 600;
	}
	form {
		gap: 20px;
		display: flex;
		flex-direction: column;
		width: 80%;
	}

	.button_wrapper {
		display: flex;
		align-items: flex-end;
		justify-content: flex-end;
		margin: 4rem 0px 10rem;
	}
	button.bg_red {
		font-weight: 600;
		font-size: 20px;
		// padding: 8px 34px;
		width: 225px;
		height: 46px;
	}
	@media (max-width: $bp-tab) {
		form {
			width: 100%;
			gap: 20px;
		}
		button.bg_red {
			margin: auto;
			font-size: 16px;
		}
	}
}
