@import "./variables.scss";

// header
.dashboard_header .header_content {
	padding: 36px 60px 24px 60px;
	.mobile_menu {
		display: none;
	}
}
.dashboard_header .logo-img {
	height: 43px;
	width: 156px;
}

.dashboard_header nav {
	gap: 32px;
	@media (max-width: 500px) {
		display: none !important;
	}
}

.dashboard_header nav a {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 27px;
	display: flex;
	align-items: center;
	color: $base;
}

.dashboard_header .nav_profile_icon_group {
	gap: 20px;
	align-items: center;
	.profile {
		height: 39px;
		width: 39px;
		background-color: $primary;
		color: white;
		border-radius: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		font-family: "Poppins";
		font-style: normal;
		font-weight: 500;
		font-size: 20px;
		line-height: 30px;
	}
}

.dashboard_header nav a:hover {
	text-decoration: none;
}

.dashboard_header nav .profile {
	height: 39px;
	width: 39px;
	background-color: $primary;
	color: white;
	border-radius: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: "Poppins";
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 30px;
}

.pricing_payment_form {
	.plan_title_group {
		button {
			left: 0;
		}
		h5 {
			text-transform: capitalize;
		}
	}
}

.dashboard {
	&.no_sidebar {
		.dashboard_layout_wrapper {
			.dashboard_content {
				width: 100%;
			}
		}
	}
}

// sidebar
.dashboard.open_menu {
	.dashboard_layout_wrapper {
		position: relative;
		@media (max-width: 600px) {
			position: absolute;
			width: 100%;
			height: 100%;
		}
	}
}

.dashboard aside {
	width: 24%;
	display: flex;
	flex-direction: column;
	align-items: center;
	@media (max-width: $bp-tab) {
		width: 34%;
	}
	@media (max-width: 500px) {
		display: block;
		position: absolute;
		top: 0;
		background: white;
		width: 100%;
		height: 100%;
		padding: 40px 30px;
		z-index: 100;
	}
	ul.sidebar_links {
		@media (max-width: 1024px) {
			width: 250px;
			li a {
				font-size: 16px;
				line-height: 24px;
				width: fit-content;
				margin-left: 20px;
			}
			button {
				font-size: 1rem;
			}
		}
		@media (max-width: $bp-phone) {
			li a {
				margin-left: 0px;
			}
		}
	}

	.cta {
		display: flex;
		flex-direction: column;
		align-items: baseline;
		margin: 0px;
		width: 220px;
		@media (max-width: 1024px) {
			width: 180px;
			button.dashboard_button,
			a.dashboard_button {
				font-size: 16px;
			}
		}
		@media (max-width: $bp-phone) {
			width: 180px;
			button.dashboard_button,
			a.dashboard_button {
				font-size: 16px;
			}
			margin-left: 15px;
		}
		.dashboard_button {
			border: 0px;
			background-color: transparent;
			margin-bottom: 30px;
			color: $base;
			font-size: 1.2rem;
			font-family: "Poppins";
			font-style: normal;
			font-weight: 500;
			line-height: normal;
			text-decoration: none;
			img {
				margin-right: 20px;
				height: 24px;
				width: 24px;
			}
		}
	}
}

.footer .bottom.row.footer-content {
	display: none;
}

.dashboard.asoebi {
	aside {
		width: 20%;
	}
	.dashboard_content {
		width: 80%;
		min-height: 500px;
		.guest_dashboard {
			.top_content {
				width: 100%;
				flex-direction: row;
				margin: 0px 0px 30px;
				align-items: center;
				h1 {
					text-transform: capitalize;
				}
			}
		}
		@media (max-width: $bp-tab) {
			width: 100%;
			.guest_dashboard {
				.top_content {
					margin: 20px 0px 30px;
					h1 {
						font-size: 26px;
					}
				}
			}
		}
		@media (max-width: $bp-phone) {
			.guest_dashboard {
				.top_content {
					flex-direction: column;
					margin-bottom: 40px;
					gap: 10px;
					h1 {
						font-size: 22px;
					}
					align-items: flex-start;
				}
			}
		}
	}
}

@media (max-width: $bp-dsk) {
	.dashboard_header .header_content {
		padding: 0px 20px;
		height: 80px;
		.mobile_menu {
			border: 0px;
			background-color: transparent;
			height: 20px;
			width: 20px;
			justify-content: center;
			align-items: center;
			padding: 0px;
		}
	}
}

@media (max-width: $bp-dsk-one-pixel-down) and (min-width: $bp-tab) {
	.dashboard_header .header_content {
		.mobile_menu.tablet_view {
			display: flex;
		}
	}

	.dashboard aside {
		width: 34%;
	}
}
// for devices like surface duo.
@media (max-width: $bp-lg-phone) and (min-width: $bp-phone) {
	.dashboard aside {
		width: 40%;
	}

	.my-wedding .event_overview_grid {
		div.grid_row_1 {
			flex-direction: column;
			grid-gap: 25px;
			gap: 25px;
			.item_card {
				width: 100%;
			}
		}
		div.card_row {
			gap: 25px;
		}
		div.card.tips_ideas,
		div.card.quotes {
			width: 100%;
		}
	}
}

.dashboard aside .profile-icon {
	margin: 0px auto 30px;
	position: relative;
	border-radius: 100%;
	max-height: 181px;
	max-width: 265px;
	height: 150px;
	background: #fceded;
	width: 150px;
	.profile_banner {
		height: 150px;
		width: 150px;
		border-radius: 100%;
		display: flex;
		position: relative;
		bottom: 0;
	}
}
.dashboard aside .profile-icon img {
	border-radius: 100%;
	max-height: 181px;
	max-width: 265px;
	height: 150px;
	width: 150px;
}
.dashboard aside .profile-icon div {
	position: absolute;
	bottom: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
}
.dashboard aside .profile-icon button {
	background: rgba(203, 48, 80, 0.65);
	border-radius: 5px;
	border: none;
	font-family: "Poppins";
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 18px;
	color: #fceded;
	padding: 6px 8px 5px 9px;
}

.dashboard aside ul {
	padding: 0px;
	margin: 0px;
	width: 250px;
}
.dashboard aside li.active {
	background-color: #fceded;
	list-style: none;
}

.dashboard aside li {
	margin-bottom: 10px;
	height: 48px;
	background-color: transparent;
	list-style: none;
	align-items: center;
	cursor: pointer;
}
.dashboard aside li a,
.dashboard aside li button,
.dashboard aside li span {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 600;
	font-size: 1.2rem;
	line-height: 30px;
	gap: 10px;
	display: flex;
	color: $base;
	padding: 5px 15px;
	cursor: pointer;
}
.dashboard aside li button:focus {
	box-shadow: none;
}
.dashboard aside li a:hover {
	text-decoration: none;
}

.dashboard aside li a.nav_link.active {
	background: #fceded;
	border-radius: 8px;
}

.dashboard aside .divider {
	width: 100%;
	height: 1px;
	background-color: #e5e5e5;
	margin: 70px 0px;
}

// dashboard_content
.dashboard_content {
	background: #fceded;
	padding: 45px 40px;
	width: 76%;
	@media (max-width: $bp-lap) {
		padding: 35px 30px;
	}
	@media (max-width: 500px) {
		width: 100%;
	}
}

// change_photo_modal
.change_photo_modal {
	button.bg_red {
		height: 48px;
		width: 213px;
	}
	input {
		position: absolute;
		z-index: 1;
		width: 50%;
	}
	img {
		height: 200px;
		width: 200px;
		position: relative;
		z-index: 4;
	}
	.upload_icon_wrapper {
		background-color: rgba(0, 0, 0, 0.539);
		z-index: 100;
		position: absolute;
		top: 0;
		height: 200px;
		width: 200px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
	}
	.event_bg_banner {
		height: 200px;
		width: 200px;
		display: flex;
		align-items: center;
		border-radius: 50%;
		z-index: 100;
		position: relative;
	}
}

// tablet sidebar
@media (max-width: $bp-dsk-one-pixel-down) {
	.dashboard.close_menu aside,
	.dashboard.open_menu + footer,
	.dashboard.open_menu section {
		display: none;
	}

	.dashboard_content {
		padding: 20px 20px 60px;
		width: 100%;
	}
}

// mobile
@media (max-width: $bp-tab) {
	.dashboard.close_menu aside,
	.dashboard.open_menu + footer,
	.dashboard.open_menu section {
		display: none;
	}

	.dashboard_header .header_content .logo-img {
		width: 120px;
		height: unset;
	}

	.dashboard aside .profile-icon {
		height: 150px;
		width: 150px;
		margin: 0px 0px 45px;
	}

	.dashboard_content {
		padding: 20px 20px 60px;
		width: 100%;
	}
	.footer {
		.row.no-gutters.footer-content.justify-content-between {
			display: flex;
			order: 3;
			margin-top: 20px;
			margin-bottom: 20px;
		}

		.container {
			display: flex;
			flex-direction: column;
		}

		a.footer-link {
			display: none;
		}

		.footer-social.mx-0 {
			margin: auto !important;
			justify-content: center;
		}

		.row.no-gutters.footer-content.justify-content-between p {
			display: none;
		}

		.bottom.row.footer-content {
			order: 2;
		}
	}
}

@media (max-width: 320px) {
	.dashboard_content {
		padding: 20px;
	}
}

@media (min-width: $bp-fhd) {
	main.dashboard {
		background-color: #fceded;
		.dashboard_layout_wrapper {
			max-width: 1440px;
			margin: auto;
		}
	}
}
