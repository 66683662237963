@import "./variables.scss";

.create_plan_modal {
	.input_view {
		display: flex;
		width: 100%;
	}
}

div.tl-watermark_SEE-LICENSE {
	display: none;
}

.draw_floor_plan {
	font-family: "Inter", sans-serif;
	button {
		background-color: transparent;
		border: none;
	}

	.right_button_group {
		button {
			box-shadow: none;
			span svg {
				display: none;
			}
			&:hover {
				color: #e845ba;
			}
		}
	}

	.top_row {
		background-color: #fafafc;
		display: flex;
		justify-content: space-between;
		padding: 12px 16px;
		align-items: center;

		.left_side {
			display: flex;
			flex-direction: column;
			gap: 3px;
			margin-bottom: 0px;
			h4 {
				font-size: 0.875rem;
				font-weight: 500;
				line-height: 19.6px;
				letter-spacing: -0.025em;
				color: #031729;
				margin-bottom: 0;
			}
			input {
				font-size: 0.875rem;
				font-weight: 500;
				line-height: 19.6px;
				letter-spacing: -0.025em;
				color: #031729;
				margin-bottom: 0;
				border: none;
				background-color: transparent;
				border-radius: 6px;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				&:focus {
					border: 1px solid #4285f4;
				}
			}
			p {
				font-size: 0.625rem;
				font-weight: 400;
				line-height: 12.1px;
				letter-spacing: -0.025em;
				color: #9395aa;
				margin-bottom: 0;
			}
		}
		.right_side {
			display: flex;
			flex-direction: row;
			gap: 5px;

			button {
				height: 32px;
				background-color: #fff;
				border: 1px solid #5455660d;
				padding: 8px 9px;
				border-radius: 6px;
				display: flex;
				align-items: center;
				font-size: 0.75rem;
				font-weight: 500;
				line-height: 16.8px;
				letter-spacing: -0.025em;
				text-align: left;
				color: #031729;
				gap: 6px;
				svg {
					height: 14px;
					width: 14px;
				}
			}

			.more_icon {
				width: 32px;
				button.dropdown_toggle {
					padding: 8px 9px !important;
				}
			}
			button:last-child svg {
				transform: rotate(90deg);
			}
			button:nth-child(3) {
				background-color: #031729;
				color: white;
			}
			button.dropdown_toggle {
				padding: 12px 16px !important;
				span {
					display: none;
				}
			}
			.dropdown-menu.show {
				min-width: 230px;
				box-shadow: 0px 4.5px 15.75px 1.13px #3030301a;
				border: 1px solid #30303026;
				border-radius: 8px;
				margin-top: 5px !important;
				padding: 5px;
				button {
					border: none;
					display: flex;
					align-items: center;
					justify-content: space-between;
					width: 100%;
					padding: 8px 12px;
					background-color: white;
					color: #000;
					&:hover {
						background-color: #fbfbfb;
						border-radius: 8px;
						color: $base_red;
					}
				}
			}
		}
		.board_row_wrapper {
			display: flex;
			align-items: center;
			justify-content: center;
			.board_row {
				display: flex;
				gap: 6px;
				border-radius: 6px;
				padding: 4px;
				background-color: #fbfbfb;
				box-shadow: 0px 4.5px 15.75px 1.13px #3030301a;
				button {
					display: flex;
					flex-direction: column;
					order: 1;
					border: 0.5px solid #5455660d;
					color: #9395aa;
					font-size: 0.75rem;
					font-weight: 500;
					line-height: 16.8px;
					letter-spacing: -0.025em;
					text-align: left;
					padding: 6px;
					border-radius: 6px;
					align-items: flex-start;
					width: 60px;
					gap: 8px;
					svg {
						display: flex;
						height: 14px;
						width: 14px;
					}
					&:hover {
						color: $base_red;
					}
				}
				&.select {
					button:first-child {
						color: $base_red;
					}
				}
				&.wall {
					button:nth-child(2) {
						color: $base_red;
					}
				}
				&.lines {
					button:nth-child(4) {
						color: $base_red;
					}
				}
			}
		}
	}

	.drawing_board {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		// background-color: #fafafc;
		gap: 1px;
		height: calc(100vh - 50px);
		padding: 0px;
		.board_sidebar {
			width: 17.5rem;
			min-width: 17.5rem;
			border-right: 1px solid #5455660d;
			padding: 8px;
			height: 100%;
			justify-content: space-between;
			display: flex;
			flex-direction: column;
			margin-left: 1px;
			margin-right: 1px;
			background-color: white;
			position: relative;
			z-index: 100;
			overflow-y: scroll;
			&::-webkit-scrollbar {
				display: none;
			}
			.top_content {
				display: flex;
				align-items: center;
				gap: 16px;
				flex-direction: column;
				.search_tools_element {
					background: #fbfbfb;
					display: flex;
					align-items: center;
					gap: 4px;
					padding: 8px 10px;
					width: 100%;
					border-radius: 6px;
					input {
						border: none;
						font-size: 12px;
						font-weight: 500;
						line-height: 16.8px;
						letter-spacing: -0.025em;
						text-align: left;
						background: #fbfbfb;
						color: #9395aa;
						width: 100%;

						&::placeholder {
							font-size: 12px;
							font-weight: 500;
							line-height: 16.8px;
							letter-spacing: -0.025em;
							text-align: left;
							color: #9395aa;
						}
					}
					svg {
						height: 14px;
						width: 14px;
					}
				}
				hr {
					display: flex;
					width: 100%;
					margin: 0px;
					border-top: 1px solid #5455660d;
				}
				.board_element {
					display: flex;
					flex-direction: column;
					width: 100%;
					align-items: flex-start;
					gap: 5px;
					.board_element_dropdown {
						display: flex;
						align-items: center;
						flex-direction: column;
						width: 100%;
						.board_element_header {
							display: flex;
							align-items: center;
							justify-content: space-between;
							width: 100%;
							background: #fafafc;
							height: 30px;
							padding: 0px 10px 0px 15px;
							cursor: pointer;
							div {
								display: flex;
								gap: 5px;
								align-items: center;
								font-size: 0.75rem;
								font-weight: 500;
								line-height: 16.8px;
								letter-spacing: -0.025em;
								text-align: center;
								color: #303030;
								svg {
									height: 14px;
									width: 14px;
									transform: rotate(0deg);
									transition: transform 0.5s ease-in;
								}
							}
							svg {
								height: 14px;
								width: 14px;
							}
						}

						&.active {
							.board_element_header div,
							.board_element_header svg {
								color: $base_red;
							}
							.board_element_header button svg {
								transform: rotate(180deg);
								transition: transform 0.5s ease-in;
							}
						}

						.board_element_content {
							background-color: #fbfbfb;
							transition: height 1s;
							width: 100%;
							margin-top: 5px;
							.ceremony_floor_plans {
								display: flex;
								align-items: center;
								flex-direction: column;
								gap: 5px;
								padding: 8px 4px;
								button {
									background-color: #fff;
									padding: 8px 12px;
									border-radius: 6px;
									height: 28px;
									border: 1px solid #5455660d;
									font-family: "Inter", sans-serif;
									font-size: 10px;
									cursor: pointer;
									font-weight: 400;
									line-height: 12.1px;
									letter-spacing: -0.025em;
									text-align: left;
									color: #031729;
									width: 100%;
								}
								.ceremony_floor_plan_wrapper {
									display: flex;
									width: 100%;
									align-items: center;
									gap: 6px;
									button.ceremony_floor_plan_name {
										width: 100%;
										color: #9395aa;
									}
									&.active {
										button.ceremony_floor_plan_name {
											color: #000;
										}
									}
									button.add_more_ceremony_floor_plan {
										border-radius: 6px;
										width: 30px;
										height: 30px;
										background: #fafafc;
										border: 1px solid #5455660d;
										display: flex;
										align-items: center;
										justify-content: center;
										padding: 6px;
										svg {
											stroke: #9395aa;
										}
									}
								}
							}
							.dimensions {
								display: grid;
								grid-template-columns: repeat(3, 1fr);
								gap: 6px;
								background: #fafafc;
								padding: 8px 4px;
								button {
									display: flex;
									flex-direction: column;
									font-size: 0.625rem;
									font-weight: 400;
									line-height: 12.1px;
									letter-spacing: -0.025em;
									border: 0.5px solid #5455660d;
									background-color: white;
									border-radius: 6px;
									text-align: center;
									justify-content: center;
									align-items: center;
									padding: 4px 4px 6px 4px;
									gap: 5px;
									color: #9395aa;
									span {
										height: 40px;
										width: 100%;
										border-radius: 4px;
										font-size: 0.625rem;
										font-weight: 500;
										line-height: 12.1px;
										text-align: center;
										display: flex;
										width: 100%;
										justify-content: center;
										align-items: center;
										background: #fbfbfb;
									}
									&:hover {
										color: $base_red;
									}
								}
							}
							.space_title {
								font-size: 10px;
								font-weight: 500;
								line-height: 12.1px;
								text-align: left;
								margin-left: 8px;
								margin-top: -10px;
								margin-bottom: 0px;
								color: #303030;
							}
							.fill_space {
								background-color: #fafafc;
								box-shadow: 0px -24px 20px 0px #00000003 inset;
								border-bottom: 16px solid #fafafc;
								display: grid;
								grid-template-columns: repeat(3, 1fr);
								gap: 6px;
								padding: 8px 4px;
								button {
									display: flex;
									flex-direction: column;
									height: 50px;
									width: 80px;
									background-color: white;
									border-radius: 4px;
									border: 0.5px solid #5455660d;
									font-size: 0.625rem;
									font-weight: 400;
									line-height: 12.1px;
									letter-spacing: -0.025em;
									text-align: left;
									text-wrap: nowrap;
									white-space: nowrap;
									align-items: flex-start;
									justify-content: center;
									color: #9395aa;
									padding: 6px;
									gap: 6px;
									svg {
										height: 14px;
										width: 14px;
									}
									&:hover {
										color: $base_red;
									}
								}

								&.object {
									button {
										&:hover {
											color: $base_red;
											svg {
												fill: $base_red;
												path,
												rect {
													fill: $base_red;
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
			.bottom_content {
				display: flex;
				align-items: center;
				gap: 6px;
				border: 0.5px solid #5455660d;
				background-color: #fbfbfb;
				border-radius: 6px;
				padding: 8px;
				position: relative;

				button {
					border: 0.5px solid #5455660d;
					background-color: #fff;
					padding: 10px 6px;
					border-radius: 3px;
					min-width: 78px;
					font-size: 10px;
					font-weight: 400;
					line-height: 12.1px;
					letter-spacing: -0.025em;
					text-align: left;
					display: flex;
					flex-direction: column;
					flex-wrap: nowrap;
					text-wrap: nowrap;
					white-space: nowrap;
					gap: 10px;
					color: #303030;
					svg {
						height: 14px;
						width: 14px;
					}
				}
				&.palette {
					button:first-child {
						color: $base_red;
					}
				}
				.react-colorful {
					position: absolute;
					left: 0;
					bottom: 90px;
				}
			}
		}
		.board_content {
			background: #fff;
			height: 100%;
			width: 100%;
			padding: 8px 6px;
			position: relative;
			overflow: scroll;

			&::-webkit-scrollbar {
				display: none;
			}

			.floor_plan_view {
				.canvas_wrapper {
					background: #fff;
					padding: 6px;
					.canvas_board {
						background: #fbfbfb;
						width: 100vw;
						height: calc(100vh - 30px);
						position: relative;
						.legend {
							text-align: center;
							ul {
								padding: 0;
								list-style: none;
								display: flex;
								flex-direction: column;
								text-wrap: nowrap;
								align-items: flex-start;
								font-size: 12px;
								margin: 0px 15px;
								text-overflow: ellipsis;
							}
						}
						.draw_actions_top {
							position: absolute;
							display: flex;
							flex-direction: column;
							align-items: center;
							justify-content: center;
							width: 100%;
							top: 10px;
							z-index: 1;
							gap: 10px;
							.icon_group {
								display: flex;
								align-items: center;
								gap: 6px;
								&.level_2_icons {
									button:nth-child(5) {
										font-family: Arial;
									}
									button:nth-child(6) {
										font-family: Courier;
									}
									button:nth-child(7) {
										font-family: Mono;
									}
									button:nth-child(8) {
										font-family: cursive;
									}
									.active {
										background-color: #e845ba;
										color: white;
									}
								}
							}
							button {
								height: 40px;
								width: 40px;
								display: flex;
								align-items: center;
								justify-content: center;
								border-radius: 6px;
								padding: 4px;
								background-color: #fff;
								border: 0.5px solid #5455660d;
								z-index: 10;
								&.bold {
									font-weight: bolder;
								}
								&.active {
									background-color: #e845ba;
									color: white;
									svg {
										color: #fff;
									}
								}

								&.italic {
									font-style: italic;
									// &.active {
									// 	background-color: #e845ba;
									// 	color: white;
									// }
								}
								svg {
									color: #888c8f;
								}
								&.select_wall {
									position: relative;
									span {
										position: absolute;
										right: 10px;
										bottom: 4px;
										transform: rotate(-45deg);
										height: 8px;
										width: 8px;
										svg {
											fill: #888c8f;
										}
									}
								}
							}
						}
						.draw_action_dropdown {
							position: absolute;
							display: flex;
							align-items: center;
							justify-content: center;
							width: 100%;
							top: 60px;
							gap: 6px;
							select {
								border: 1px solid #5455660d;
								height: 34px;
								border-radius: 6px;
								padding: 8px;
								background: #fbfbfb;
								font-family: "Inter", sans-serif;
								font-size: 12px;
								font-weight: 500;
								line-height: 18px;
								letter-spacing: -0.025em;
								color: #404040;
								z-index: 10;
							}
							.draw_input_wrapper {
								display: flex;
								height: 34px;
								z-index: 10;
								align-items: center;
								span {
									background: #fbfbfb;
									width: 23px;
									height: 34px;
									border-width: 1px 0px 1px 1px;
									border-style: solid;
									border-color: #5455660d;
									display: flex;
									align-items: center;
									justify-content: center;
									color: #888c8f;
									font-family: "Inter", sans-serif;
									font-size: 11px;
									font-weight: 500;
									line-height: 13.75px;
									border-radius: 6px 0px 0px 6px;

									&.unit {
										border-width: 1px 1px 1px 0px;
										border-radius: 0px 6px 6px 0px;
									}
								}
								input {
									background: #ffffff;
									border: 1px solid #5455660d;
									padding: 0px;
									gap: 4px;
									border-radius: 0px;
									width: 57px;
									padding: 0px 4px;
									height: 34px;
								}
							}
							.area_input_wrapper {
								display: flex;
								align-items: center;
								input.area_input {
									z-index: 10;
									margin-left: -10px;
									border-radius: 0px;
									border: 1px solid #5455660d;
									width: 57px;
									height: 34px;
									padding: 0px 5px;
								}
								span {
									background: #fbfbfb;
									width: 40px;
									border-width: 1px 1px 1px 0px;
									border-style: solid;
									border-color: #5455660d;
									display: flex;
									align-items: center;
									justify-content: center;
									color: #888c8f;
									font-family: "Inter", sans-serif;
									font-size: 11px;
									height: 34px;
									font-weight: 500;
									line-height: 13.75px;
									border-radius: 0px 6px 6px 0px;
								}
							}
							select:focus-visible,
							input:focus-visible {
								outline-offset: none;
								outline: none;
							}
						}
						.draw_actions_bottom {
							position: absolute;
							display: flex;
							align-items: center;
							justify-content: flex-end;
							width: 100%;
							bottom: 10px;
							gap: 6px;
							right: 10px;
							.draw_button_wrapper {
								display: flex;
								gap: 8px;
								align-items: center;
								z-index: 1000;
								position: relative;
								right: 0;
							}
							button {
								height: 40px;
								min-width: 40px;
								display: flex;
								align-items: center;
								justify-content: center;
								border-radius: 6px;
								padding: 4px;
								background-color: #fff;
								border: 1px solid #5455660d;
								color: #000;
								&.percentage {
									width: 57px;
								}
								svg {
									stroke: #888c8f;
									height: 14px;
									width: 14px;
									circle {
										stroke: #888c8f;
									}
								}
								&.hex_color_button {
									position: relative;
									.hex_color_picker {
										position: absolute;
										bottom: 50px;
									}
								}
							}
							.upload_wrapper {
								position: relative;
								bottom: 20px;
								.floorplan_upload_image {
									height: 40px;
									min-width: 40px;
									display: flex;
									align-items: center;
									justify-content: center;
									border-radius: 6px;
									padding: 4px;
									background-color: #fff;
									border: 1px solid #5455660d;
									color: #000;
									position: absolute;
									cursor: pointer;
									svg {
										stroke: #888c8f;
										height: 14px;
										width: 14px;
										circle {
											stroke: #888c8f;
										}
									}
									input {
										position: absolute;
										width: 100%;
										height: 100%;
										z-index: 100;
										cursor: pointer;
										&::-webkit-file-upload-button {
											visibility: hidden;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

.create_ceremony_floor_plan_modal {
	width: 70%;
}

.create_ceremony_floor_plan_modal.dashboard_modal.show {
	.modal-dialog {
		min-width: 50%;
		.modal-header .modal-title {
			font-family: "Inter", sans-serif;
			font-size: 14px;
			font-weight: 500;
			line-height: 19.6px;
			letter-spacing: -0.025em;
			text-align: center;
		}
		.modal-content {
			.modal-body {
				width: 100%;
				padding: 0;

				.input_view {
					display: flex;
					flex-direction: column;
					width: 100%;
					align-items: flex-end;
					.input_group {
						display: flex;
						flex-direction: column;
						padding: 24px;
						width: 100%;
						align-items: flex-end;
						gap: 15px;
						.input_row {
							display: flex;
							flex-direction: row;
							width: 100%;
							align-items: center;
							gap: 5px;
							.input2 {
								width: 100%;
							}
							button {
								border: none;
								background: #fafafc;
								padding: 10px 12px;
								font-family: "Inter", sans-serif;
								font-size: 10px;
								font-weight: 500;
								line-height: 12.1px;
								text-align: center;
								color: #303030;
								border-radius: 6px;
								svg {
									width: 12px;
									height: 12px;
								}
							}
						}
						.add_plan {
							width: fit-content;
							justify-content: center;
							display: flex;
							align-items: flex-end;
							background: #fafafc;
							padding: 6px 8px;
							border: none;
							font-family: "Inter", sans-serif;
							font-size: 12px;
							font-weight: 500;
							line-height: 16.8px;
							border-radius: 6px;
							letter-spacing: -0.025em;
							text-align: center;
							color: #303030;
							gap: 4px;
							svg {
								stroke: #71798c;
								height: 14px;
								width: 14px;
							}
						}
						hr {
							width: 100%;
							border-top: 1px solid #5455660d;
							margin: 0.5rem 0;
						}
					}
					.form_footer {
						width: 100%;
						display: flex;
						justify-content: flex-end;
						border-top: 1px solid #5455660d;
						padding: 12px 16px;
						button {
							background: #e72e50;
							padding: 0px 24px;
							height: 36px;
							color: #fff;
							font-family: "Inter", sans-serif;
							font-size: 12px;
							font-weight: 600;
							line-height: 16.8px;
							letter-spacing: -0.025em;
							text-align: left;
							border-radius: 6px;
							margin: 5px 0px 3px;
						}
					}
				}
			}
		}
	}
}

.element_menu {
	button.dropdown_toggle {
		position: absolute;
		background: white;
		border: 1px solid #5455660d;
		border-radius: 6px;
		height: 24px;
		padding: 0px 6px !important;
		display: flex;
		align-items: center;
		justify-content: center;
		span {
			display: none;
		}
		svg {
			transform: rotate(90deg);
			circle {
				stroke: #71798c;
			}
		}
		&:hover {
			background-color: #fff;
		}
	}
	.dropdown-menu.show {
		min-width: 120px;
		box-shadow: 0px 4.5px 15.75px 1.13px #3030301a;
		border: 1px solid #30303026;
		border-radius: 8px;
		margin-top: 0px !important;
		padding: 5px;
		a {
			border: none;
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			padding: 4px 8px;
			color: #303030;
			font-family: "Inter", sans-serif;
			font-size: 10px;
			font-weight: 400;
			line-height: 12.1px;
			letter-spacing: -0.025em;
			text-align: center;
			background-color: white;
			color: #000;
			&:hover {
				background-color: #fbfbfb;
				border-radius: 8px;
				color: $base_red;
			}
			svg {
				height: 12px;
				width: 12px;
			}
		}
	}
}
