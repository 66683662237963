@import "./variables.scss";

.checklist_dropdown {
	.underline {
		color: $primary;
	}
}

.activity_log {
	background-color: #fafafc;
	border-radius: 6px;
	padding: 6px;
	height: 100vh;
	font-family: "Inter", sans-serif;

	.search_audits {
		display: flex;
		justify-content: flex-end;
		.search_cta {
			position: absolute;
			right: 40px;
			top: 2px;
		}
		input.form-control {
			border: 1px solid #5455660d;
			border-radius: 5px;
			width: 300px;
			height: 32px;
			font-size: 12px;
			@media (max-width: 768px) {
				width: 100%;
			}
		}
	}

	.logs {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		flex-direction: column;
		height: calc(100% - 40px);
		.log_group {
			width: 100%;
			font-size: 12px;
			span {
				color: black;
			}
		}
	}

	.audit_list_card {
		.type {
			border-radius: 25px;
			background: #fff;
			color: #000;
			border: 2px solid #5455660d;
			font-size: 10px;
			font-weight: 600;
			padding: 4px 10px;
			text-wrap: nowrap;
			text-transform: uppercase;
		}
	}

	.log_title {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 10px;
		h4 {
			font-size: 14px;
			font-weight: 500;
			line-height: 19.6px;
			letter-spacing: -0.025em;
			text-align: left;
			color: #031729;
		}

		svg {
			height: 14px;
			width: 14px;
		}
	}
	.audit_actions {
		display: flex;
		align-items: center;
		gap: 20px;
		justify-content: flex-end;
		div#search_audits {
			width: 100%;
			min-width: 200px;
			input:focus {
				outline: none;
				box-shadow: none;
			}
		}
		.merged_buttons {
			display: flex;
			align-items: center;
			background: #fbfbfb;
			border: none;
			background-color: #fff;
			border: 1px solid #5455660d;
			height: fit-content;
			padding: 10px;
			gap: 8px;
			border-radius: 6px;
			display: flex;
			align-items: center;
			font-family: "Inter", sans-serif;
			font-size: 0.75rem;
			letter-spacing: -0.025em;
			height: 32px;
			color: #303030;
			justify-content: center;
			hr {
				height: 12px;
				width: 0px;
				margin: 0px 5px;
				border: 1px solid #e9e9e9;
			}
			svg {
				height: 12px;
				width: 12px;
			}
			button {
				background-color: transparent;
				border: none;
				&.filter {
					color: #000;
					border-right: none;
					border-top-right-radius: 0px;
					border-bottom-right-radius: 0px;
					gap: 5px;
					display: flex;
					align-items: center;
				}
			}
			.dropdown_toggle {
				border-left: none;
				border-top-left-radius: 0px;
				border-bottom-left-radius: 0px;
				font-size: 12px;
				display: flex;
				gap: 5px;
				align-items: center;
			}
		}
	}
}

.search_audits {
	display: flex;
	justify-content: flex-end;
	.search_cta {
		position: absolute;
		right: 55px;
		top: 10px;
	}
	input.form-control {
		border: 1px solid #545565;
		border-radius: 5px;
		width: 300px;
		height: 46px;
		@media (max-width: 768px) {
			width: 100%;
		}
	}
}

.paginate_event {
	font-size: 12px;
	select#events {
		font-size: 12px;
	}
	.pagination_btn_group {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	button {
		background-color: white;
		border: 1px solid #5455660d;
		border-radius: 6px;
		padding: 8px 10px;
		height: 32px;
		width: 32px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.audit_list_card {
	@media (max-width: 768px) {
		flex-direction: row;
		&.show_type {
			flex-direction: column;
			div.audit_type_group {
				flex-direction: row;
				display: flex;
				width: 100%;
				margin: 20px 0px 0px;
				justify-content: space-between;
				.type {
					width: fit-content;
				}
			}
		}
	}
	.type {
		border-radius: 5px;
		background: #c0c6a6;
		color: #000;
		font-size: 12px;
		font-weight: 600;
		padding: 5px 15px;
		text-transform: uppercase;
		text-wrap: nowrap;
	}
	&.show_type {
		.audit_details {
			width: 75%;
			line-height: 19.6px;
			letter-spacing: -0.025em;
			text-align: left;
			@media (max-width: 768px) {
				width: 100%;
			}
		}
		.audit_type_group {
			display: flex;
			width: fit-content;
			gap: 20px;
			justify-content: space-between;
		}
	}
}

.filter_audit_select {
	display: flex;
	flex-direction: row;
	max-width: 280px;
	align-items: center;
	font-size: 16px;
	label {
		text-wrap: nowrap;
		white-space: nowrap;
		margin-right: 20px;
		font-size: 16px;
		margin-bottom: 0;
	}
	select {
		height: 40px;
	}
}

@media (max-width: $bp-tab) {
	section.edit_profile {
		h4 {
			font-size: 18px;
			margin-bottom: 0px;
		}
		h5 {
			font-size: 16px;
			margin-bottom: 0px;
		}
	}
	.audit_list_card div {
		font-size: 16px;
	}
}

@media (max-width: $bp-phone) {
	.activity_log .audit_list_card div {
		font-size: 12px;
	}
	.audit_list_card div {
		font-size: 14px;
	}
	.activity_log {
		height: unset;
		.audit_actions {
			flex-direction: column;
			align-items: flex-start;
		}
	}
}
