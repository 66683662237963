@import "./variables.scss";

.collaborators_page {
	display: flex;
	flex-direction: column;
	background-color: #fafafc;
	border-radius: 6px;
	padding: 6px;
	font-family: "Inter", sans-serif;
	height: calc(100vh - 150px);

	.top_row {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 12px 16px;
		h4 {
			font-size: 0.875rem;
			font-weight: 500;
			line-height: 19.6px;
			letter-spacing: -0.025em;
			text-align: left;
			margin: 0;
			color: #031729;
		}
		.button_row {
			display: flex;
			align-items: center;
			gap: 6px;
			button {
				padding: 0px 10px;
				font-size: 0.75rem;
				font-weight: 500;
				line-height: 16.8px;
				letter-spacing: -0.025em;
				text-align: left;
				height: 32px;
				border: none;
			}
			button.invite_collaborator,
			button.dropdown_toggle {
				background: #031729;
				border: 1px solid #5455660d;
				color: white;
				border-radius: 6px;
				display: flex;
				align-items: center;
				gap: 4px;
				svg {
					height: 14px;
					width: 14px;
				}
			}
			button.dropdown_toggle {
				background-color: #fff;
				color: #000;
				box-shadow: 0px 4px 20px 0px #0000000d;
				padding: 0px 12px 0px 10px !important;
				svg {
					height: 14px;
					width: 14px;
				}
				span {
					display: none;
				}
			}
			.dropdown-menu.show {
				display: flex;
				flex-direction: column;
				margin-top: 4px;
				button {
					background-color: transparent;
					color: #000;
					width: 100%;
					&:hover {
						background: #fbfbfb;
						color: $base_red;
					}
				}
			}
		}
	}
	.content {
		margin-top: 10px;
		table {
			background-color: #fff;
			border: 1px solid #5455660d;
			border-radius: 6px;
			width: 100%;
			thead {
				border-radius: 6px 6px 0px 6px;
				padding: 4px 0px 4px 0px;
				th {
					padding: 16px 12px;
					font-size: 12px;
					font-weight: 500;
					line-height: 16.8px;
					letter-spacing: -0.025em;
					text-align: left;
					color: #031729;
				}
				th:first-child {
					width: 40px;
				}
			}
			tbody {
				td {
					padding: 8px 12px;
					font-size: 12px;
					font-weight: 500;
					line-height: 16.8px;
					letter-spacing: -0.025em;
					text-align: left;
					border-top: 1px solid #5455660d;
					color: #031729;
					p {
						font-size: 10px;
						font-weight: 400;
						line-height: 12.1px;
						color: #9395aa;
						margin: 0;
					}
				}
				td:first-child {
					width: 40px;
					padding: 16px 12px;
				}
				.permission {
					border: 0.6px solid #dddfe3;
					width: fit-content;
					padding: 1px 6px;
					border-radius: 120px;
					background: #fbfbfb;
					font-size: 10px;
					font-weight: 500;
					line-height: 12.1px;
					text-align: center;
					color: #9395aa;
					text-wrap: nowrap;
					white-space: nowrap;
					display: flex;
					align-items: center;
					gap: 3px;
				}
				td:nth-child(4) {
					color: blue;
				}
				td:last-child {
					button.dropdown_toggle {
						border-radius: 6px;
						padding: 2px;
						background: #fff;
						border: none;
						box-shadow: 0px 1px 2px 0px #0000000d;
						height: 30px;
						width: 30px;
						display: flex;
						align-items: center;
						justify-content: center;
						svg {
							transform: rotate(90deg);
							height: 14px;
							width: 14px;
							circle {
								stroke: #71798c;
							}
						}
						span {
							display: none;
						}
					}
					.dropdown-menu.show {
						min-width: 240px;
						button {
							background-color: transparent;
							border: none;
							text-wrap: nowrap;
							white-space: nowrap;
							padding: 0px 10px;
							font-size: 0.75rem;
							font-weight: 500;
							line-height: 16.8px;
							letter-spacing: -0.025em;
							text-align: left;
							height: 32px;
							border: none;
							color: #303030;
							width: 100%;
							&:hover {
								background: #fbfbfb;
								color: $base_red;
							}
						}
					}
				}
			}
			input {
				height: 14px;
				width: 14px;
			}
		}
	}
}

@media (max-width: 560px) {
	.collaborators_page .content table thead th:first-child,
	.collaborators_page .content table thead th:nth-child(2) {
		position: sticky;
		z-index: 100;
	}

	.collaborators_page .content table tbody td:first-child,
	.collaborators_page .content table tbody td:nth-child(2) {
		position: sticky;
		z-index: 100;
	}

	.collaborators_page .content {
		overflow: scroll;
		position: relative;
	}
}
