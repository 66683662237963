@import "./variables.scss";

p.ceremony_note_warning {
	font-size: 14px !important;
	color: $primary !important;
	margin-top: 5px;
}

.onboarding_layout {
	.search_select .select__control {
		border: 1px solid #a7a8b0;
		padding: 5px 10px;
		.select__input-container {
			.select__value-container {
				padding: 0px;
			}
			height: 30px;
			padding: 0px;
			input {
				height: 30px;
			}
		}
	}
	div.select__placeholder {
		color: #8d8d8e;
		font-size: 15px;
	}
	@media (max-width: $bp-tab) {
		&.onboarding__form {
			.onboarding_body {
				height: 77%;
			}
		}

		&.invite_collaborator_onboarding {
			.onboarding_body {
				height: 85%;
				div.onboarding_stage button {
					bottom: -38%;
				}
				.onboarding_slides .controls {
					bottom: 3%;
				}
				.collaborator .form_element {
					margin-bottom: 2px;
				}
			}
		}
	}

	.onboarding-grid {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		max-width: 511px;
		grid-template-rows: repeat(44, 20px);
		grid-gap: 0px;
		gap: 0px;
		background-color: white;
		@media (max-width: $bp-tab) {
			max-width: 100%;
		}
		.logo {
			background-color: white;
			display: flex;
			margin: auto;
			grid-column: 1;
			grid-row: 1/5;
			img {
				height: 43px;
				width: 156px;
				@media (max-width: $bp-phone) {
					height: 30px;
					max-width: 112px;
					margin-left: -30px;
				}
			}
		}

		.grid_0 {
			grid-column: 2;
			grid-row: 1/8;
		}

		.grid_0 {
			grid-column: 2;
			grid-row: 1/8;
		}

		img {
			width: 100%;
			height: 100%;
		}

		.grid_1 {
			grid-column: 1;
			grid-row: 5/17;
		}

		.grid_2 {
			grid-column: 2;
			grid-row: 8/17;
		}

		.grid_3 {
			grid-column: 1;
			grid-row: 17/26;
		}

		.grid_4 {
			grid-column: 2;
			grid-row: 17/33;
		}

		.grid_5 {
			grid-column: 1;
			grid-row: 32/50;
		}

		.grid_6 {
			grid-column: 2;
			grid-row: 33/41;
		}

		.grid_7 {
			grid-column: 1;
			grid-row: 25/33;
		}

		.grid_8 {
			grid-column: 2;
			grid-row: 41/50;
		}
	}
	.onboarding_body {
		padding-right: 150px;

		.onboarding_stage {
			position: relative;
			height: 515px;
			button {
				position: absolute;
				bottom: 0;
				left: 44%;
				z-index: 10;
			}
			&.stage_3 {
				button {
					position: relative;
					left: unset;
					bottom: unset;
				}
			}
			.collaborator {
				label,
				h5 {
					color: #545565;
					font-family: Poppins;
					font-size: 20px;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
					@media (max-width: 768px) {
						font-size: 16px;
					}
				}
				h5 {
					font-weight: 600;
				}
				select {
					appearance: none;
					background: url("https://firebasestorage.googleapis.com/v0/b/plan-a-day-12b4c.appspot.com/o/select_arrow.svg?alt=media&token=9056509d-c3bb-450d-abe0-91884643ef77")
						no-repeat;
					-webkit-appearance: none;
					padding-right: 10px !important;
					background-position: calc(100% - 0.75rem) center !important;
					-moz-appearance: none !important;
				}
			}
		}
		h3 {
			font-size: 32px;
			font-style: normal;
			font-weight: 700;
			line-height: 70px;
			letter-spacing: 0.64px;
			color: $primary;
			@media (max-width: $bp-dsk) {
				line-height: 40px;
			}
		}
		h4 {
			color: $base;
			font-size: 24px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
		}
		label {
			color: $base;
			font-family: "Poppins";
			font-size: 20px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
		}
		form {
			display: flex;
			flex-direction: column;
			row-gap: 20px;
			margin-bottom: 20px;
			.group {
				gap: 30px;
				.form_element {
					width: 50%;
				}
			}

			.ceremony_picker {
				position: relative;
				button.add_ceremony {
					background-color: transparent;
					border: 0px;
					order: 1;
				}
				.select__option:hover {
					background-color: #e695a5;
					color: white;
				}
			}
		}
	}
	.onboarding_slides {
		height: 600px;
		display: flex;
		flex-direction: column;

		ul.indicator {
			gap: 10px;
			padding: 0;
			margin: auto;
			justify-content: center;
			li {
				height: 11px;
				width: 50px;
				list-style: none;
				background-color: #e5e5e5;
				border-radius: 8px;
			}
			li.active {
				background-color: $primary;
			}
			li.visited {
				background-color: #e598a7;
			}
		}
		.controls {
			margin-top: 60px;
			position: absolute;
			bottom: 0;
			width: 80%;
		}
		button {
			color: $primary;
			font-family: "Poppins";
			font-size: 24px;
			font-style: normal;
			font-weight: 300;
			line-height: normal;
			letter-spacing: 2.4px;
		}
		button.bg_red {
			color: white;
			text-align: center;
			font-family: "Poppins";
			font-size: 20px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
		}
		button.profile {
			width: 220px;
		}
		p {
			color: $base;
			font-family: "Poppins";
			font-size: 20px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
			margin-bottom: 20px;
			span {
				color: $primary;
				margin-right: 4px;
			}
		}
	}
}

@media (max-width: $bp-tab) {
	.onboarding_layout {
		position: relative;
	}
	.onboarding_layout .onboarding_body {
		padding-right: 0px;
		position: absolute;
		margin: 5%;
		background-color: #fff;
		border-radius: 5px;
		width: 90%;
		padding: 20px;
		padding-bottom: 30px;
		top: 10%;
	}
	.onboarding_layout .onboarding_body h3 {
		font-size: 20px;
		line-height: 32px;
		color: $primary;
	}
	.onboarding_layout .onboarding_body h4 {
		font-size: 18px;
		line-height: 20px;
	}
	.onboarding_layout .onboarding_body label {
		font-size: 16px;
	}
	.onboarding_layout .onboarding_slides {
		height: 500px;
		justify-content: flex-start;
	}
	.onboarding_layout .onboarding_slides .controls {
		margin-top: 20px;
		position: absolute;
		width: 90%;
		bottom: 6%;
		left: 5%;
	}
	.onboarding_layout .onboarding_slides button.bg_red,
	.onboarding_layout .onboarding_slides .controls button {
		font-size: 14px;
	}
	.onboarding_layout .onboarding_slides button {
		padding: 0px;
	}
	.onboarding_layout .onboarding_slides button.bg_red {
		padding: 8px 20px;
		width: unset;
	}
	.onboarding_layout .onboarding_slides input,
	.onboarding_layout .onboarding_slides input::placeholder {
		font-size: 16px;
	}
	.onboarding_layout .onboarding_slides input,
	.onboarding_layout .onboarding_slides select {
		@media (max-width: 768px) {
			height: 40px;
		}
	}
	.onboarding_layout .onboarding_slides p {
		font-size: 16px;
	}
	.onboarding_layout .onboarding_slides button.profile {
		margin: auto;
		display: flex;
	}

	.onboarding_layout .onboarding_body div.onboarding_stage {
		height: 415px;
		button {
			left: 45%;
			bottom: 0;
		}
	}
	.onboarding_layout .onboarding_body form .group {
		flex-direction: column;
		gap: 20px;
	}

	.onboarding_layout .onboarding_body form .group .form_element {
		width: 100%;
	}
}

@media (max-width: 450px) {
	div.onboarding_layout .onboarding_body div.onboarding_stage {
		button.button {
			left: 40%;
		}
	}
}

button.react-datepicker__navigation {
	background-color: unset !important;
	width: 32px !important;
}
