@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400,500&family=Raleway:wght@500,600&display=swap");
@import "./variables.scss";
@import "./utilities.scss";

@font-face {
	font-family: "Helvetica_Neue";
	font-weight: 400;
	src: local("Helvetica_Neue"), url(../assets/fonts/HelveticaNeueLight.ttf) format("ttf");
}

@font-face {
	font-family: "Helvetica_Neue";
	font-weight: 500;
	font-style: italic;
	src: local("Helvetica_Neue"), url(../assets/fonts/HelveticaNeue-MediumCondObl.otf) format("otf");
}

@font-face {
	font-family: "Helvetica_Neue";
	font-weight: 500;
	src: local("Helvetica_Neue"), url(../assets/fonts/HelveticaNeueMedium.ttf) format("ttf");
}

@font-face {
	font-family: "Helvetica_Neue";
	font-weight: 700;
	src: local("Helvetica_Neue"), url(../assets/fonts/HelveticaNeueBold.ttf) format("ttf");
}

@font-face {
	font-family: "SF_PRO";
	font-weight: 500;
	src: local("SF_PRO"), url(../assets/fonts/SFPRODISPLAYMEDIUM.OTF) format("otf");
}

@font-face {
	font-family: "SF_PRO";
	font-weight: 400;
	src: local("SF_PRO"), url(../assets/fonts/SFPRODISPLAYREGULAR.OTF) format("otf");
}

@media (min-width: 1600px) {
	header.landing_page,
	main.landing_page {
		width: 1600px;
	}
	header.header.landing_page {
		margin: 0px auto;
	}

	main.main.landing_page {
		margin: auto;
		margin-top: 100px;
		.hero {
			.hero_content {
				margin: 0px;
				.emoji_wrapper {
					margin-left: 0px;
				}
				.socials {
					margin-top: 40px;
				}
			}
			.dashboard_shadow {
				margin-top: -48%;
			}
		}
		section.text_content_first {
			margin-top: 0px;
			h3 {
				text-align: center;
				margin-bottom: 40px;
				span {
					color: $primary;
				}
			}
		}
	}
}

header.header.landing_page > div {
	max-width: 1600px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	margin: auto;
}

header.landing_page {
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: black;
	font-size: 1rem;
	position: fixed;
	z-index: 100;
	line-height: 24px;
	width: 100%;
	background-color: #fdfdfd5c;
	height: 100px;
	backdrop-filter: blur(10px);
	top: 0;
	margin: 0;
	padding: 0px 25px;
	font-family: "Helvetica_Neue", sans-serif;

	.group_1 {
		ul li {
			cursor: pointer;
			a:hover {
				text-decoration: none;
			}
			list-style: none;
			button {
				font-weight: 400;
			}
		}
	}
	.group_2 {
		a,
		button {
			font-weight: 550;
			cursor: pointer;
		}
	}

	.group_1,
	.group_2 {
		display: flex;
		align-items: center;
		gap: 40px;
	}
	button:focus {
		box-shadow: none !important;
	}
	a {
		color: black;
		font-weight: 500;
		cursor: pointer;
	}
	button.dropdown-toggle {
		color: black;
	}
	a.button {
		color: $primary;
		border: 1px solid $primary;
		background-color: white;
		border-radius: 8px;
		padding: 10px;
		cursor: pointer;
		font-weight: 500;
		&:hover {
			text-decoration: none;
		}
	}
	button#dropdown-basic {
		color: black;
	}
	ul {
		display: flex;
		align-items: center;
		gap: 40px;
		margin-bottom: 0px;
		padding-left: 0px;
		li {
			a,
			.dropdown-menu .dropdown-item {
				color: black;
				font-size: 16px;
				font-weight: 500;
			}
		}
		button.btn {
			background-color: transparent !important;
			color: black;
			border: none;
			font-weight: 500;

			&:hover {
				color: black;
				border: none;
			}
		}
		button.btn:hover,
		button.btn:focus {
			background-color: transparent !important;
			color: black;
			border: none;
			color: black;
		}
	}
	.mobile_menu,
	aside.mobileMenu {
		display: none;
	}
}

main.landing_page {
	section,
	.features_group {
		margin: 0px 25px;
	}
	.features_group {
		background-image: url("../assets/img/bg_img.png");
		background-position: left;
		background-size: contain;
	}

	section.hero {
		margin: 0px;
		position: relative;
	}
	// margin: 0px 25px;
	margin-top: 100px;
	button:focus {
		box-shadow: none !important;
	}
	.hero_carousel_content {
		height: 700px;
		object-fit: cover;
		object-position: center;
		// width: 100%;
		margin: 0px 25px;
		border-radius: 15px;
		position: relative;
		z-index: 1;
		.overlay {
			background-color: #000;
			opacity: 0.75;
			width: 100%;
			border-radius: 15px;
			height: 100%;
			display: flex;
			border-radius: 15px !important;
			z-index: 1;
			position: absolute;
		}
		img.hero_img,
		.slide,
		.carousel {
			border-radius: 15px;
		}
		img.hero_img {
			max-height: 700px;
		}
		.content_wrapper {
			position: absolute;
			height: 700px;
			width: 100%;
			top: 0;
			z-index: 10;
			margin: auto;
			display: flex;
			.hero_content {
				color: white;
				display: flex;
				margin: 55px auto;
				width: 100%;
				align-items: center;
				flex-direction: column;

				.emoji_wrapper {
					background-color: #f5f5f540;
					padding: 2px 16px 2px 16px;
					border-radius: 20px;
					font-weight: 400;
					margin-left: 70px;
					margin-top: 40px;
					border: 0.2px solid #989898;
					backdrop-filter: blur(6px);
					width: fit-content;
					display: flex;
					align-items: center;
					p {
						font-size: 1rem;
						margin: 0;
						font-family: "SF_PRO", sans-serif;
						font-weight: 400;
						color: #dcdcdc;
					}
				}
				h1 {
					margin: 20px auto;
					font-size: 5rem;
					font-weight: 500;
					font-style: italic;
					font-family: "Helvetica_Neue", sans-serif;
					line-height: 60px;
					letter-spacing: -5px;
					span {
						color: $primary;
						font-style: normal;
					}
				}
				h2 {
					color: #ffffff80;
					margin: 0;
					font-size: 1.25rem;
					font-weight: 500;
					font-family: "SF_PRO", sans-serif;
					line-height: 28px;
					text-align: center;
					letter-spacing: -0.24px;
				}
				.button_group {
					margin-top: 50px;
					gap: 20px;
					button,
					a.button {
						height: 48px;
						border-radius: 8px;
						display: flex;
						align-items: center;
						border: none;
						font-size: 1rem;
						font-weight: 500;
						font-family: "Helvetica_Neue", sans-serif;
						line-height: 24px;
						letter-spacing: -0.08px;
						justify-content: center;
						color: white;
						&:hover {
							text-decoration: none;
						}
						&.left {
							background-color: #f5f5f540;
							padding: 12px 48px 12px 48px;
							backdrop-filter: blur(6px);
						}
						&.right {
							background-color: $primary;
							padding: 12px 32px 12px 32px;
						}
					}
				}
			}
		}
		.socials {
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			padding: 0;
			gap: 10px;
			width: fit-content;
			margin-right: 25px;
			position: fixed;
			right: 0px;
			z-index: 10;
			top: 50%;
			li {
				background-color: #f3a8b44d;
				// background-color: #ffffff26;
				padding: 10px;
				border-radius: 100%;
				height: 40px;
				width: 40px;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
	}
	.dashboard_image_wrapper {
		width: 100%;
		margin-top: -250px;
		img.dashboard_image {
			position: relative;
			z-index: 5;
			width: 93%;
			margin: auto;
			display: flex;
		}
	}

	.dashboard_shadow {
		background-color: white;
		height: 100px;
		width: 100%;
		display: flex;
		z-index: 2;
		position: relative;
		bottom: 0;
		margin-top: -5% !important;
		backdrop-filter: blur(6px);
		filter: blur(10px);
		-o-filter: blur(10px);
		-ms-filter: blur(10px);
		-moz-filter: blur(10px);
		-webkit-filter: blur(10px);
	}

	.text_content_first {
		margin-top: 0px;
		border: 2px solid #f5f5f5;
		border-radius: 16px;
		padding: 50px 0px 100px;
		background-color: #fbf9fa;
		h3 {
			text-align: center;
			margin-bottom: 40px;
			font-size: 2.5rem;
			font-family: "Raleway", sans-serif;
			color: #000;
			font-weight: 500;
			span {
				color: $primary;
			}
		}
		.section_group {
			display: flex;
			gap: 50px;

			.section_card {
				width: 100%;
				background-color: #f5f5f580;
				border-radius: 20px !important;
				border: 10px solid #fff;
				&:hover {
					border-radius: 20px !important;
					background-color: $primary;
					// transition: background-color 0.5s ease-in-out;
					h4 {
						color: white;
					}
					div.text_content {
						p {
							color: white;
						}
						a {
							border: 2px solid white;
							path {
								fill: white;
							}
						}
					}
				}
				span {
					background-color: white;
					padding: 6px 12px;
					font-size: 0.75rem;
					margin: 35px 0px 20px 10px;
					width: fit-content;
					color: #303030;
					border-radius: 20px;
					letter-spacing: -0.06px;
					font-weight: 500;
					font-family: "Inter", sans-serif;
					display: flex;
				}
				h4 {
					font-size: 1.5rem;
					font-weight: 700;
					font-family: "Helvetica_Neue", sans-serif;
					margin-left: 10px;
					color: #303030;
					letter-spacing: -0.38px;
					margin-bottom: 30px;
				}
				img.card_img {
					width: 100%;
				}
				div.text_content {
					display: flex;
					justify-content: space-between;
					align-items: flex-start;
					margin: 0px 10px;
					p {
						font-family: "Helvetica_Neue", sans-serif;
						font-weight: 400;
						font-size: 1rem;
						width: 80%;
						color: #303030;
					}
					a {
						border: 2px solid black;
						border-radius: 100%;
						height: 60px;
						width: 60px;
						display: flex;
						align-items: center;
						justify-content: center;
						img {
							height: 20px;
							width: 20px;
						}
					}
				}
			}

			.section_card > div {
				border-radius: 10px !important;
				background-color: #f5f5f580;
				padding: 10px 10px 0px 10px;
			}
			.section_card:hover > div {
				border-radius: 10px !important;
				background-color: $primary;
			}
		}
	}

	.features_group {
		margin-top: 30px;
		border: 2px solid #f5f5f5;
		border-radius: 16px;
		padding: 50px 30px 15px;
		background-color: #fafafa;
		position: relative;
		z-index: 0;
		h3 {
			text-align: center;
			margin-bottom: 40px;
			font-size: 2.5rem;
			color: #303030;
			font-family: "SF_PRO", sans-serif;
			font-weight: 400;
			letter-spacing: -0.03px;
			span {
				color: $primary;
			}
		}
		.features {
			margin-top: 30px;
			border: 6px solid #fff;
			border-radius: 16px;
			background-color: #fdfdfd;
			padding: 50px 0px 0px;
			&:hover {
				background-color: $primary;
				transition: background-color 0.5s ease-in-out;
				color: white;
				h3 {
					color: white;
					span {
						color: white;
					}
				}
				p {
					color: white;
				}
				p + div {
					button {
						border: 2px solid #fff;
						background-color: #fcf3f336;
						color: #fff;
					}
				}
			}
			h3 {
				text-align: center;
				margin-bottom: 20px;
				font-size: 2.25rem;
				color: #303030;
				font-weight: 500;
				font-family: "Helvetica_Neue", sans-serif;
				letter-spacing: -0.03px;
				span {
					color: $primary;
				}
			}
			p {
				color: #717984;
				margin: 0;
				font-size: 1rem;
				font-weight: 400;
				line-height: 28px;
				letter-spacing: -0.035px;
				text-align: center;
				width: 60%;
				font-family: "Inter", sans-serif;
				margin: auto;
			}
			p + div {
				display: flex;
				align-items: center;
				gap: 20px;
				margin: 20px auto;
				justify-content: center;
				button,
				a {
					color: $primary;
					border: 2px solid $primary;
					background-color: white;
					border-radius: 40px;
					padding: 10px 20px;
					font-weight: 500;
					text-decoration: none;
					font-size: 1rem;
					letter-spacing: -0.09px;
					font-family: "Inter", sans-serif;
				}
			}
			img {
				margin: auto;
				margin-top: 80px;
				justify-content: center;
				display: flex;
				width: 50%;
			}
		}
	}

	.join_community {
		background-color: $primary;
		color: white;
		border-radius: 20px;
		align-items: center;
		display: flex;
		margin-bottom: 20px;
		padding: 40px 60px 0px;
		div {
			margin-top: -30px;
			display: flex;
			gap: 15px;
			flex-direction: column;
		}
		h5 {
			font-size: 3rem;
			font-weight: 500;
			font-family: "Helvetica_Neue", sans-serif;
			letter-spacing: -0.03px;
		}
		p {
			width: 60%;
			font-size: 1.125rem;
			font-weight: 400;
			font-family: "Helvetica_Neue", sans-serif;
		}
		button {
			background-color: #fff;
			color: $primary;
			padding: 12px 48px;
			border-radius: 8px;
			font-family: "Helvetica_Neue", sans-serif;
			font-weight: 500;
			letter-spacing: -0.08px;
			border: none;
			max-width: 166px;
			font-size: 1rem;
			width: fit-content;
		}
		img {
			margin: 0;
		}
	}
	.testimonials {
		border: 2px solid #f5f5f5;
		position: relative;
		z-index: 0;
		border-radius: 16px;
		padding: 40px 0px 0px;

		h3 {
			color: $primary;
			font-size: 2.5rem;
			font-weight: 500;
			text-align: center;
			letter-spacing: 0.03px;
			font-family: "Helvetica_Neue", sans-serif;
		}
		.testimonial_group {
			-ms-overflow-style: none; /* Internet Explorer 10+ */
			scrollbar-width: none; /* Firefox */
		}
		.testimonial_group::-webkit-scrollbar {
			display: none; /* Safari and Chrome */
		}
		.testimonial_group {
			display: flex;
			gap: 30px;
			overflow-x: scroll;
			scroll-snap-type: x mandatory;
			margin: 40px 0px;

			.customer_testimonial {
				display: flex;
				background-color: #f9f9f9;
				border: 4px solid #fff;
				border-radius: 10px;
				flex-direction: column;
				padding: 20px;
				color: black;
				min-width: 460px;
				transform: translate3d(0, 0, 0);
				opacity: 1;
				animation-play-state: paused;
				cursor: pointer;
				.card_top {
					display: flex;
					align-items: center;
					gap: 30px;
					div {
						display: flex;
						flex-direction: column;
					}
					h5 {
						font-size: 0.875rem;
						font-weight: 600;
						font-family: "Raleway", sans-serif;
						color: #191d20;
					}
					img {
						height: 60px;
						width: 60px;
					}
					p {
						width: 100%;
						font-weight: 300;
						font-family: "Raleway", sans-serif;
						color: #545565;
						font-size: 1rem;
						letter-spacing: 0.02px;
						margin-bottom: 0px;
					}
				}
				p {
					width: 100%;
					font-family: "Helvetica_Neue", sans-serif;
					font-weight: 400;
					font-size: 0.875rem;
					letter-spacing: -0.01px;
					color: #303030;
				}
			}
			.customer_testimonial:hover {
				border: 2px solid $primary;
			}
		}
	}
	ul {
		list-style: none;
	}
}

footer.footer.landing_page {
	display: flex;
	flex-direction: column;
	background-color: $primary;
	border-top-left-radius: 16px;
	border-top-right-radius: 16px;
	width: 100%;
	gap: 40px;
	padding-top: 50px;
	padding-bottom: 30px;
	color: white;
	max-width: 1600px;
	margin: auto;
	margin-top: 100px;

	.top_section {
		width: 80%;
		display: flex;
		margin: auto;
		justify-content: space-between;
		img {
			height: 28px;
			width: 140px;
		}
		div {
			display: flex;
			gap: 20px;
			color: #f4f5f5;
			font-size: 16px;
			align-items: center;
			p {
				margin-bottom: 0px;
				font-family: "Helvetica_Neue", sans-serif;
				font-weight: 500;
				font-size: 1rem;
				letter-spacing: -0.01px;
				color: #f4f5f5;
			}
			a {
				border-radius: 50%;
				height: 40px;
				width: 40px;
				background-color: #da334e;
				display: flex;
				justify-content: center;
				align-items: center;
				img {
					height: 20px;
					width: 20px;
				}
			}
			a.go_up {
				background-color: #da334e;
			}
			.icon {
				background-color: white;
			}
		}
	}
	.top_section > div {
		gap: 100px;
		align-items: center;
	}
	hr {
		border: 1px solid white;
		width: 80%;
	}
	.bottom_section {
		width: 80%;
		margin: auto;
		display: flex;
		justify-content: space-between;
		color: white;
		font-size: 14px;
		opacity: 0.75;
		a {
			color: white;
			opacity: 0.75;
			font-family: "Helvetica_Neue", sans-serif;
			font-weight: 400;
			font-size: 0.75rem;
			letter-spacing: -0.01px;
		}
		div {
			gap: 20px;
			display: flex;
			text-decoration: underline;
		}
	}
	ul {
		list-style: none;
	}
}

@media (max-width: 1024px) and (min-width: 769px) {
	main.landing_page .hero_carousel_content .socials {
		margin-right: 0px;
	}
	main.landing_page .join_community {
		padding-bottom: 30px;
		img {
			width: 300px;
		}
	}
}

@media (max-width: 768px) {
	main.landing_page .features_group .features img {
		width: 100%;
	}

	aside.mobileMenu {
		display: flex;
		flex-direction: column;
		backdrop-filter: blur(10px);
		height: 100vh;
		padding: 40px;
		position: fixed;
		width: 100%;
		background-color: #fdfdfd5c;
		z-index: 100;
		top: 70px;
		gap: 20px;
		font-weight: 600;
		padding-top: 40px;
		ul {
			gap: 40px;
			display: flex;
			padding: 0px;
			flex-direction: column;
		}
		button {
			font-weight: 700;
			background-color: $primary;
			color: white;
			padding: 10px auto;
		}
	}

	header.landing_page button.mobile_menu {
		display: flex;
		border: none;
		background-color: transparent;
		opacity: 1;
	}

	.hamburger {
		cursor: pointer;
		width: 24px;
		height: 24px;
		transition: all 0.25s;
		position: relative;
	}

	.hamburger-top,
	.hamburger-middle,
	.hamburger-bottom {
		position: absolute;
		top: 0;
		left: 0;
		width: 24px;
		height: 3px;
		border-radius: 6px;
		background: $primary;
		transform: rotate(0);
		transition: all 0.5s;
	}

	.hamburger-middle {
		transform: translateY(7px);
	}

	.hamburger-bottom {
		transform: translateY(14px);
	}

	.open {
		transform: rotate(90deg);
		transform: translateY(0px);
	}

	.open .hamburger-top {
		transform: rotate(45deg) translateY(6px) translate(6px);
	}

	.open .hamburger-middle {
		display: none;
	}

	.open .hamburger-bottom {
		transform: rotate(-45deg) translateY(6px) translate(-6px);
	}

	main.landing_page .join_community img {
		width: 100%;
	}
	main.landing_page .join_community div {
		flex-direction: column;
		text-align: center;
		justify-content: center;
		align-items: center;
	}

	footer.footer .bottom_section,
	footer.footer .top_section > div,
	footer.footer .top_section,
	main.landing_page .join_community div,
	main.landing_page .join_community,
	main.landing_page .text_content_first .section_group,
	main.landing_page .text_content_first .section_group .section_card div.text_content {
		flex-direction: column;
	}
	header.landing_page .group_2,
	header.landing_page ul {
		display: none;
	}
	main.landing_page .hero_carousel_content .content_wrapper .hero_content .emoji_wrapper {
		margin-left: 0px;
	}
	main.landing_page .hero_carousel_content {
		height: 420px;
	}

	main.landing_page .hero_carousel_content .content_wrapper .hero_content h1 {
		font-size: 2rem;
		text-align: center;
		line-height: 28px;
		letter-spacing: 0.05px;
	}
	main.landing_page .dashboard_image_wrapper {
		width: 80%;
		margin: auto;
		margin-top: -150px;
	}
	main.landing_page .hero_carousel_content .content_wrapper .hero_content {
		margin-top: 0px;
	}

	main.landing_page .dashboard_shadow {
		position: absolute;
		bottom: 0px;
		margin-top: unset;
		height: 30px;
	}
	header.landing_page {
		height: 70px;
	}
	main.landing_page .hero_carousel_content .socials {
		margin-right: 10px;
		right: 0px;
	}
	main.landing_page .join_community {
		padding: 60px 25px 15px;

		h5 {
			font-size: 1.4rem;
		}
		p {
			width: 100%;
			font-size: 1rem;
		}
		div {
			margin-bottom: 10px;
		}
		img {
			width: 250px;
			height: 100%;
		}
	}
	main.landing_page .testimonials {
		padding: 30px 15px 0px;
		h3 {
			font-size: 1.5rem;
			margin-bottom: 0;
			line-height: 24px;
		}
		.testimonial_group {
			margin: 20px 0px;
			.customer_testimonial {
				min-width: 250px;
				.card_top p {
					font-size: 0.75rem;
				}
			}
		}
	}
	main.landing_page .hero_carousel_content .content_wrapper {
		height: 500px;
	}
	main.landing_page .features_group {
		padding: 25px 10px 40px;
		.features {
			h3 {
				font-size: 1.3rem;
				line-height: 24px;
			}
			p {
				width: 90%;
				font-size: 0.875rem;
				line-height: 20px;
			}
		}
		.features p + div {
			flex-direction: column;
			button {
				font-size: 0.75rem;
			}
		}
	}

	main.landing_page .text_content_first {
		padding: 25px 0px 50px;
		h3 {
			font-size: 1.125rem;
			margin-bottom: 10px;
		}
	}

	main.landing_page .hero_carousel_content .content_wrapper .hero_content h2 {
		font-size: 0.875rem;
		line-height: 16px;
	}

	main.landing_page .hero_carousel_content .content_wrapper .hero_content .emoji_wrapper p {
		font-size: 0.625rem;
	}
	main.landing_page .text_content_first .section_group .section_card div.text_content {
		padding-bottom: 30px;
	}
	main.landing_page .hero_carousel_content img.hero_img {
		height: 400px;
		border-bottom-left-radius: 0px !important;
		border-bottom-right-radius: 0px !important;
		object-fit: cover;
	}

	main.landing_page .hero_carousel_content .carousel {
		border-bottom-left-radius: 0px;
		border-bottom-right-radius: 0px;
	}

	main.landing_page .text_content_first .section_group .section_card div.text_content {
		p {
			width: 100%;
		}
		a {
			height: 40px;
			width: 40px;
		}
	}

	main.landing_page .hero_carousel_content .content_wrapper .hero_content .button_group button {
		font-size: 0.75rem;
		width: 165px;
		.left,
		.right {
			padding: 10px 40px;
		}
	}

	main.landing_page .hero_carousel_content .content_wrapper .hero_content .button_group {
		margin-top: 30px;
		gap: 10px !important;
	}

	.landing_page {
		section,
		.features_group {
			margin: 0px 15px;
		}
	}

	main.landing_page .features_group h3 {
		font-size: 1.5rem;
		margin-bottom: 0px;
		line-height: 20px;
	}

	footer.footer .top_section {
		flex-direction: column;
		gap: 20px;
		div {
			align-items: flex-start;
			gap: 20px;
			p {
				font-size: 0.875rem;
			}
		}
	}
	footer.footer .top_section > div {
		gap: 40px;
	}
}

@media (max-width: 500px) {
	main.landing_page .hero_carousel_content .content_wrapper .hero_content .button_group {
		flex-direction: column;
	}
	main.landing_page .hero_carousel_content .content_wrapper .hero_content h1 {
		height: 55px;
	}
	main.landing_page .join_community {
		padding-bottom: 20px;
		div {
			margin-bottom: 10px;
		}
		img {
			width: 200px;
			height: 100%;
			margin-top: 30px;
		}
	}
	main.landing_page .dashboard_image_wrapper {
		width: 80%;
		margin: auto;
		margin-top: -90px;
	}
}

.rfm-child {
	margin: 0px 20px;
	max-width: 460px;
}

.animate__animated {
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}

.animate__fadeInRight {
	-webkit-animation-name: fadeInRight;
	animation-name: fadeInRight;
}

.animate__fadeInLeftBig {
	-webkit-animation-name: fadeInLeftBig;
	animation-name: fadeInLeftBig;
}

.animate__fadeInLeft {
	-webkit-animation-name: fadeInLeft;
	animation-name: fadeInLeft;
}

@keyframes fadeInLeftBig {
	0% {
		opacity: 0;
		-webkit-transform: translate3d(100%, 0, 0);
		transform: translate3d(100%, 0, 0);
		margin-left: 150px;
	}
	100% {
		opacity: 1;
		-webkit-transform: translateZ(0);
		transform: translateZ(0);
		margin-left: 0px;
	}
}

@keyframes fadeInRight {
	0% {
		opacity: 0;
		-webkit-transform: translate3d(100%, 0, 0);
		transform: translate3d(100%, 0, 0);
		margin-right: 100px;
	}
	100% {
		opacity: 1;
		-webkit-transform: translateZ(0);
		transform: translateZ(0);
		margin-right: 0px;
	}
}
@keyframes fadeInLeft {
	0% {
		opacity: 0;
		-webkit-transform: translate3d(-100%, 0, 0);
		transform: translate3d(-100%, 0, 0);
		margin-left: -100px;
	}
	100% {
		opacity: 1;
		-webkit-transform: translateZ(0);
		transform: translateZ(0);
		margin-left: 0px;
	}
}
